// base
import React, { useState, useEffect, useContext } from 'react';
import './materialsAddUpdateComponent.css';
import { useSelector } from 'react-redux';

// context
import { materialsContext } from '../../../pages/materials/materialsContext';

// components
import MessageLabel from '../../message_label/MessageLabel';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import TextButton from '../../inputs/buttons/text_button/TextButton';
import TextInput from '../../inputs/text_input/TextInput';
import Selector from '../../inputs/selector/Selector';

// logic
import {
  MaterialsModeEnum,
  onChangeHandler,
  validateMaterialName,
  newMaterialAsync,
  updateMaterialAsync
} from '../../../pages/materials/materialsLogic';

export default function MaterialsAddUpdateComponent() {
  const {
    branches,
    pageMode,
    setPageMode,
    setModelMessage,
    reload,
    setReload,
    materialObjForUpdate
  } = useContext(materialsContext);

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [newData, setNewData] = useState({
    materialId: 0,
    materialName: '',
    branchId: 0
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: 'Please fill in the required fields to add a new material. All fields marked with * are mandatory.',
    type: 'info',
    isVisible: true
  });

  const options = branches.map(branch => ({
    value: String(branch.branchId),
    label: branch.branchName
  }));

  const [selectedBranch, setSelectedBranch] = useState({value: '', label: ''});

  useEffect(() => {

    if (pageMode === MaterialsModeEnum.ADD) {
      setNewData({
        materialId: 0,
        materialName: '',
        branchId: options[0].value,
      })
      setSelectedBranch(options[0]);

      setMessage({
        message: 'Please fill in the required fields to add a new material. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      });
    }

    if (pageMode === MaterialsModeEnum.UPDATE) {
      setNewData({
        materialId: materialObjForUpdate.materialId,
        materialName: materialObjForUpdate.materialName,
        branchId: 0
      })
      setSelectedBranch({value: '', label: ''});
      setMessage({
        message: 'Please fill in the required fields to update material. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      })
    }

  }, [pageMode]);

  const handleNewMaterial = async() => {
    if (await newMaterialAsync(newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setNewData({
        materialId: 0,
        materialName: '',
        branchId: options[0].value,
      });
      setSelectedBranch(options[0]);
      setReload(!reload);
    }
  }

  const handleUpdateMaterial = async() => {
    if (await updateMaterialAsync(newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setReload(!reload);
      setPageMode(MaterialsModeEnum.READ);
      setModelMessage({
          title: 'success', 
          message: 'Material updated successfully.', 
          isVisible: true 
        });
    }
  }

  const handleAddUpdateClick = async () => {

    if ((currentAdminPermissions & 2097152) !== 2097152){
      setMessage({
        message: 'You do not have sufficient permissions to perform this action.',
        type: 'info',
        isVisible: true,
      });
      return;
    }

    try {

      if (pageMode === MaterialsModeEnum.ADD) {
        await handleNewMaterial();
      }
      else if (pageMode === MaterialsModeEnum.UPDATE) {
        await handleUpdateMaterial();
      }

    } catch {
      setMessage({
        message: 'An error occurred while processing your request. Please try again later.',
        type: 'error',
        isVisible: true
      });
    }
  }

  const handleCancelClick = () => {
    setNewData({
      materialId: 0,
      materialName: '',
      branchId: 0
    })
    setPageMode(MaterialsModeEnum.READ);
  }

  const handleBranchOption = (option) => {
    setSelectedBranch(option);
    onChangeHandler('branchId', option.value, setNewData);
  }
  
  return (
    <div className='material-add-update-component'>
      <div className='material-add-update-content'>

        <div className='new-material-header'>
          <h3>{pageMode === MaterialsModeEnum.ADD ? 'Add New Material' : 'Update Material'}</h3>
          <div className={`save-loader ${loading ? 'active' : ''}`}></div>
        </div>

        <div className='new-material-message'>
          <MessageLabel message={message.message} type={message.type} isVisible={message.isVisible} />
        </div>

        <div className={`new-material-body ${loading ? 'loading' : ''}`}>
            <div className='item-container'>
              <TextInput
                title={'Material Name'} 
                value={newData.materialName}
                onChangeHandler={(newValue) => {onChangeHandler('materialName', newValue, setNewData)}} 
                validateInput={validateMaterialName}
                mandatory={true}
                tooltipTitle={'Please enter a valid Material name with a maximum length of 100 characters.'}
                onEnter={async() => {handleAddUpdateClick()}}
                dir='rtl'
              />
            </div>

            <div className={`item-container label ${pageMode === MaterialsModeEnum.UPDATE ? 'hidden' : ''}`}>
              <Selector className={'label'} dir={'rtl'} title={'Branch'} options={options} value={selectedBranch.value} onSelect={handleBranchOption}/>
            </div>
        </div>

        <div className={`new-material-footer ${loading ? 'loading' : ''}`}>
          <div className={`new-material-add-button-container ${(currentAdminPermissions & 2097152) !== 2097152 ? 'disabled' : ''}`}>
            <ContainedButton title={pageMode === MaterialsModeEnum.ADD ? 'Add' : 'Update'} onclickHandler={async() => {handleAddUpdateClick()}} />
          </div>

          <div className='new-material-cancel-button-container'>
            <TextButton title={'Cancel'} onclickHandler={() => {handleCancelClick()}} />
          </div>
        </div>

      </div>
    </div>
  )
}
