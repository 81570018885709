// base
import React, { useContext, useEffect } from 'react'
import './typicalQuestionsTypesHeader.css';
import { useSelector } from 'react-redux';

// context
import { typicalQuestionsTypesContext } from '../../../pages/typical_questions_types/typicalQuestionsTypesContext';

// icons
import { FaTags } from "react-icons/fa";

// components 
import AdditionCard from '../../common_components/addition_card/AdditionCard';
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// logic
import {
  TypicalQuestionsTypesModeEnum,
  getQuestionsTypes
} from '../../../pages/typical_questions_types/typicalQuestionsTypesLogic';

export default function TypicalQuestionsTypesHeader() {
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const {
    types,
    setTypes,
    setPageMode,
    setLoading,
    reload
  } = useContext(typicalQuestionsTypesContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setTypes(await getQuestionsTypes());
      } catch {
        console.error('An error occurred while fetching typical questions types data:');
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [reload])

  return (
    <div className='typical-questions-types-header-component'>

      <div className={`item-container ${(currentAdminPermissions & 524288) !== 524288 ? 'disabled' : ''}`} onClick={() => {setPageMode(TypicalQuestionsTypesModeEnum.ADD)}}>
        <AdditionCard />
      </div>

      <div className='item-container'>
        <AnalyticalCard
          title={'Typical Questions Types'}
          icon={FaTags}
          value={types?.length}
          isActive={true}
          onClick={() => {}}
        />
      </div>

    </div>
  )
}
