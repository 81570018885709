// base
import React from 'react'

// context
import { AcademicYearsProvider } from './academicYearsContext';

// components
import YearsMain from '../../components/academic_years_components/years_main/YearsMain';

export default function AcademicYears() {
  return (
    <AcademicYearsProvider>
      <YearsMain />
    </AcademicYearsProvider>
  )
}
