// base
import React, { useContext, useEffect } from 'react'
import './yearsHeader.css';
import { useSelector } from 'react-redux';

// context
import { academicYearsContext } from '../../../pages/academic_years/academicYearsContext';

// icons
import { FaCalendarAlt } from "react-icons/fa";

// components 
import AdditionCard from '../../common_components/addition_card/AdditionCard';
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// logic
import {
  AcademicYearsModeEnum,
  getYears
} from '../../../pages/academic_years/academicYearsLogic';

export default function YearsHeader() {
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const {
    years,
    setYears,
    setPageMode,
    setLoading,
    reload
  } = useContext(academicYearsContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setYears(await getYears());
      } catch {
        console.error('An error occurred while fetching academic years data:');
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [reload])

  return (
    <div className='years-header-component'>

      <div className={`item-container ${(currentAdminPermissions & 131072) !== 131072 ? 'disabled' : ''}`} onClick={() => {setPageMode(AcademicYearsModeEnum.ADD)}}>
        <AdditionCard />
      </div>

      <div className='item-container'>
        <AnalyticalCard
          title={'All Academic Years'}
          icon={FaCalendarAlt}
          value={years?.length}
          isActive={true}
          onClick={() => {}}
        />
      </div>

    </div>
  )
}
