// base
import React, { useState, useEffect, useContext, useRef } from 'react';
import './ratesFilesAddUpdateComponent.css';
import { useSelector } from 'react-redux';

// context
import { acceptanceRatesFilesContext } from '../../../pages/acceptance_rates_files/acceptanceRatesFilesContext';

// components
import MessageLabel from '../../message_label/MessageLabel';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import TextButton from '../../inputs/buttons/text_button/TextButton';
import TextInput from '../../inputs/text_input/TextInput';
import Selector from '../../inputs/selector/Selector';

// logic
import {
  AcceptanceRatesModeEnum,
  onChangeHandler,
  validateFileTitle,
  newAcceptanceRateFileAsync,
  updateAcceptanceRateFileAsync,
  validateFile
} from '../../../pages/acceptance_rates_files/acceptanceRatesFilesLogic';

// icons
import { FaFilePdf } from "react-icons/fa6";
import { RiFileUnknowFill } from "react-icons/ri";

export default function RatesFilesAddUpdateComponent() {
  const fileInputRef = useRef(null);
  const [inputFileName, setInputFileName] = useState('');
  const [event, setEvent] = useState(null);
  const [inputStatus, setInputStatus] = useState('normal');

  const {
    years,
    pageMode,
    setPageMode,
    setModelMessage,
    reload,
    setReload,
    rateObjForUpdate
  } = useContext(acceptanceRatesFilesContext);

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [newData, setNewData] = useState({
    fileId: 0,
    fileTitle: '',
    fileURL: '',
    fileName: '',
    academicYearId: 0
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: 'Please fill in the required fields to add a acceptance rate file. All fields marked with * are mandatory.',
    type: 'info',
    isVisible: true
  });

  const [selectedYear, setSelectedYear] = useState({value: '', label: ''});

  const YearsOptions = years.map(year => ({
    value: String(year.yearId),
    label: year.academicYear
  }));

  useEffect(() => {
    if (pageMode === AcceptanceRatesModeEnum.ADD) {
      setSelectedYear(YearsOptions[0] || {value: '', label: ''});
    }
    else if (pageMode === AcceptanceRatesModeEnum.UPDATE) {
      setSelectedYear(YearsOptions.find(option => option.label === rateObjForUpdate.academicYear) || {value: '', label: ''});
    }
  }, [pageMode, reload]);

  useEffect(() => {
    setInputFileName('')
    setEvent(null);
  }, [reload]);

  useEffect(() => {

    if (pageMode === AcceptanceRatesModeEnum.ADD) {
      setNewData({
        fileId: 0,
        fileTitle: '',
        fileURL: '',
        fileName: '',
        academicYearId: selectedYear?.value || 0
      });    
    }

    if (pageMode === AcceptanceRatesModeEnum.UPDATE) {
      setNewData({
        fileId: rateObjForUpdate.fileId,
        fileTitle: rateObjForUpdate.fileTitle,
        fileURL: rateObjForUpdate.fileURL,
        fileName: rateObjForUpdate.fileName,
        academicYearId: selectedYear?.value || 0
      });

      setMessage({
        message: 'Please fill in the required fields to update the acceptance rate file. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      });
    }

  }, [pageMode, reload]);

  useEffect(() => {
    onChangeHandler('academicYearId', selectedYear.value, setNewData)
  }, [selectedYear]);

  useEffect(() => {
    if (pageMode === AcceptanceRatesModeEnum.ADD) {
      setMessage({
        message: 'Please fill in the required fields to add a new acceptance rate file. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      });
    }
  }, [pageMode])

  const handleNewRateFile = async() => {
    if (await newAcceptanceRateFileAsync(event, newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setNewData({
        fileId: 0,
        fileTitle: '',
        fileURL: '',
        fileName: '',
        academicYearId: YearsOptions[0].value || 0
      });
      setSelectedYear(YearsOptions[0] || {value: '', label: ''});      

      setReload(!reload);
    }
  }

  const handleUpdateRateFile = async() => {
    if (await updateAcceptanceRateFileAsync(event, newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setReload(!reload);
      setPageMode(AcceptanceRatesModeEnum.READ);
      setModelMessage({
          title: 'success', 
          message: 'Acceptance rate file updated successfully.', 
          isVisible: true 
        });
    }
  }

  const handleAddUpdateClick = async () => {

    if ((currentAdminPermissions & 134217728) !== 134217728){
      setMessage({
        message: 'You do not have sufficient permissions to perform this action.',
        type: 'info',
        isVisible: true,
      });
      return;
    }

    try {

      if (pageMode === AcceptanceRatesModeEnum.ADD) {
        await handleNewRateFile();
      }
      else if (pageMode === AcceptanceRatesModeEnum.UPDATE) {
        await handleUpdateRateFile();
      }

    } catch {
      setMessage({
        message: 'An error occurred while processing your request. Please try again later.',
        type: 'error',
        isVisible: true
      });
    }
  }

  const handleCancelClick = () => {
    setNewData({
      fileId: 0,
      fileTitle: '',
      fileURL: '',
      fileName: '',
      academicYearId: 0
    })
    setPageMode(AcceptanceRatesModeEnum.READ);
  }

  const handleYearOption = (option) => {
    setSelectedYear(option);
    onChangeHandler('academicYearId', option.value, setNewData);
  }

  return (
    <div className='rates-files-add-update-component'>
      <div className='rates-files-add-update-content'>

        <div className='new-rate-file-header'>
          <h3>{pageMode === AcceptanceRatesModeEnum.ADD ? 'Add New File' : 'Update File'}</h3>
          <div className={`save-loader ${loading ? 'active' : ''}`}></div>
        </div>

        <div className='new-rate-file-message'>
          <MessageLabel message={message.message} type={message.type} isVisible={message.isVisible} />
        </div>

        <div className={`new-rate-file-body ${loading ? 'loading' : ''}`}>
          <div className='item-container'>
            <TextInput
              title={'Acceptance Rate File Title'} 
              value={newData.fileTitle}
              onChangeHandler={(newValue) => {onChangeHandler('fileTitle', newValue, setNewData)}} 
              validateInput={validateFileTitle}
              mandatory={true}
              tooltipTitle={'Please enter a valid acceptance rate file with a maximum length of 150 characters.'}
              onEnter={async() => {handleAddUpdateClick()}}
              dir='rtl'
            />
          </div>

          <div className="item-container file-input-container">
            <div className="file-cover">
              {inputFileName || newData.fileName ? (
                <FaFilePdf className="pdf-icon" fontSize="large" />

              ) : (
                <RiFileUnknowFill className="pdf-icon" fontSize="large" />
              )}
            </div>

            <div
              className={`input-container ${inputStatus}`}
              onClick={() => {
                fileInputRef.current.click();
              }}
            >
              {pageMode === AcceptanceRatesModeEnum.ADD
                ? inputFileName || "Select a PDF file."
                : inputFileName || newData.fileTitle}

              <input
                className="pdf-input"
                type="file"
                accept="application/pdf"
                onChange={(e) => {
                  setInputFileName('');
                  const file = e.target.files[0];
                  if (!file) {
                    setInputStatus('danger');
                    return;
                  };

                  const isValid = validateFile(file);
                  if (!isValid) {
                    setMessage({
                      message: "Please select a file in PDF format only.",
                      type: "error",
                      isVisible: true,
                    });
                    e.target.value = "";
                    setInputStatus('danger');
                  } else {
                    setInputFileName(file.name);
                    setEvent(e);
                    setInputStatus('normal');
                  }
                }}
                ref={fileInputRef}
              />
            </div>
          </div>

          <div className={`item-container label`}>
            <p className='selector-label'>Academic Year <span>*</span></p>
            <Selector className={'label'} dir={'rtl'} title={''} options={YearsOptions} value={selectedYear.value} onSelect={handleYearOption}/>
          </div>
        </div>

        <div className={`new-rate-file-footer ${loading ? 'loading' : ''}`}>
          <div className={`new-rate-file-add-button-container ${(currentAdminPermissions & 134217728) !== 134217728 ? 'disabled' : ''}`}>
            <ContainedButton title={pageMode === AcceptanceRatesModeEnum.ADD ? 'Add' : 'Update'} onclickHandler={async() => {handleAddUpdateClick()}} />
          </div>

          <div className='new-rate-file-cancel-button-container'>
            <TextButton title={'Cancel'} onclickHandler={() => {handleCancelClick()}} />
          </div>
        </div>

      </div>
    </div>
  )
}
