// base
import React, { useState, useEffect } from 'react'
import './main.css';
import { useSelector } from 'react-redux';

// images
import welcomeImage from './welcome.webp';

// icons
import { FaCircleInfo } from "react-icons/fa6";
import GroupIcon from '@mui/icons-material/Group';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import FeedIcon from '@mui/icons-material/Feed';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';

// components
import Loading from '../../components/loading/Loading';
import ContainedButton from '../../components/inputs/buttons/contained_button/ContainedButton';
import AnalyticalCard from '../../components/common_components/analytical_card/AnalyticalCard';
import Model  from '../../components/model/Model';

import {
  fetchDataAsync,
  checkUsersSubscriptionsAsync
} from './mainLogic';

export default function Main() {
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);
  const currentAdminFullname = useSelector((state) => state.currentUser.fullName);
  
  const [users, setUsers] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [sessions, setSessions] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });

  useEffect(() => {
    fetchDataAsync(setUsers,setSubscriptions, setSessions, setTransactions, setLoading);
  }, [])

  const getCurrentMonthName = () => {
    const months = [
      "January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December"
    ];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    return months[currentMonth];
  };

  return (
    <div className='main-container'>

      <div className={`loading-container ${loading ? 'active' : ''}`}>
        <Loading isVisible={loading} />
      </div>

      <Model 
        title={modelMessage.title} 
        message={modelMessage.message} 
        status={modelMessage.isVisible} 
        setStatus={setModelMessage} 
      />

      <div className='welcome-container'>
        <div>
          <h3>Welcome back 👋</h3>
          <h3>{currentAdminFullname}</h3>
          <p className='welcome-message'>Welcome to the inbook management panel. We strive to provide everything you need to manage the system efficiently. Enjoy a seamless and comprehensive experience, and if you need any assistance, please reach out to higher-level administrators for support.</p>
        </div>
        <div>
          <img src={welcomeImage} alt='welcome' className='welcome-img' />
        </div>
      </div>

      <div className='check-users-subscriptions-container'>
        <h4>To ensure a thorough review of all user subscriptions, please click the button below to begin the subscription check.</h4>
        <div className='note-message'>
          <div className='icon-container'>
            <FaCircleInfo className='icon' />
          </div>
          <p>Kindly adhere to the instructions and guidelines provided by the administrator who assigned you this task to ensure the process aligns with the established policies.</p>
        </div>

        <div className='btn-container'>
          <div className={`btn-sub-container ${checkLoading ? 'disabled' : ''}`}>
            <ContainedButton title={'Check'} onclickHandler={async() => {
              await checkUsersSubscriptionsAsync(currentAdminPermissions, setCheckLoading, setModelMessage);
            }}/>
          </div>
          <div className={`check-loader ${checkLoading ? 'active' : ''}`}></div>
        </div>
      </div>

      <div className='analytic-container'>
        <h3 className='card-title-container'>Users Analytics</h3>
        <div className='cards users'>
          <AnalyticalCard 
            title={'Total Users'} 
            icon={GroupIcon}
            value={users?.totalUsers || 0}
            isActive={false}
            onClick={() => {}}
            />

          <AnalyticalCard 
            title={`Total Users - ${getCurrentMonthName()}`} 
            icon={PermContactCalendarIcon}
            value={users?.totalUsersThisMonth || 0}
            isActive={false}
            onClick={() => {}}
            />
        </div>
      </div>

      <div className='analytic-container'>
        <h3 className='card-title-container'>Users Subscriptions Analytics</h3>
        <div className='cards subscriptions'>
          <AnalyticalCard 
            title={'Total Subscriptions'} 
            icon={AssignmentTurnedInIcon}
            value={subscriptions?.totalSubscriptions || 0}
            isActive={false}
            onClick={() => {}}
            />

          <AnalyticalCard 
            title={`Total Subscriptions - ${getCurrentMonthName()}`} 
            icon={EventAvailableIcon}
            value={subscriptions?.totalSubscriptionsThisMonth || 0}
            isActive={false}
            onClick={() => {}}
            />
        </div>
      </div>

      <div className='analytic-container'>
        <h3 className='card-title-container'>Sessions Analytics</h3>
        <div className='cards sessions'>
          <AnalyticalCard 
            title={'Total Sessions'} 
            icon={FeedIcon}
            value={sessions?.totalSessions || 0}
            isActive={false}
            onClick={() => {}}
            />

          <AnalyticalCard 
            title={'Active Sessions'} 
            icon={ToggleOnIcon}
            value={sessions?.activeSessions || 0}
            isActive={false}
            onClick={() => {}}
            />

          <AnalyticalCard 
            title={'In-Active Sessions'} 
            icon={ToggleOffIcon}
            value={sessions?.inActiveSessions || 0}
            isActive={false}
            onClick={() => {}}
            />
        </div>
      </div>

      <div className='analytic-container'>
        <h3 className='card-title-container'>Transactions Analytics</h3>
        <div className='cards transactions'>
          <AnalyticalCard 
            title={`Total Additions - ${getCurrentMonthName()}`} 
            icon={AddIcon}
            value={transactions?.additions || 0}
            isActive={false}
            onClick={() => {}}
            />

          <AnalyticalCard 
            title={`Total Deletions - ${getCurrentMonthName()}`} 
            icon={DeleteIcon}
            value={transactions?.deletions || 0}
            isActive={false}
            onClick={() => {}}
            />

          <AnalyticalCard 
            title={`Total Updates - ${getCurrentMonthName()}`} 
            icon={CreateIcon}
            value={transactions?.updates || 0}
            isActive={false}
            onClick={() => {}}
            />
        </div>
      </div>
      
      <div className='main-footer'>
        <p>© 2024 InBook | Developed by Murtadha Muhammed.</p>
        <p>
          Contact: 
          <a href="tel:+9647763599662"> Phone</a> | 
          <a href="mailto:murtadhamuhammad383@gmail.com"> Email</a> | 
          <a href="https://www.linkedin.com/in/mac383" target="_blank" rel='noopener noreferrer'> LinkedIn</a>
        </p>
      </div>

    </div>
  )
}
