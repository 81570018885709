// base
import React, { useContext, useEffect } from 'react';
import './materialsMain.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// context
import { materialsContext } from '../../../pages/materials/materialsContext';

// logic
import { 
  resetMessage, 
  MessageBoxActionsEnum,
  MaterialsModeEnum,
  deleteMaterialAsync
} from '../../../pages/materials/materialsLogic';

// components
import MaterialsHeader from '../materials_header/MaterialsHeader';
import MaterialsBody from '../materials_body/MaterialsBody';
import MaterialsAddUpdateComponent from '../materials_add_update_component/MaterialsAddUpdateComponent';
import Model  from '../../model/Model';
import MessageBox from '../../message_box/MessageBox';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import Loading from '../../loading/Loading';

export default function MaterialsMain() {
  const navigate = useNavigate();

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const { 
    reload,
    setReload,
    modelMessage, 
    setModelMessage,
    messageBox,
    setMessageBox,
    loading,
    setLoading,
    materialIdForDelete,
    pageMode,
    actionType, 
    setActionType
  } = useContext(materialsContext);

  useEffect(() => {
    onActionTypeChangeHandler();
  }, [actionType])

  const onActionTypeChangeHandler = () => {
    switch (actionType) {
      case MessageBoxActionsEnum.DELETE:
        setMessageBox({
          title: 'Confirm Deletion',
          message: 'Are you sure you want to delete this material? This action cannot be undone.',
          isVisible: true
        });
        break;

      default:
        return;
    }
  }

  const onConfirmAction = async() => {
    switch (actionType) {
      case MessageBoxActionsEnum.DELETE:
        await deleteMaterialAsync
          (
            materialIdForDelete,
            currentAdminId,
            currentAdminPermissions,
            setModelMessage,
            setLoading,
            reload,
            setReload,
            navigate
          )
        break;

      default:
        return;
    }
    setActionType(MessageBoxActionsEnum.NONE);
  }

  return (
    <div className='materials-main-component'>
      
      <Model 
        title={modelMessage.title} 
        message={modelMessage.message} 
        status={modelMessage.isVisible} 
        setStatus={setModelMessage} 
      />

      <MessageBox 
        title={messageBox.title} 
        message={messageBox.message} 
        isVisable={messageBox.isVisible} 
        onClose={() => {
          resetMessage(setMessageBox);
          setActionType(MessageBoxActionsEnum.NONE);
        }}
      >
        <div className='materials-confirm-btn-container'>
          <ContainedButton title={'Confirm'} onclickHandler={async() => {
            resetMessage(setMessageBox);
            onConfirmAction();
          }} />
        </div>
      </MessageBox>

      <div className={`loading-container ${loading ? 'active' : ''}`}>
        <Loading isVisible={loading} />
      </div>

      <div className={`add-update-container ${pageMode === MaterialsModeEnum.ADD ? '' : 'in-active'}`}>
        <MaterialsAddUpdateComponent />
      </div>

      <div className={`add-update-container ${pageMode === MaterialsModeEnum.UPDATE ? '' : 'in-active'}`}>
        <MaterialsAddUpdateComponent />
      </div>
      
      <div className='materials-header'>
        <MaterialsHeader />
      </div>

      <div className='materials-body'>
        <MaterialsBody />
      </div>

    </div>
  )
}