// base
import React from 'react'

// context
import { AcceptanceRatesFilesProvider } from './acceptanceRatesFilesContext';

// components
import RatesFilesMain from '../../components/acceptance_rates_files_components/rates_files_main/RatesFilesMain';

export default function Materials() {
  return (
    <AcceptanceRatesFilesProvider>
      <RatesFilesMain />
    </AcceptanceRatesFilesProvider>
  )
}
