// base
import React, { useContext, useEffect } from 'react';
import './ratesMain.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// context
import { acceptanceRatesContext } from '../../../pages/acceptance_rates/acceptanceRatesContext';

// logic
import { 
  resetMessage, 
  MessageBoxActionsEnum,
  AcceptanceRatesModeEnum,
  deleteRateAsync
} from '../../../pages/acceptance_rates/acceptanceRatesLogic';

// components
import RatesHeader from '../rates_header/RatesHeader';
import RatesBody from '../rates_body/RatesBody';
import RatesAddUpdateComponent from '../rates_add_update_component/RatesAddUpdateComponent';
import Model  from '../../model/Model';
import MessageBox from '../../message_box/MessageBox';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import Loading from '../../loading/Loading';

// material components
import Pagination from '@mui/material/Pagination';

export default function RatesMain() {
  const navigate = useNavigate();

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const { 
    reload,
    setReload,
    modelMessage, 
    setModelMessage,
    messageBox,
    setMessageBox,
    loading,
    setLoading,
    rateIdForDelete,
    pageMode,
    actionType, 
    setActionType,
    pagesCount,
    pageNumber,
    setPageNumber
  } = useContext(acceptanceRatesContext);

  useEffect(() => {
    onActionTypeChangeHandler();
  }, [actionType])

  const onActionTypeChangeHandler = () => {
    switch (actionType) {
      case MessageBoxActionsEnum.DELETE:
        setMessageBox({
          title: 'Confirm Deletion',
          message: 'Are you sure you want to delete this acceptance rate? This action cannot be undone.',
          isVisible: true
        });
        break;

      default:
        return;
    }
  }

  const onConfirmAction = async() => {
    switch (actionType) {
      case MessageBoxActionsEnum.DELETE:
        await deleteRateAsync
          (
            rateIdForDelete,
            currentAdminId,
            currentAdminPermissions,
            setModelMessage,
            setLoading,
            reload,
            setReload,
            navigate
          )
        break;
      default:
        return;
    }
    setActionType(MessageBoxActionsEnum.NONE);
  }

  const handlePaginationChange = (event, value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPageNumber(value)
  }

  return (
    <div className='rates-main-component'>
      
      <Model 
        title={modelMessage.title} 
        message={modelMessage.message} 
        status={modelMessage.isVisible} 
        setStatus={setModelMessage} 
      />

      <MessageBox 
        title={messageBox.title} 
        message={messageBox.message} 
        isVisable={messageBox.isVisible} 
        onClose={() => {
          resetMessage(setMessageBox);
          setActionType(MessageBoxActionsEnum.NONE);
        }}
      >
        <div className='rates-confirm-btn-container'>
          <ContainedButton title={'Confirm'} onclickHandler={async() => {
            resetMessage(setMessageBox);
            onConfirmAction();
          }} />
        </div>
      </MessageBox>

      <div className={`loading-container ${loading ? 'active' : ''}`}>
        <Loading isVisible={loading} />
      </div>

      <div className={`add-update-container ${pageMode === AcceptanceRatesModeEnum.ADD ? '' : 'in-active'}`}>
        <RatesAddUpdateComponent />
      </div>

      <div className={`add-update-container ${pageMode === AcceptanceRatesModeEnum.UPDATE ? '' : 'in-active'}`}>
        <RatesAddUpdateComponent />
      </div>
      
      <div className='rates-header'>
        <RatesHeader />
      </div>

      <div className='rates-body'>
        <RatesBody />
      </div>

      <div className={`rates-pagination-container ${pagesCount <= 0 ? 'hidden' : ''}`}>
        <Pagination 
          count={pagesCount} 
          page={pageNumber}
          variant="outlined" 
          shape="rounded" 
          color="primary" 
          showFirstButton 
          showLastButton 
          size="small"
          onChange={handlePaginationChange}
        />
      </div>

    </div>
  )
}