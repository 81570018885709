import axios from 'axios';
import config from '../../global/config';

import {
  fetchScalarData,
  fetchMultipleData,
  deleteData
} from '../../global/utils/functions/apiService';

import {
  checkLength,
} from '../../global/utils/validation';

const TypicalQuestionsTypesModeEnum = Object.freeze({
  READ: 'read',
  ADD: 'add',
  UPDATE: 'update'
});

const MessageBoxActionsEnum = Object.freeze({
  NONE: 'none',
  DELETE: 'delete'
})

function onChangeHandler(field, newValue, state) {
  state(prevState => ({
    ...prevState,
    [field]: newValue
  }));
}

function validateType (input) {
  return checkLength(1, 25, input);
}

function validateTypeForAddNew (type) {
  if (!validateType(type.type))
    return false;

  return true;
}

function validateTypeForUpdate (type) {
  if (!validateType(type.type))
    return false;

  if (!type.typeId || type.typeId <= 0)
    return false;

  return true;
}

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

async function getQuestionsTypes() {
  const url = '/TypicalQuestionsTypes/GetAllTypicalQuestionsTypes';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const types = response?.data?.Response?.data?.types || [];

    return types;
  } else {
    console.log(response.message);
    return [];
  }
}

async function isTypeHasRelationsAsync(typeId) {
  const url = `/TypicalQuestionsTypes/IsTypicalQuestionTypeHasRelations/${typeId}`;

  try {
    const response = await fetchScalarData(url);

    if (response.data.Response.data.hasRelations === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('An error occurred while fetching the data:', error);
    return false;
  }
}

async function deleteTypeAsync(typeId, byAdmin, currentAdminPermissions, messageState, loaderState, reload, setReload, navigate) {
  try {
    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((currentAdminPermissions & 524288) !== 524288) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);
    
    const hasRelations = await isTypeHasRelationsAsync(typeId);

    if (hasRelations === true) {
      messageState({
        title: 'Action Required',
        message: 'This typical question type is linked to other records. Please remove the dependencies before attempting to delete it.',
        isVisible: true,
      });
      
      return;
    }

    const response = await deleteData(`/TypicalQuestionsTypes/DeleteTypicalQuestionType/${typeId}/${byAdmin}`);
    
    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'The typical question type has been deleted successfully.',
        isVisible: true
      });
      setReload(!reload);
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to delete typical question type. Please check your permissions and try again.',
        isVisible: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deleting the typical question type. Please try again later.',
      isVisible: true
    });
  } finally {
    loaderState(false);
  }
}

async function isTypeExist(type) {
  const url = `/TypicalQuestionsTypes/IsTypicalQuestionTypeExist/${type}`;

  try {
    const response = await fetchScalarData(url);

    if (response.data.Response.data.isExist === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('An error occurred while fetching the data:', error);
    return false;
  }
}

async function newTypeAsync(type, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (validateTypeForAddNew(type) === false) {
      setMessage({
        message: 'Invalid typical question type data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    if ((currentAdminPermissions & 524288) !== 524288) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);

    if (await isTypeExist(type.type) === true) {
      setMessage({
        message: 'This typical question type already exists. Please enter a different typical question type.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    const url = `${config.apiBaseUrl}/TypicalQuestionsTypes/NewTypicalQuestionType`;

    const response = await axios.post(url, {
      type: type.type,
      byAdmin: byAdmin
    });

    if (response.status === 201) {
      setMessage({
        message: 'Typical question type inserted successfully.',
        type: 'success',
        isVisible: true
      });
      return true;
    } else {
      setMessage({
        message: 'Failed to insert new typical question type.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.response?.data?.message}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
};

// review catch section in top method

async function updateTypeAsync(type, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (validateTypeForUpdate(type) === false) {
      setMessage({
        message: 'Invalid typical question type data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    if ((currentAdminPermissions & 524288) !== 524288) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }
    loaderState(true);

    if (await isTypeExist(type.type) === true) {
      setMessage({
        message: 'This typical question type already exists. Please enter a different typical question type.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    const url = `${config.apiBaseUrl}/TypicalQuestionsTypes/UpdateTypicalQuestionType`;

    const response = await axios.put(url, {
      typeId: type.typeId,
      type: type.type,
      byAdmin: byAdmin
    });

    if (response.status === 200) {
      return true;
    } else {
      setMessage({
        message: 'Failed to update typical question type.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.response?.data?.message}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
};

export {
  TypicalQuestionsTypesModeEnum,
  MessageBoxActionsEnum,
  onChangeHandler,
  validateType,
  resetMessage,
  getQuestionsTypes,
  deleteTypeAsync,
  newTypeAsync,
  updateTypeAsync
}