import React, { createContext, useState } from 'react';
import { TypicalQuestionsTypesModeEnum, MessageBoxActionsEnum } from './typicalQuestionsTypesLogic';

export const typicalQuestionsTypesContext = createContext();

export const TypicalQuestionsTypesProvider = ({ children }) => {

  const [types, setTypes] = useState([]);
  const [pageMode, setPageMode] = useState(TypicalQuestionsTypesModeEnum.READ);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);
  const [typeIdForDelete, setTypeIdForDelete] = useState(0);
  const [typeObjForUpdate, setTypeObjForUpdate] = useState(null);
  const [actionType, setActionType] = useState(MessageBoxActionsEnum.NONE);

  return (
  <typicalQuestionsTypesContext.Provider
    value={{
      types,
      setTypes,
      pageMode, 
      setPageMode,
      loading,
      setLoading,
      modelMessage,
      setModelMessage,
      messageBox,
      setMessageBox,
      reload,
      setReload,
      typeIdForDelete,
      setTypeIdForDelete,
      typeObjForUpdate,
      setTypeObjForUpdate,
      actionType, 
      setActionType
    }}
  >
      {children}
  </typicalQuestionsTypesContext.Provider>
  );
};
