// base
import React from 'react'

// context
import { CoursesProvider } from './coursesContext';

// components
import CoursesMain from '../../components/courses_components/courses_main/CoursesMain';

export default function Materials() {
  return (
    <CoursesProvider>
      <CoursesMain />
    </CoursesProvider>
  )
}
