import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./newSubscription.css";
import { useSelector } from "react-redux";

// logic
import { getBranches } from "../../../pages/subscription_plans/subscriptionPlansLogic";

import {
  getSubscriptionPlans,
  newUserSubscription,
} from "../../../pages/users_subscriptions/usersSubscriptionsLogic";

import Model from "../../model/Model";
import Loading from "../../loading/Loading";

export default function NewSubscription() {
  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const { id, branchName } = useParams();
  const [plans, setPlans] = useState([]);
  const [message, messageState] = useState({
    title: "",
    message: "",
    status: false,
  });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const branches = await getBranches();
      if (!branches) return;

      const userBranch = branches.find(
        (branch) => branch.branchName === branchName
      );
      if (!userBranch) return;

      const allPlans = await getSubscriptionPlans(
        userBranch?.branchId,
        setLoader
      );
      if (!allPlans) return;

      setPlans(allPlans.filter((plan) => plan.price !== 0));
    };
    fetchData();
  }, []);

  const onSubscribeClick = async (planId) => {
    await newUserSubscription(
      currentAdminId,
      id,
      planId,
      setLoader,
      messageState
    );
  };

  return (
    <div className="new-subscription-container">
      <Model
        title={message.title}
        message={message.message}
        status={message.status}
        setStatus={messageState}
      />

      <div className="plans-container">
        <Loading isVisible={loader} />
        {plans?.length > 0 ? (
          plans.map((plan) => (
            <div key={plan.planId} className="card">
              <h3 className="card-title">{plan.planName}</h3>
              <p>
                <strong>Branch:</strong> {plan.branchName}
              </p>
              <p>
                <strong>Price:</strong> ${plan.price}
              </p>
              <p>
                <strong>Monthly Duration:</strong> {plan.monthlyDuration}{" "}
                {plan.monthlyDuration > 1 ? "months" : "month"}
              </p>
              <p>
                <strong>Daily Duration:</strong> {plan.dailyDuration}{" "}
                {plan.dailyDuration > 1 ? "days" : "day"}
              </p>
              <p>
                <strong>Discount:</strong> {plan.discount}%
              </p>
              <p>
                <strong>Final Price:</strong> ${plan.finalPrice}
              </p>
              <p>
                <strong>Free Trial:</strong> {plan.isFreeTrail ? "Yes" : "No"}
              </p>
              <p>
                <strong>Active:</strong> {plan.isActive ? "Yes" : "No"}
              </p>
              <p>
                <strong>Created At:</strong> {plan.createdAt.split("T")[0]}
              </p>
              <button
                className="card-button"
                onClick={() => {
                  onSubscribeClick(plan?.planId);
                }}
              >
                Subscribe Now
              </button>
            </div>
          ))
        ) : (
          <p>no plans available</p>
        )}
      </div>
    </div>
  );
}
