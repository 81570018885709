// base
import React, { useContext } from 'react';
import './materialsBody.css';

// context
import { materialsContext } from '../../../pages/materials/materialsContext';

// components
import MaterialsCard from '../materials_card/MaterialsCard';

export default function MaterialsBody() {
  const {
    materials
  } = useContext(materialsContext);

  return (
    <div className='materials-body-component'>
      
      {materials.length > 0 ? (
        materials.map((material, index) => (
          <div key={material.materialId || index} className='material-card-container'>
            <MaterialsCard material={material} _index={index + 1} />
          </div>
        ))
      ) : (
        <div className="no-materials-message">
          No materials found.
        </div>
      )}

    </div>
  )
}
