// base
import React, { useState, useContext } from 'react';
import './ratesCard.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// context
import { acceptanceRatesContext } from '../../../pages/acceptance_rates/acceptanceRatesContext';

// icons
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

// components
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';

// logic
import { 
  AcceptanceRatesModeEnum,
  MessageBoxActionsEnum
} from '../../../pages/acceptance_rates/acceptanceRatesLogic';

export default function RatesCard({ rate, _index }) {
  
  const {
    setPageMode,
    setActionType,
    setRateIdForDelete,
    setRateObjForUpdate
  } = useContext(acceptanceRatesContext)

  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [openMode, setOpenMode] = useState(false);

  const onUpdateClickHandler = () => {
    setRateObjForUpdate(rate);
    setPageMode(AcceptanceRatesModeEnum.UPDATE)
  }

  const onDeleteClickHandler = () => {
    setActionType(MessageBoxActionsEnum.DELETE);
    setRateIdForDelete(rate?.rateId);
  }

  return (
    <div className='rates-card-component'>

      <div className='rate-card-header' onClick={() => {setOpenMode(!openMode)}}>
        <div className='index-container'>
          <p className='label index'>#/{_index}</p>
        </div>

        <div className='college-container item'>
          <p className='label'>College</p>
          <p className='value'>{rate?.college}</p>
        </div>

        <div className='average-container item'>
          <p className='label'>Gender</p>
          <p className='value'>{rate?.gender}</p>
        </div>
        
        <div className='total-container item'>
          <p className='label'>Total Marks</p>
          <p className='value'>{rate?.total}</p>
        </div>

        <div className='icon-container'>
          {openMode ? (
            <FaRegArrowAltCircleUp className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          ) : (
            <FaRegArrowAltCircleDown className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          )}
        </div>
      </div>

      <div className={`card-body ${openMode ? 'opened' : ''}`}>

        <div className='item'>
          <p className='label'>College</p>
          <p className='value rtl'>{rate?.college}</p>
        </div>

        <div className='item inline'>
          <p className='label'>Total Marks</p>
          <p className='value'>{rate?.total}</p>
        </div>

        <div className='item inline'>
          <p className='label'>Average</p>
          <p className='value'>{rate?.average}</p>
        </div>

        <div className='item inline'>
          <p className='label'>Gender</p>
          <p className='value'>{rate?.gender}</p>
        </div>

        <div className='item inline'>
          <p className='label'>Branch</p>
          <p className='value'>{rate?.branchName}</p>
        </div>

        <div className='item inline'>
          <p className='label'>Academic Year</p>
          <p className='value'>{rate?.academicYear}</p>
        </div>

        <div className='item'>
          <p className='label'>Description</p>
          <p className='value rtl'>{rate?.description ? rate.description : 'no description'}</p>
        </div>

        <div className='item inline'>
          <p className='label'>View History</p>

          <Link to={`/transactions-history/${rate?.rateId}/Acceptance Rates`}>
            <p className='value history'>history</p>
          </Link>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Update</p>
          <div className={`btn-container ${(currentAdminPermissions & 33554432) !== 33554432 ? 'disabled' : ''}`}>
            <ContainedButton title={'Update'} onclickHandler={onUpdateClickHandler} />
          </div>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Delete</p>
          <div className={`btn-container ${(currentAdminPermissions & 33554432) !== 33554432 ? 'disabled' : ''}`}>
            <ContainedButton title={'Delete'} onclickHandler={onDeleteClickHandler} type='danger' />
          </div>
        </div>

      </div>

    </div>
  )
}
