// base
import React, { useState, useContext } from 'react';
import './typicalQuestionsCard.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// context
import { typicalQuestionsContext } from '../../../pages/typical_questions/typicalQuestionsContext';

// icons
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

// components
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';

// logic
import { 
  TypicalQuestionsModeEnum,
  MessageBoxActionsEnum
} from '../../../pages/typical_questions/typicalQuestionsLogic';

export default function TypicalQuestionsCard({ question, _index }) {
  
  const {
    setPageMode,
    setActionType,
    setQuestionObjForDelete,
    setQuestionObjForUpdate
  } = useContext(typicalQuestionsContext)

  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [openMode, setOpenMode] = useState(false);

  const onUpdateClickHandler = () => {
    setQuestionObjForUpdate(question);
    setPageMode(TypicalQuestionsModeEnum.UPDATE)
  }

  const onDeleteClickHandler = () => {
    setActionType(MessageBoxActionsEnum.DELETE);
    setQuestionObjForDelete({
      questionId: question.questionId,
      fileName: question.fileName
    });
  }

  return (
    <div className='typical-questions-card-component'>
      <div className='typical-questions-card-header' onClick={() => {setOpenMode(!openMode)}}>
        <div className='index-container'>
          <p className='label index'>#/{_index}</p>
        </div>

        <div className='question-title-container'>
          <p className='label'>Question Title</p>
          <p className='value'>{question?.questionTitle}</p>
        </div>
        <div className='type-container'>
          <p className='label'>Type</p>
          <p className='value'>{question?.type}</p>
        </div>

        <div className='icon-container'>
          {openMode ? (
            <FaRegArrowAltCircleUp className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          ) : (
            <FaRegArrowAltCircleDown className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          )}
        </div>
      </div>

      <div className={`card-body ${openMode ? 'opened' : ''}`}>

        <div className='item question-title-container'>
          <p className='label'>Question Title</p>
          <p className='value'>{question?.questionTitle}</p>
        </div>
        <div className='item inline'>
          <p className='label'>Type</p>
          <p className='value'>{question?.type}</p>
        </div>
        <div className='item inline'>
          <p className='label'>Academic Year</p>
          <p className='value'>{question?.academicYear}</p>
        </div>
        <div className='item inline'>
          <p className='label'>Open File</p>
          <a href={question.fileURL} className='value history' target='_blank' rel='noopener noreferrer' >Open</a>
        </div>

        <div className='item inline'>
          <p className='label'>View History</p>

          <Link to={`/transactions-history/${question?.questionId}/Typical Questions`}>
            <p className='value history'>history</p>
          </Link>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Update</p>
          <div className={`btn-container ${(currentAdminPermissions & 8388608) !== 8388608 ? 'disabled' : ''}`}>
            <ContainedButton title={'Update'} onclickHandler={onUpdateClickHandler} />
          </div>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Delete</p>
          <div className={`btn-container ${(currentAdminPermissions & 8388608) !== 8388608 ? 'disabled' : ''}`}>
            <ContainedButton title={'Delete'} onclickHandler={onDeleteClickHandler} type='danger' />
          </div>
        </div>

      </div>

    </div>
  )
}
