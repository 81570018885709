// base
import React, { useState, useEffect, useContext, useRef } from 'react';
import './typicalQuestionsAddUpdateComponent.css';
import { useSelector } from 'react-redux';

// context
import { typicalQuestionsContext } from '../../../pages/typical_questions/typicalQuestionsContext';

// components
import MessageLabel from '../../message_label/MessageLabel';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import TextButton from '../../inputs/buttons/text_button/TextButton';
import TextInput from '../../inputs/text_input/TextInput';
import Selector from '../../inputs/selector/Selector';

// logic
import {
  TypicalQuestionsModeEnum,
  onChangeHandler,
  validateQuestionTitle,
  newTypicalQuestionAsync,
  updateTypicalQuestionAsync,
  validateFile
} from '../../../pages/typical_questions/typicalQuestionsLogic';

// icons
import { FaFilePdf } from "react-icons/fa6";
import { RiFileUnknowFill } from "react-icons/ri";

export default function TypicalQuestionsAddUpdateComponent() {
  const fileInputRef = useRef(null);
  const [inputFileName, setInputFileName] = useState('');
  const [event, setEvent] = useState(null);
  const [inputStatus, setInputStatus] = useState('normal');

  const {
    types,
    materials,
    branches,
    years,
    pageMode,
    setPageMode,
    setModelMessage,
    reload,
    setReload,
    questionObjForUpdate
  } = useContext(typicalQuestionsContext);

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [newData, setNewData] = useState({
    questionId: 0,
    questionTitle: '',
    fileURL: '',
    fileName: '',
    typeId: 0,
    materialId: 0,
    branchId: 0,
    yearId: 0
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: 'Please fill in the required fields to add a new typical question. All fields marked with * are mandatory.',
    type: 'info',
    isVisible: true
  });

  const [selectedType, setSelectedType] = useState({value: '', label: ''});
  const [selectedMaterial, setSelectedMaterial] = useState({value: '', label: ''});
  const [selectedBranch, setSelectedBranch] = useState({value: '', label: ''});
  const [selectedYear, setSelectedYear] = useState({value: '', label: ''});

  const [materialsOptions, setMaterialsOptions] = useState(
    materials
    .filter(material => material.branchName === selectedBranch.label)
    .map(material => ({
      value: String(material.materialId),
      label: material.materialName
  })));

  const TypesOptions = types.map(type => ({
    value: String(type.typeId),
    label: type.type
  }));

  const BranchesOptions = branches.map(branch => ({
    value: String(branch.branchId),
    label: branch.branchName
  }));

  const YearsOptions = years.map(year => ({
    value: String(year.yearId),
    label: year.academicYear
  }));

  useEffect(() => {
    if (pageMode === TypicalQuestionsModeEnum.ADD) {
      setSelectedType(TypesOptions[0] || {value: '', label: ''});
      setSelectedMaterial(materialsOptions[0] || {value: '', label: ''});
      setSelectedBranch(BranchesOptions[0] || {value: '', label: ''});
      setSelectedYear(YearsOptions[0] || {value: '', label: ''});
    }
    else if (pageMode === TypicalQuestionsModeEnum.UPDATE) {
      setSelectedType(TypesOptions.find(option => option.label === questionObjForUpdate.type) || {value: '', label: ''});
      setSelectedBranch(BranchesOptions.find(option => option.label === questionObjForUpdate.branchName) || {value: '', label: ''});
      setSelectedMaterial(materialsOptions.find(option => option.label === questionObjForUpdate.materialName) || {value: '', label: ''});
      setSelectedYear(YearsOptions.find(option => option.label === questionObjForUpdate.academicYear) || {value: '', label: ''});
    }
  }, [pageMode, reload]);

  useEffect(() => {
    const data = materials
    .filter(material => material.branchName === selectedBranch.label)
    .map(material => ({
      value: String(material.materialId),
      label: material.materialName
    }));
    
    setMaterialsOptions(data);
  }, [selectedBranch]);

  useEffect(() => {
    if (pageMode === TypicalQuestionsModeEnum.ADD) {
      setSelectedMaterial(materialsOptions[0] || {value: '', label: ''});
    }
    else if (pageMode === TypicalQuestionsModeEnum.UPDATE){
      setSelectedMaterial(materialsOptions.find(option => option.label === questionObjForUpdate.materialName) || materialsOptions[0] || {value: '', label: ''});
    }
  }, [materialsOptions]);

  useEffect(() => {
    setInputFileName('')
    setEvent(null);
  }, [reload]);

  useEffect(() => {

    if (pageMode === TypicalQuestionsModeEnum.ADD) {
      setNewData({
        questionId: 0,
        questionTitle: '',
        fileURL: '',
        fileName: '',
        typeId: selectedBranch?.value || 0,
        materialId: selectedMaterial?.value || 0,
        branchId: selectedBranch?.value || 0,
        yearId: selectedYear?.value || 0
      });    

      setMessage({
        message: 'Please fill in the required fields to add a new typical question. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      });
    }

    if (pageMode === TypicalQuestionsModeEnum.UPDATE) {
      setNewData({
        questionId: questionObjForUpdate.questionId,
        questionTitle: questionObjForUpdate.questionTitle,
        fileURL: questionObjForUpdate.fileURL,
        fileName: questionObjForUpdate.fileName,
        typeId: selectedBranch?.value || 0,
        materialId: selectedMaterial?.value || 0,
        branchId: selectedBranch?.value || 0,
        yearId: selectedYear?.value || 0
      });

      setMessage({
        message: 'Please fill in the required fields to update the typical question. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      });
    }

  }, [pageMode, reload]);

  useEffect(() => {
    onChangeHandler('typeId', selectedType.value, setNewData)
    onChangeHandler('materialId', selectedMaterial.value, setNewData)
    onChangeHandler('branchId', selectedBranch.value, setNewData)
    onChangeHandler('yearId', selectedYear.value, setNewData)
  }, [selectedType, selectedMaterial, selectedBranch, selectedYear]);

  const handleNewTypicalQuestion = async() => {
    if (await newTypicalQuestionAsync(event, newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setNewData({
        questionId: 0,
        questionTitle: '',
        fileURL: '',
        fileName: '',
        typeId: TypesOptions.length > 0 ? TypesOptions[0].value : 0,
        materialId: materialsOptions.length > 0 ? materialsOptions[0].value : 0,
        branchId: BranchesOptions.length > 0 ? BranchesOptions[0].value : 0,
        yearId: YearsOptions.length > 0 ? YearsOptions[0].value : 0
      });
      setSelectedType(TypesOptions.length > 0 ? TypesOptions[0] : {value: '', label: ''});
      setSelectedMaterial(materialsOptions.length > 0 ? materialsOptions[0] : {value: '', label: ''});
      setSelectedBranch(BranchesOptions.length > 0 ? BranchesOptions[0] : {value: '', label: ''});
      setSelectedYear(YearsOptions.length > 0 ? YearsOptions[0] : {value: '', label: ''});      

      setReload(!reload);
    }
  }

  const handleUpdateTypicalQuestion = async() => {
    if (await updateTypicalQuestionAsync(event, newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setReload(!reload);
      setPageMode(TypicalQuestionsModeEnum.READ);
      setModelMessage({
          title: 'success', 
          message: 'Typical question updated successfully.', 
          isVisible: true 
        });
    }
  }

  const handleAddUpdateClick = async () => {

    if ((currentAdminPermissions & 8388608) !== 8388608){
      setMessage({
        message: 'You do not have sufficient permissions to perform this action.',
        type: 'info',
        isVisible: true,
      });
      return;
    }

    try {

      if (pageMode === TypicalQuestionsModeEnum.ADD) {
        await handleNewTypicalQuestion();
      }
      else if (pageMode === TypicalQuestionsModeEnum.UPDATE) {
        await handleUpdateTypicalQuestion();
      }

    } catch {
      setMessage({
        message: 'An error occurred while processing your request. Please try again later.',
        type: 'error',
        isVisible: true
      });
    }
  }

  const handleCancelClick = () => {
    setNewData({
      questionId: 0,
      questionTitle: '',
      fileURL: '',
      fileName: '',
      typeId: 0,
      materialId: 0,
      branchId: 0,
      yearId: 0
    })
    setPageMode(TypicalQuestionsModeEnum.READ);
  }

  const handleTypeOption = (option) => {
    setSelectedType(option);
    onChangeHandler('typeId', option.value, setNewData);
  }

  const handleMaterialOption = (option) => {
    setSelectedMaterial(option);
    onChangeHandler('materialId', option.value, setNewData);
  }

  const handleBranchOption = (option) => {
    setSelectedBranch(option);
    onChangeHandler('branchId', option.value, setNewData);
  }

  const handleYearOption = (option) => {
    setSelectedYear(option);
    onChangeHandler('yearId', option.value, setNewData);
  }

  return (
    <div className='typical-questions-add-update-component'>
      <div className='typical-questions-add-update-content'>

        <div className='new-typical-questions-header'>
          <h3>{pageMode === TypicalQuestionsModeEnum.ADD ? 'Add New Typical Question' : 'Update Typical Question'}</h3>
          <div className={`save-loader ${loading ? 'active' : ''}`}></div>
        </div>

        <div className='new-typical-questions-message'>
          <MessageLabel message={message.message} type={message.type} isVisible={message.isVisible} />
        </div>

        <div className={`new-typical-questions-body ${loading ? 'loading' : ''}`}>
          <div className='item-container'>
            <TextInput
              title={'Question Title'} 
              value={newData.questionTitle}
              onChangeHandler={(newValue) => {onChangeHandler('questionTitle', newValue, setNewData)}} 
              validateInput={validateQuestionTitle}
              mandatory={true}
              tooltipTitle={'Please enter a valid question title with a maximum length of 150 characters.'}
              onEnter={async() => {handleAddUpdateClick()}}
              dir='rtl'
            />
          </div>

          <div className="item-container file-input-container">
            <div className="file-cover">
              {inputFileName || newData.fileName ? (
                <FaFilePdf className="pdf-icon" fontSize="large" />

              ) : (
                <RiFileUnknowFill className="pdf-icon" fontSize="large" />
              )}
            </div>

            <div
              className={`input-container ${inputStatus}`}
              onClick={() => {
                fileInputRef.current.click();
              }}
            >
              {pageMode === TypicalQuestionsModeEnum.ADD
                ? inputFileName || "Select a PDF file."
                : inputFileName || newData.questionTitle}

              <input
                className="pdf-input"
                type="file"
                accept="application/pdf"
                onChange={(e) => {
                  setInputFileName('');
                  const file = e.target.files[0];
                  if (!file) {
                    setInputStatus('danger');
                    return;
                  };

                  const isValid = validateFile(file);
                  if (!isValid) {
                    setMessage({
                      message: "Please select a file in PDF format only.",
                      type: "error",
                      isVisible: true,
                    });
                    e.target.value = "";
                    setInputStatus('danger');
                  } else {
                    setInputFileName(file.name);
                    setEvent(e);
                    setInputStatus('normal');
                  }
                }}
                ref={fileInputRef}
              />
            </div>
          </div>

          <div className={`item-container label`}>
            <p className='selector-label'>Question Type <span>*</span></p>
            <Selector className={'label'} dir={'rtl'} title={''} options={TypesOptions} value={selectedType.value} onSelect={handleTypeOption}/>
          </div>

          <div className={`item-container label`}>
            <p className='selector-label'>Branch <span>*</span></p>
            <Selector className={'label'} dir={'rtl'} title={''} options={BranchesOptions} value={selectedBranch.value} onSelect={handleBranchOption}/>
          </div>

          <div className={`item-container label`}>
            <p className='selector-label'>Material <span>*</span></p>
            <Selector className={'label'} dir={'rtl'} title={''} options={materialsOptions} value={selectedMaterial.value} onSelect={handleMaterialOption}/>
          </div>

          <div className={`item-container label`}>
            <p className='selector-label'>Academic Year <span>*</span></p>
            <Selector className={'label'} dir={'rtl'} title={''} options={YearsOptions} value={selectedYear.value} onSelect={handleYearOption}/>
          </div>
        </div>

        <div className={`new-typical-questions-footer ${loading ? 'loading' : ''}`}>
          <div className={`new-typical-questions-add-button-container ${(currentAdminPermissions & 8388608) !== 8388608 ? 'disabled' : ''}`}>
            <ContainedButton title={pageMode === TypicalQuestionsModeEnum.ADD ? 'Add' : 'Update'} onclickHandler={async() => {handleAddUpdateClick()}} />
          </div>

          <div className='new-typical-questions-cancel-button-container'>
            <TextButton title={'Cancel'} onclickHandler={() => {handleCancelClick()}} />
          </div>
        </div>

      </div>
    </div>
  )
}
