import React from 'react'
import './logo.css';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

export default function Logo({ resetActiveItem }) {

  const navigate = useNavigate();

  const handleToggleSideBar = () => {
    document.body.classList.toggle('toggle-sidebar');
  }

  const handleOnClick = () => {
    navigate('/home')
    resetActiveItem();
  }

  return (
    <div className='logo-container'>

        <h2 onClick={handleOnClick}>INBOOK CONSOLE</h2>
        <MenuIcon className='icon' onClick={handleToggleSideBar}/>

    </div>
  )
}
