// base
import React, { useContext, useEffect } from 'react'
import './branchesHeader.css';
import { useSelector } from 'react-redux';

// context
import { branchesContext } from '../../../pages/branches/branchesContext';

// icons
import { SiGoogleclassroom } from "react-icons/si";


// components 
import AdditionCard from '../../common_components/addition_card/AdditionCard';
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// logic
import {
  BranchesModeEnum,
  getBranches
} from '../../../pages/branches/branchesLogic';

export default function BranchesHeader() {
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const {
    branches,
    setBranches,
    setPageMode,
    setLoading,
    reload
  } = useContext(branchesContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setBranches(await getBranches());
      } catch {
        console.error('An error occurred while fetching branches data:');
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [reload])

  return (
    <div className='branches-header-component'>

      <div className={`item-container ${(currentAdminPermissions & 32768) !== 32768 ? 'disabled' : ''}`} onClick={() => {setPageMode(BranchesModeEnum.ADD)}}>
        <AdditionCard />
      </div>

      <div className='item-container'>
        <AnalyticalCard
          title={'All Branches'}
          icon={SiGoogleclassroom}
          value={branches?.length}
          isActive={true}
          onClick={() => {}}
        />
      </div>

    </div>
  )
}
