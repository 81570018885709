// base
import React, { useContext } from 'react';
import './typicalQuestionsTypesBody.css';

// context
import { typicalQuestionsTypesContext } from '../../../pages/typical_questions_types/typicalQuestionsTypesContext';

// components
import TypicalQuestionsTypesCard from '../typical_questions_types_card/TypicalQuestionsTypesCard';

export default function TypicalQuestionsTypesBody() {
  const {
    types
  } = useContext(typicalQuestionsTypesContext);

  return (
    <div className='typical-questions-types-body-component'>
      
      {types.length > 0 ? (
        types.map((type, index) => (
          <div key={type.typeId || index} className='typical-questions-types-card-container'>
            <TypicalQuestionsTypesCard type={type} _index={index + 1} />
          </div>
        ))
      ) : (
        <div className="no-typical-questions-types-message">
          No typical questions types found.
        </div>
      )}

    </div>
  )
}
