// base
import React, { useContext } from 'react';
import './branchesBody.css';

// context
import { branchesContext } from '../../../pages/branches/branchesContext';

// components
import BranchesCard from '../branches_card/BranchesCard';

export default function BranchesBody() {
  const {
    branches
  } = useContext(branchesContext);

  return (
    <div className='branches-body-component'>
      
      {branches.length > 0 ? (
        branches.map((branch, index) => (
          <div key={branch.branchId || index} className='branch-card-container'>
            <BranchesCard branch={branch} _index={index + 1} />
          </div>
        ))
      ) : (
        <div className="no-branches-message">
          No branches found.
        </div>
      )}

    </div>
  )
}
