import axios from 'axios';
import config from '../../global/config';

import {
  fetchScalarData,
  fetchMultipleData,
  deleteData
} from '../../global/utils/functions/apiService';

import {
  isFloat,
  checkLength,
} from '../../global/utils/validation';

const AcceptanceRatesModeEnum = Object.freeze({
  READ: 'read',
  ADD: 'add',
  UPDATE: 'update'
});

const MessageBoxActionsEnum = Object.freeze({
  NONE: 'none',
  DELETE: 'delete'
})

function onChangeHandler(field, newValue, state) {
  state(prevState => ({
    ...prevState,
    [field]: newValue
  }));
}

function validateCollege (input) {
  return checkLength(1, 250, input);
}

function validateTotal (input) {
  return isFloat(input) && input >= 1;
}

/**
 * Validates if the input is a float number between 0 and 100 (inclusive).
 * @param {number} input - The value to validate.
 * @returns {boolean} True if input is a valid float within range, otherwise false.
 */
function validateAverage(input) {
  return isFloat(input) && input >= 1 && input <= 100;
}

function validateGender (input) {
  return checkLength(1, 10, input);
}

function validateDescription (input) {
  if (!input || input.length === 0)
    return true;

  return checkLength(1, 150, input);
}

function validateAcceptanceRateForAddNew (rate) {
  if (!validateCollege(rate.college))
    return false;

  if (!validateTotal(rate.total))
    return false;

  if (!validateAverage(rate.average))
    return false;

  if (!validateGender(rate.gender))
    return false;

  if (!validateDescription(rate.description))
    return false;

  if (!rate.branchId || rate.branchId <= 0)
    return false;

  if (!rate.academicYearId || rate.academicYearId <= 0)
    return false;

  return true;
}

function validateAcceptanceRateForUpdate (rate) {
  if (!rate.rateId || rate.rateId <= 0)
    return false;

  if (!validateCollege(rate.college))
    return false;

  if (!validateTotal(rate.total))
    return false;

  if (!validateAverage(rate.average))
    return false;

  if (!validateGender(rate.gender))
    return false;

  if (!validateDescription(rate.description))
    return false;

  if (!rate.branchId || rate.branchId <= 0)
    return false;

  if (!rate.academicYearId || rate.academicYearId <= 0)
    return false;
  
  return true;
}

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

async function getBranches() {
  const url = '/Branches/GetAllBranches';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const branches = response?.data?.Response?.data?.branches || [];

    return branches;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getYears() {
  const url = '/AcademicYears/GetAllAcademicYears';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const years = response?.data?.Response?.data?.academicYears || [];

    return years;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getAllRatesCount() {
  const url = `/AcceptanceRates/GetAcceptanceRatesCount`;
  const result = await fetchScalarData(url);
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching all rates count:', result.message);
    return 0;
  }
}

async function getPagesCountByBranch(branchId) {
  const url = `/AcceptanceRates/GetAcceptanceRatesPagesCountByBranch/${branchId}`;
  const result = await fetchScalarData(url);
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching all rates count:', result.message);
    return 0;
  }
}

async function getRatesByBranch(branchId, pageNumber) {
  const url = `/AcceptanceRates/GetAcceptanceRatesByBranch/${branchId}`;

  const response = await fetchMultipleData(url, pageNumber);
  
  if (response.success) {
    const rates = response?.data?.Response?.data?.acceptanceRates || [];

    return rates;
  } else {
    console.log(response.message);
    return [];
  }
}

async function deleteRateAsync(rateId, byAdmin, currentAdminPermissions, messageState, loaderState, reload, setReload, navigate) {
  try {
    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((currentAdminPermissions & 33554432) !== 33554432) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);

    const response = await deleteData(`/AcceptanceRates/DeleteAcceptanceRate/${rateId}/${byAdmin}`);
    
    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'The acceptance rate has been deleted successfully.',
        isVisible: true
      });
      setReload(!reload);
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to delete acceptance rate. Please check your permissions and try again.',
        isVisible: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deleting the acceptance rate. Please try again later.',
      isVisible: true
    });
  } finally {
    loaderState(false);
  }
}

async function newRateAsync(rate, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (validateAcceptanceRateForAddNew(rate) === false) {
      setMessage({
        message: 'Invalid acceptance rate data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    if ((currentAdminPermissions & 33554432) !== 33554432) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);

    const url = `${config.apiBaseUrl}/AcceptanceRates/NewAcceptanceRate`;

    const response = await axios.post(url, {
      college: rate.college,
      total: rate.total,
      average: rate.average,
      gender: rate.gender,
      description: rate.description,
      branchId: rate.branchId,
      academicYearId: rate.academicYearId,
      byAdmin: byAdmin
    });

    if (response.status === 201) {
      setMessage({
        message: 'Acceptance rate inserted successfully.',
        type: 'success',
        isVisible: true
      });
      return true;
    } else {
      setMessage({
        message: 'Failed to insert new acceptance rate.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.response?.data?.title}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
};

async function updateRateAsync(rate, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (validateAcceptanceRateForUpdate(rate) === false) {
      setMessage({
        message: 'Invalid acceptance rate data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    if ((currentAdminPermissions & 33554432) !== 33554432) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }
    loaderState(true);

    const url = `${config.apiBaseUrl}/AcceptanceRates/UpdateAcceptanceRate`;

    const response = await axios.put(url, {
      rateId: rate.rateId,
      college: rate.college,
      total: rate.total,
      average: rate.average,
      gender: rate.gender,
      description: rate.description,
      branchId: rate.branchId,
      academicYearId: rate.academicYearId,
      byAdmin: byAdmin
    });

    if (response.status === 200) {
      return true;
    } else {
      setMessage({
        message: 'Failed to update acceptance rate.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.response?.data?.message}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
};

export {
  AcceptanceRatesModeEnum,
  MessageBoxActionsEnum,
  onChangeHandler,
  validateCollege,
  validateTotal,
  validateAverage,
  validateDescription,
  resetMessage,
  getBranches,
  getYears,
  getAllRatesCount,
  getPagesCountByBranch,
  getRatesByBranch,
  deleteRateAsync,
  newRateAsync,
  updateRateAsync
}