// base
import React from 'react'

// context
import { BranchesProvider } from './branchesContext';

// components
import BranchesMain from '../../components/branches/branches_main/BranchesMain';

export default function Branches() {
  return (
    <BranchesProvider>
      <BranchesMain />
    </BranchesProvider>
  )
}
