// base
import React, { useContext, useEffect } from 'react';
import './subscriptionPlansMain.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// context
import { subscriptionPlansContext } from '../../../pages/subscription_plans/subscriptionPlansContext';

// logic
import { 
  resetMessage, 
  MessageBoxActionsEnum,
  SubscriptionPlansMode,
  activatePlanAsync,
  deactivatePlanAsync,
  deletePlanAsync
} from '../../../pages/subscription_plans/subscriptionPlansLogic';

// components
import SubscriptionPlansHeader from '../subscription_plans_header/SubscriptionPlansHeader';
import SubscriptionPlansBody from '../subscription_plans_body/SubscriptionPlansBody';
import Model  from '../../model/Model';
import MessageBox from '../../message_box/MessageBox';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import Loading from '../../loading/Loading';
import AddUpdateComponent from '../add_update_component/AddUpdateComponent';

export default function SubscriptionPlansMain() {
  const navigate = useNavigate();

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const { 
    reload,
    setReload,
    modelMessage, 
    setModelMessage,
    messageBox,
    setMessageBox,
    loading,
    setLoading,
    actionType,
    setActionType,
    planIdForChangeStatus,
    planIdForDelete,
    pageMode,
  } = useContext(subscriptionPlansContext);


  useEffect(() => {
    onActionTypeChangeHandler();
  }, [actionType])

  const onActionTypeChangeHandler = () => {
    switch (actionType) {
      case MessageBoxActionsEnum.ACTIVATE:
        setMessageBox({
          title: 'Confirm Activation',
          message: `Are you sure you want to activate this subscription plan?`,
          isVisible: true
        });
        break;
      
      case MessageBoxActionsEnum.DEACTIVATE:
        setMessageBox({
          title: 'Confirm Deactivation',
          message: 'Are you sure you want to deactivate this subscription plan?',
          isVisible: true
        });
        break;

      case MessageBoxActionsEnum.DELETE:
        setMessageBox({
          title: 'Confirm Deletion',
          message: 'Are you sure you want to delete this subscription plan? This action cannot be undone.',
          isVisible: true
        });
        break;

      default:
        return;
    }
  }

  const onConfirmAction = async() => {
    switch (actionType) {
      case MessageBoxActionsEnum.ACTIVATE:
        await activatePlanAsync
        (
          planIdForChangeStatus,
          currentAdminId, 
          currentAdminPermissions, 
          setModelMessage, 
          setLoading, 
          reload, 
          setReload, 
          navigate
        );
        break;
      
      case MessageBoxActionsEnum.DEACTIVATE:
        await deactivatePlanAsync
        (
          planIdForChangeStatus,
          currentAdminId, 
          currentAdminPermissions, 
          setModelMessage, 
          setLoading, 
          reload, 
          setReload, 
          navigate
        );
        break;

      case MessageBoxActionsEnum.DELETE:
        await deletePlanAsync
          (
            planIdForDelete,
            currentAdminId,
            currentAdminPermissions,
            setModelMessage,
            setLoading,
            reload,
            setReload,
            navigate
          )
        break;

      default:
        return;
    }
    setActionType(MessageBoxActionsEnum.NONE);
  }

  return (
    <div className='subscription-plans-main-component'>
      
      <Model 
        title={modelMessage.title} 
        message={modelMessage.message} 
        status={modelMessage.isVisible} 
        setStatus={setModelMessage} 
      />

      <MessageBox 
        title={messageBox.title} 
        message={messageBox.message} 
        isVisable={messageBox.isVisible} 
        onClose={() => {
          resetMessage(setMessageBox);
          setActionType(MessageBoxActionsEnum.NONE);
        }}
      >
        <div className='plans-main-confirm-btn-container'>
          <ContainedButton title={'Confirm'} onclickHandler={async() => {
            resetMessage(setMessageBox);
            onConfirmAction();
          }} />
        </div>
      </MessageBox>

      <div className={`loading-container ${loading ? 'active' : ''}`}>
        <Loading isVisible={loading} />
      </div>

      <div className={`add-update-container ${pageMode === SubscriptionPlansMode.ADD ? '' : 'in-active'}`}>
        <AddUpdateComponent />
      </div>

      <div className={`add-update-container ${pageMode === SubscriptionPlansMode.UPDATE ? '' : 'in-active'}`}>
        <AddUpdateComponent />
      </div>
      
      <div className='plans-header'>
        <SubscriptionPlansHeader />
      </div>

      <div className='plans-body'>
        <SubscriptionPlansBody />
      </div>

    </div>
  )
}