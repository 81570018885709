// base
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import './lessonsHeader.css';
import { useSelector } from 'react-redux';

// context
import { lessonsContext } from '../../../pages/Lessons/lessonsContext';

// components 
import AdditionCard from '../../common_components/addition_card/AdditionCard';

// icons
import { MdVideoLibrary } from "react-icons/md";
import { FaYoutube } from "react-icons/fa";

// logic
import {
  LessonsModeEnum,
  GetSectionByIdAsync,
  getLessonsBySectionAsync,
  onChangeHandler,
} from '../../../pages/Lessons/lessonsLogic';

export default function LessonsHeader() {

  const { sectionId } = useParams();
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [section, setSection] = useState(null);

  const {
    lessons,
    setLessons,
    setPageMode,
    setLoading,
    reload,
    setLessonObjForAction
  } = useContext(lessonsContext);

  useEffect(() => {
    fetchLessonsBySection();
  }, [reload])

  async function fetchLessonsBySection() {
    try {
      setLoading(true);
      setSection(await GetSectionByIdAsync(sectionId));
      setLessons(await getLessonsBySectionAsync(sectionId));
    } catch {
      console.error('An error occurred while fetching data:');
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className='lesson-header-component'>

      <div className='section-img-container'>
        {section?.coverURL ? 
          <img src={section?.coverURL} alt='cover' className='cover-image' />
        :
          <p className='no-cover'>cover</p>
        }
      </div>
      
      <div className='section-info-container'>
        
        <div className='section-info'>
          <div className='section-name-container'>
            <p className='section-name'>{section?.sectionName}</p>
          </div>
          <div className='course-title-container'>
            <p className='course-title'>{section?.courseTitle}</p>
          </div>
          <div className='lessons-count-container'>
            <MdVideoLibrary fontSize={''} className='video-icon'/>
            <p className='label'>{lessons.length} lessons</p>
          </div>
          <div className='open-url-container'>
            <FaYoutube fontSize={''} className='youtube-icon' />
            <a href={section?.playlistURL} target='_blank' rel='noopener noreferrer' className='label link'>YouTube</a>
          </div>
        </div>

        <div className='lesson-footer-container'>
          <div className={`addition-card-container ${(currentAdminPermissions & 1073741824) !== 1073741824 ? 'disabled' : ''}`} onClick={() => {
            onChangeHandler('sectionId', section?.sectionId, setLessonObjForAction);
            setPageMode(LessonsModeEnum.ADD);}
          }>
            <AdditionCard />
          </div>
        </div>

      </div>

    </div>
  )
}

