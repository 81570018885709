import React, { createContext, useState } from 'react';
import { CoursesModeEnum, MessageBoxActionsEnum } from './coursesLogic';

export const coursesContext = createContext();

export const CoursesProvider = ({ children }) => {

  const [count, setCount] = useState(0);
  const [courses, setCourses] = useState([]);
  const [branches, setBranches] = useState([]);
  const [pageMode, setPageMode] = useState(CoursesModeEnum.READ);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);
  const [courseObjForAction, setCourseObjForAction] = useState(null);
  const [actionType, setActionType] = useState(MessageBoxActionsEnum.NONE);

  return (
  <coursesContext.Provider
    value={{
      count, setCount,
      courses, setCourses,
      branches, setBranches,
      pageMode, setPageMode,
      loading, setLoading,
      modelMessage, setModelMessage,
      messageBox, setMessageBox,
      reload, setReload,
      courseObjForAction, setCourseObjForAction,
      actionType, setActionType
    }}
  >
      {children}
  </coursesContext.Provider>
  );
};
