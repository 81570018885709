// base
import React, { useState, useEffect, useContext } from 'react';
import './coursesAddUpdateComponent.css';
import { useSelector } from 'react-redux';

// context
import { coursesContext } from '../../../pages/courses/coursesContext';

// components
import MessageLabel from '../../message_label/MessageLabel';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import TextButton from '../../inputs/buttons/text_button/TextButton';
import TextInput from '../../inputs/text_input/TextInput';
import Selector from '../../inputs/selector/Selector';

// logic
import {
  CoursesModeEnum,
  onChangeHandler,
  validateCourseTitle,
  validateTeacherName,
  newCourseAsync,
  updateCourseAsync
} from '../../../pages/courses/coursesLogic';

export default function CoursesAddUpdateComponent() {
  const {
    branches,
    pageMode,
    setPageMode,
    setModelMessage,
    reload,
    setReload,
    courseObjForAction
  } = useContext(coursesContext);

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [newData, setNewData] = useState({
    courseId: 0,
    courseTitle: '',
    description: '',
    teacherName: '',
    branchId: 0
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: 'Please fill in the required fields to add a new course. All fields marked with * are mandatory.',
    type: 'info',
    isVisible: true
  });

  const options = branches.map(branch => ({
    value: String(branch.branchId),
    label: branch.branchName
  }));

  const [selectedBranch, setSelectedBranch] = useState({value: '', label: ''});

  useEffect(() => {

    if (pageMode === CoursesModeEnum.ADD) {
      setNewData({
        courseId: 0,
        courseTitle: '',
        description: '',
        teacherName: '',
        branchId: options[0].value

      })
      setSelectedBranch(options[0]);

      setMessage({
        message: 'Please fill in the required fields to add a new course. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      });
    }

    if (pageMode === CoursesModeEnum.UPDATE) {
      setNewData({
        courseId: courseObjForAction.courseId,
        courseTitle: courseObjForAction.courseTitle,
        description: courseObjForAction.description,
        teacherName: courseObjForAction.teacherName,
        branchId: options.find(option => option.label === courseObjForAction.branchName).value || 0
      })
      setSelectedBranch(options.find(option => option.label === courseObjForAction.branchName));
      setMessage({
        message: 'Please fill in the required fields to update course. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      })
    }

  }, [pageMode]);

  const handleNewCourse = async() => {
    if (await newCourseAsync(newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setNewData({
        courseId: 0,
        courseTitle: '',
        description: '',
        teacherName: '',
        branchId: options[0].value,
      });
      setSelectedBranch(options[0]);
      setReload(!reload);
    }
  }

  const handleUpdateCourse = async() => {
    if (await updateCourseAsync(newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setReload(!reload);
      setPageMode(CoursesModeEnum.READ);
      setModelMessage({
          title: 'success', 
          message: 'Course updated successfully.', 
          isVisible: true 
        });
    }
  }

  const handleAddUpdateClick = async () => {

    if ((currentAdminPermissions & 1073741824) !== 1073741824){
      setMessage({
        message: 'You do not have sufficient permissions to perform this action.',
        type: 'info',
        isVisible: true,
      });
      return;
    }

    try {

      if (pageMode === CoursesModeEnum.ADD) {
        await handleNewCourse();
      }
      else if (pageMode === CoursesModeEnum.UPDATE) {
        await handleUpdateCourse();
      }

    } catch {
      setMessage({
        message: 'An error occurred while processing your request. Please try again later.',
        type: 'error',
        isVisible: true
      });
    }
  }

  const handleCancelClick = () => {
    setNewData({
      courseId: 0,
      courseTitle: '',
      description: '',
      teacherName: '',
      branchId: 0
    })
    setPageMode(CoursesModeEnum.READ);
  }

  const handleBranchOption = (option) => {
    setSelectedBranch(option);
    onChangeHandler('branchId', option.value, setNewData);
  }
  
  return (
    <div className='courses-add-update-component'>
      <div className='courses-add-update-content'>
        <div className='new-courses-header'>
          <h3>{pageMode === CoursesModeEnum.ADD ? 'Add New Course' : 'Update Course'}</h3>
          <div className={`save-loader ${loading ? 'active' : ''}`}></div>
        </div>

        <div className='new-courses-message'>
          <MessageLabel message={message.message} type={message.type} isVisible={message.isVisible} />
        </div>

        <div className={`new-courses-body ${loading ? 'loading' : ''}`}>
            <div className='item-container'>
              <TextInput
                title={'Course Title'} 
                value={newData.courseTitle}
                onChangeHandler={(newValue) => {onChangeHandler('courseTitle', newValue, setNewData)}} 
                validateInput={validateCourseTitle}
                mandatory={true}
                tooltipTitle={'Please enter a valid course title with a maximum length of 250 characters.'}
                onEnter={async() => {handleAddUpdateClick()}}
                dir='rtl'
              />
            </div>

            <div className='item-container'>
              <TextInput
                title={'Teacher Name'} 
                value={newData.teacherName}
                onChangeHandler={(newValue) => {onChangeHandler('teacherName', newValue, setNewData)}} 
                validateInput={validateTeacherName}
                mandatory={true}
                tooltipTitle={'Please enter a valid teacher name with a maximum length of 100 characters.'}
                onEnter={async() => {handleAddUpdateClick()}}
                dir='rtl'
              />
            </div>

            <div className='item-container'>
              <textarea 
                className='description-input' 
                placeholder='Description (Max 250 characters)' 
                maxLength={250} 
                rows="4" 
                value={newData.description} 
                onChange={(e) => {onChangeHandler('description', e.target.value, setNewData)}}
              />
            </div>

            <div className={`item-container label`}>
              <p className='selector-label'>Branch <span>*</span></p>
              <Selector className={'label'} dir={'rtl'} title={''} options={options} value={selectedBranch.value} onSelect={handleBranchOption}/>
            </div>
        </div>

        <div className={`new-courses-footer ${loading ? 'loading' : ''}`}>
          <div className={`new-courses-add-button-container ${(currentAdminPermissions & 1073741824) !== 1073741824 ? 'disabled' : ''}`}>
            <ContainedButton title={pageMode === CoursesModeEnum.ADD ? 'Add' : 'Update'} onclickHandler={async() => {handleAddUpdateClick()}} />
          </div>

          <div className='new-courses-cancel-button-container'>
            <TextButton title={'Cancel'} onclickHandler={() => {handleCancelClick()}} />
          </div>
        </div>

      </div>
    </div>
  )
}
