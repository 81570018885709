// base
import React, { useContext, useEffect, useState, useRef } from 'react'
import './ratesHeader.css';
import { useSelector } from 'react-redux';

// context
import { acceptanceRatesContext } from '../../../pages/acceptance_rates/acceptanceRatesContext';

// icons
import { MdBarChart } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

// components 
import AdditionCard from '../../common_components/addition_card/AdditionCard';
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// logic
import {
  AcceptanceRatesModeEnum,
  getBranches,
  getAllRatesCount,
  getRatesByBranch,
  getPagesCountByBranch,
  getYears
} from '../../../pages/acceptance_rates/acceptanceRatesLogic';

export default function RatesHeader() {
  const containerRef = useRef(null);

  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);
  const [activeCardId, setActiveCardId] = useState(0);

  const {
    branches,
    setBranches,
    setYears,
    count,
    setCount,
    rates,
    setRates,
    pageNumber,
    setPageNumber,
    setPagesCount,
    setPageMode,
    setLoading,
    reload
  } = useContext(acceptanceRatesContext);

  useEffect(() => {

    const fetchData = async () => {
      setCount(await getAllRatesCount());
      if (activeCardId <= 0)
        await fetchBaseData();
      else
        await fetchRatesByBranch();
    }
    fetchData();
  }, [reload]);

  useEffect(() => {
    if (activeCardId && activeCardId > 0) 
      fetchRatesByBranch();
  }, [activeCardId])

  useEffect(() => {
    if (!activeCardId || activeCardId <= 0)
      return;
    
    const fetchData = async () => {
      try {
        setLoading(true);
        setRates(await getRatesByBranch(activeCardId, pageNumber));
      } catch {
        console.error('An error occurred while fetching data:');
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [pageNumber]);

  async function fetchBaseData(){
    try {
      setLoading(true);
      const data = await getBranches();
      if (data && data.length > 0){
        // setCount(await getAllRatesCount());
        setActiveCardId(data[0].branchId);
        setPageNumber(1);
        setPagesCount(await getPagesCountByBranch(data[0].branchId));
        setRates(await getRatesByBranch(data[0].branchId, 1));
      }

      setBranches(data);

      setYears(await getYears());

    } catch {
      console.error('An error occurred while fetching data:');
    } finally {
      setLoading(false);
    }
  }

  async function fetchRatesByBranch() {
    try {
      setLoading(true);
      setPageNumber(1);
      setPagesCount(await getPagesCountByBranch(activeCardId));
      setRates(await getRatesByBranch(activeCardId, 1));
    } catch {
      console.error('An error occurred while fetching data:');
    } finally {
      setLoading(false);
    }
  }

  const scrollLeft = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23;
      containerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  };

  const scrollRight = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23
    containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  const onCardClickHandler = (branchId) => {
    setActiveCardId(branchId)
  }

  return (
    <div className='rates-header-component'>

      <div className='scroll-container left'>
        <IoIosArrowBack className='icon' fontSize={''} onClick={scrollLeft} />
      </div>

      <div className='items-container' ref={containerRef}>

        <div className={`item-container ${(currentAdminPermissions & 33554432) !== 33554432 ? 'disabled' : ''}`} onClick={() => {setPageMode(AcceptanceRatesModeEnum.ADD)}}>
          <AdditionCard />
        </div>

        <div className='item-container'>
          <AnalyticalCard
            title={'All Branches'}
            icon={MdBarChart}
            value={`${count} Rates`}
            isActive={false}
            onClick={() => {}}
          />
        </div>

        {branches
          .map((branch) => (
            <div key={branch.branchId} className="item-container">
              <AnalyticalCard
                title={branch.branchName}
                icon={MdBarChart}
                value={activeCardId === branch.branchId ? `${rates?.length} Rates` : '-'}
                isActive={activeCardId === branch.branchId}
                onClick={() => onCardClickHandler(branch.branchId)}
              />
            </div>
          ))
        }

      </div>

      <div className='scroll-container right'>
        <IoIosArrowForward className='icon' fontSize={''} onClick={scrollRight} />
      </div>

    </div>
  )
}
