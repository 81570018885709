// base
import React, { useContext } from 'react';
import './lessonsBody.css';

// context
import { lessonsContext } from '../../../pages/Lessons/lessonsContext';

// components
import LessonsCard from '../lessons_card/LessonsCard';

export default function LessonsBody() {
  const {
    lessons
  } = useContext(lessonsContext);

  return (
    <div className='lessons-body-component'>

      {lessons.length > 0 ? (
        lessons.map((lesson, index) => (
          <div key={lesson.lessonId || index} className='lesson-card-container'>
            <LessonsCard lesson={lesson} _index={index + 1} />
          </div>
        ))
      ) : (
        <div className="no-sections-message">
          No lessons found.
        </div>
      )}

    </div>
  )
}
