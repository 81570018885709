import React, { createContext, useState } from 'react';
import { MaterialsModeEnum, MessageBoxActionsEnum } from './materialsLogic';

export const materialsContext = createContext();

export const MaterialsProvider = ({ children }) => {

  const [materials, setMaterials] = useState([]);
  const [branches, setBranches] = useState([]);
  const [pageMode, setPageMode] = useState(MaterialsModeEnum.READ);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);
  const [materialIdForDelete, setMaterialIdForDelete] = useState(0);
  const [materialObjForUpdate, setMaterialObjForUpdate] = useState(null);
  const [actionType, setActionType] = useState(MessageBoxActionsEnum.NONE);

  return (
  <materialsContext.Provider
    value={{
      materials,
      setMaterials,
      branches,
      setBranches,
      pageMode, 
      setPageMode,
      loading,
      setLoading,
      modelMessage,
      setModelMessage,
      messageBox,
      setMessageBox,
      reload,
      setReload,
      materialIdForDelete,
      setMaterialIdForDelete,
      materialObjForUpdate,
      setMaterialObjForUpdate,
      actionType, 
      setActionType
    }}
  >
      {children}
  </materialsContext.Provider>
  );
};
