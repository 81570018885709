// base
import React, { useContext } from 'react';
import './subscriptionPlansBody.css';

// context
import { subscriptionPlansContext } from '../../../pages/subscription_plans/subscriptionPlansContext';

// components
import SubscriptionPlanCard from '../subscription_plans_card/SubscriptionPlanCard';

export default function SubscriptionPlansBody() {
  const {
    plans
  } = useContext(subscriptionPlansContext);

  return (
    <div className='subscription-plans-body-component'>
      
      {plans.length > 0 ? (
        plans.map((plan, index) => (
          <div key={plan.planId || index} className='plan-card-container'>
            <SubscriptionPlanCard plan={plan} _index={index + 1} />
          </div>
        ))
      ) : (
        <div className="no-plans-message">
          No subscription plans found.
        </div>
      )}

    </div>
  )
}
