// base
import React, { useState, useEffect, useContext } from 'react';
import './sectionsAddUpdateComponent.css';
import { useSelector } from 'react-redux';

// context
import { coursesSectionsContext } from '../../../pages/courses_sections/coursesSectionsContext';

// components
import MessageLabel from '../../message_label/MessageLabel';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import TextButton from '../../inputs/buttons/text_button/TextButton';
import TextInput from '../../inputs/text_input/TextInput';

// logic
import {
  SectionsModeEnum,
  onChangeHandler,
  validateSectionName,
  newSectionAsync,
  setSectionNameAsync
} from '../../../pages/courses_sections/coursesSectionsLogic';

export default function SectionsAddUpdateComponent() {
  const {
    pageMode,
    setPageMode,
    setModelMessage,
    reload,
    setReload,
    sectionObjForAction
  } = useContext(coursesSectionsContext);

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [newData, setNewData] = useState({
    sectionId: 0,
    sectionName: '',
    playlistURL: '',
    courseId: 0
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: 'Please fill in the required fields to add a new course section. All fields marked with * are mandatory.',
    type: 'info',
    isVisible: true
  });

  useEffect(() => {

    if (pageMode === SectionsModeEnum.ADD) {
      setNewData({
        sectionId: 0,
        sectionName: '',
        playlistURL: '',
        courseId: sectionObjForAction.courseId
      })

      setMessage({
        message: 'Please fill in the required fields to add a new course section. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      });
    }

    if (pageMode === SectionsModeEnum.UPDATE) {
      setNewData({
        sectionId: sectionObjForAction.sectionId,
        sectionName: sectionObjForAction.sectionName,
        playlistURL: '',
        courseId: sectionObjForAction.courseId
      })

      setMessage({
        message: 'Please fill in the required fields to update course section. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      })
    }

  }, [pageMode]);

  const handleNewCourse = async() => {
    if (await newSectionAsync(newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setNewData({
        sectionId: 0,
        sectionName: '',
        playlistURL: '',
        courseId: sectionObjForAction.courseId
      });

      setReload(!reload);
    }
  }

  const handleUpdateCourse = async() => {
    if (await setSectionNameAsync(newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setReload(!reload);
      setPageMode(SectionsModeEnum.READ);
      setModelMessage({
          title: 'success', 
          message: 'Course section updated successfully.', 
          isVisible: true 
        });
    }
  }

  const handleAddUpdateClick = async () => {

    if ((currentAdminPermissions & 1073741824) !== 1073741824){
      setMessage({
        message: 'You do not have sufficient permissions to perform this action.',
        type: 'info',
        isVisible: true,
      });
      return;
    }

    try {

      if (pageMode === SectionsModeEnum.ADD) {
        await handleNewCourse();
      }
      else if (pageMode === SectionsModeEnum.UPDATE) {
        await handleUpdateCourse();
      }

    } catch {
      setMessage({
        message: 'An error occurred while processing your request. Please try again later.',
        type: 'error',
        isVisible: true
      });
    }
  }

  const handleCancelClick = () => {
    setNewData({
      sectionName: '',
      playlistURL: '',
      courseId: 0
    })
    setPageMode(SectionsModeEnum.READ);
  }

  return (
    <div className='courses-sections-add-update-component'>
      <div className='courses-sections-add-update-content'>
        <div className='new-courses-sections-header'>
          <h3>{pageMode === SectionsModeEnum.ADD ? 'Add New Course Section' : 'Update Course Section'}</h3>
          <div className={`save-loader ${loading ? 'active' : ''}`}></div>
        </div>

        <div className='new-courses-sections-message'>
          <MessageLabel message={message.message} type={message.type} isVisible={message.isVisible} />
        </div>

        <div className={`new-courses-sections-body ${loading ? 'loading' : ''}`}>
            <div className='item-container'>
              <TextInput
                title={'Section Name'} 
                value={newData.sectionName}
                onChangeHandler={(newValue) => {onChangeHandler('sectionName', newValue, setNewData)}} 
                validateInput={validateSectionName}
                mandatory={true}
                tooltipTitle={'Please enter a valid section name with a maximum length of 250 characters.'}
                onEnter={async() => {handleAddUpdateClick()}}
                dir='rtl'
              />
            </div>

            <div className={`item-container ${pageMode === SectionsModeEnum.UPDATE ? 'disabled' : ''}`}>
              <textarea 
                className='url-input' 
                placeholder='Playlist URL' 
                // maxLength={250} 
                rows="4" 
                value={newData.playlistURL} 
                onChange={(e) => {onChangeHandler('playlistURL', e.target.value, setNewData)}}
              />
            </div>
        </div>

        <div className={`new-courses-sections-footer ${loading ? 'loading' : ''}`}>
          <div className={`new-courses-sections-add-button-container ${(currentAdminPermissions & 1073741824) !== 1073741824 ? 'disabled' : ''}`}>
            <ContainedButton title={pageMode === SectionsModeEnum.ADD ? 'Add' : 'Update'} onclickHandler={async() => {handleAddUpdateClick()}} />
          </div>

          <div className='new-courses-sections-cancel-button-container'>
            <TextButton title={'Cancel'} onclickHandler={() => {handleCancelClick()}} />
          </div>
        </div>
      </div>
    </div>
  )
}
