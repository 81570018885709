import React, { createContext, useState } from 'react';
import {
  UsersSubscriptionsMode
} from './usersSubscriptionsLogic';

export const usersSubscriptionsContext = createContext();

export const UsersSubscriptionsProvider = ({ children }) => {

  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsMode, setSubscriptionsMode] = useState(UsersSubscriptionsMode.ACTIVES);
  const [activesCount, setActivesCount] = useState(0);
  const [inActivesCount, setInActivesCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);

  return (
  <usersSubscriptionsContext.Provider
    value={{
      subscriptions, 
      setSubscriptions,
      subscriptionsMode,
      setSubscriptionsMode,
      activesCount, 
      setActivesCount, 
      inActivesCount, 
      setInActivesCount,
      currentPageNumber, 
      setCurrentPageNumber, 
      pagesCount, 
      setPagesCount,
      loading,
      setLoading,
      modelMessage, 
      setModelMessage, 
      messageBox,
      setMessageBox,
      reload,
      setReload
    }}
  >
      {children}
  </usersSubscriptionsContext.Provider>
  );
};
