// base
import React, { useContext, useEffect } from 'react';
import './sectionsMain.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// context
import { coursesSectionsContext } from '../../../pages/courses_sections/coursesSectionsContext';

// logic
import { 
  resetMessage, 
  MessageBoxActionsEnum,
  SectionsModeEnum,
  deleteSectionCoverAsync,
  deleteSectionAsync,
} from '../../../pages/courses_sections/coursesSectionsLogic';

// components
import SectionsHeader from '../courses_sections_header/SectionsHeader';
import SectionsBody from '../courses_sections_body/SectionsBody';
import SectionsAddUpdateComponent from '../courses_sections_add_update_component/SectionsAddUpdateComponent';
import Model  from '../../model/Model';
import MessageBox from '../../message_box/MessageBox';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import Loading from '../../loading/Loading';

export default function SectionsMain() {
  const navigate = useNavigate();

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const { 
    reload,
    setReload,
    modelMessage, 
    setModelMessage,
    messageBox,
    setMessageBox,
    loading,
    setLoading,
    sectionObjForAction,
    pageMode,
    actionType, 
    setActionType
  } = useContext(coursesSectionsContext);

  useEffect(() => {
    onActionTypeChangeHandler();
  }, [actionType])

  const onActionTypeChangeHandler = () => {
    switch (actionType) {
      case MessageBoxActionsEnum.DELETE_COVER:
        setMessageBox({
          title: 'Confirm Cover Deletion',
          message: 'Are you sure you want to delete this section cover?',
          isVisible: true
        });
      break;
      case MessageBoxActionsEnum.DELETE:
        setMessageBox({
          title: 'Confirm Deletion',
          message: 'Are you sure you want to delete this course section? This action cannot be undone.',
          isVisible: true
        });
        break;

      default:
        return;
    }
  }

  const onConfirmAction = async() => {
    switch (actionType) {
      case MessageBoxActionsEnum.DELETE_COVER:
        await deleteSectionCoverAsync(
          sectionObjForAction,
          currentAdminId,
          currentAdminPermissions,
          reload,
          setReload,
          setModelMessage,
          setLoading
        )
      break;
      case MessageBoxActionsEnum.DELETE:
        await deleteSectionAsync
          (
            sectionObjForAction,
            currentAdminId,
            currentAdminPermissions,
            setModelMessage,
            setLoading,
            reload,
            setReload,
            navigate
          )
        break;

      default:
        return;
    }
    setActionType(MessageBoxActionsEnum.NONE);
  }

  return (
    <div className='course-section-main-component'>

      <Model 
        title={modelMessage.title} 
        message={modelMessage.message} 
        status={modelMessage.isVisible} 
        setStatus={setModelMessage} 
      />

      <MessageBox 
        title={messageBox.title} 
        message={messageBox.message} 
        isVisable={messageBox.isVisible} 
        onClose={() => {
          resetMessage(setMessageBox);
          setActionType(MessageBoxActionsEnum.NONE);
        }}
      >
        <div className='course-section-confirm-btn-container'>
          <ContainedButton title={'Confirm'} onclickHandler={async() => {
            resetMessage(setMessageBox);
            onConfirmAction();
          }} />
        </div>
      </MessageBox>

      <div className={`loading-container ${loading ? 'active' : ''}`}>
        <Loading isVisible={loading} />
      </div>

      <div className={`add-update-container ${pageMode === SectionsModeEnum.ADD ? '' : 'in-active'}`}>
        <SectionsAddUpdateComponent />
      </div>

      <div className={`add-update-container ${pageMode === SectionsModeEnum.UPDATE ? '' : 'in-active'}`}>
        <SectionsAddUpdateComponent />
      </div>

      <div className='course-section-header'>
        <div className='course-section-header-sub-container'>
          <SectionsHeader />
        </div>
      </div>

      <div className='course-section-body'>
        <SectionsBody />
      </div>

    </div>
  )
}