// base
import React, { useState, useEffect, useContext } from 'react';
import './addUpdateComponent.css';
import { useSelector } from 'react-redux';

// context
import { subscriptionPlansContext } from '../../../pages/subscription_plans/subscriptionPlansContext';

// components
import MessageLabel from '../../message_label/MessageLabel';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import TextButton from '../../inputs/buttons/text_button/TextButton';
import TextInput from '../../inputs/text_input/TextInput';
import Selector from '../../inputs/selector/Selector';

// logic
import {
  SubscriptionPlansMode,
  validatePlanName,
  validatePrice,
  validateDiscount,
  validateMonthlyAndDailyDuration,
  onChangeHandler,
  newPlanAsync,
  updatePlanAsync
} from '../../../pages/subscription_plans/subscriptionPlansLogic';


export default function AddUpdateComponent() {
  const {
    branches,
    pageMode,
    setPageMode,
    setModelMessage,
    reload,
    setReload,
    planObjForUpdate
  } = useContext(subscriptionPlansContext);

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [newData, setNewData] = useState({
    planId: 0,
    planName: '',
    price: '',
    discount: '',
    monthlyDuration: '',
    dailyDuration: '',
    description: '', // for new mode
    branchId: 0,     // for new mode
  })

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: 'Please fill in the required fields to add a new subscription plan. All fields marked with * are mandatory.',
    type: 'info',
    isVisible: true
  });

  const options = branches.map(branch => ({
    value: String(branch.branchId), // تحويل branchId إلى string
    label: branch.branchName
  }));

  const [selectedBranch, setSelectedBranch] = useState({value: '', label: ''});

  useEffect(() => {

    if (pageMode === SubscriptionPlansMode.ADD) {
      setNewData({
        planId: 0,
        planName: '',
        price: '',
        discount: '',
        monthlyDuration: '',
        dailyDuration: '',
        description: '',
        branchId: options[0].value,
      })
      setSelectedBranch(options[0]);
    }

    if (pageMode === SubscriptionPlansMode.UPDATE) {
      setNewData({
        planId: planObjForUpdate.planId,
        planName: planObjForUpdate.planName,
        price: planObjForUpdate.price,
        discount: planObjForUpdate.discount,
        monthlyDuration: planObjForUpdate.monthlyDuration,
        dailyDuration: planObjForUpdate.dailyDuration,
        description: planObjForUpdate.description,
        branchId: planObjForUpdate.branchId
      })

      setMessage({
        message: 'Please fill in the required fields to update subscription plan. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      })

      setSelectedBranch(options.find(option => option.label === String(planObjForUpdate.branchName)) || options[0]);
    }

  }, [pageMode, branches])

  const handleNewPlan = async() => {
    if (await newPlanAsync(newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setNewData({
        planName: '',
        price: '',
        discount: '',
        monthlyDuration: '',
        dailyDuration: '',
        description: '',
        branchId: 0,
      });
      setReload(!reload);
    }
  }

  const handleUpdatePlan = async() => {
    if (await updatePlanAsync(newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setReload(!reload);
      setPageMode(SubscriptionPlansMode.READ);
      setModelMessage({
          title: 'success', 
          message: 'Subscription plan updated successfully.', 
          isVisible: true 
        });
    }
  }

  const handleAddUpdateClick = async () => {

    if ((currentAdminPermissions & 4096) !== 4096){
      setMessage({
        message: 'You do not have sufficient permissions to perform this action.',
        type: 'info',
        isVisible: true,
      });
      return;
    }

    try {

      if (pageMode === SubscriptionPlansMode.ADD) {
        await handleNewPlan();
      }
      else if (pageMode === SubscriptionPlansMode.UPDATE) {
        await handleUpdatePlan();
      }

    } catch {
      setMessage({
        message: 'An error occurred while processing your request. Please try again later.',
        type: 'error',
        isVisible: true
      });
    }
  }

  const handleCancelClick = () => {
    setNewData({
      planName: '',
      price: 0,
      discount: 0,
      monthlyDuration: 0,
      dailyDuration: 0,
      description: '',
      branchId: 0,
    });

    setPageMode(SubscriptionPlansMode.READ);
  }

  const handleBranchOption = (option) => {
    setSelectedBranch(option);
    onChangeHandler('branchId', option.value, setNewData);
  }

  function handleDescription(description) {
    if (!description)
      return '';
    else if (description === 'null')
      return '';
    else
      return description;
  }

  return (
    <div className='plan-add-update-component'>
      <div className='plan-add-update-content'>

        <div className='new-plan-header'>
          <h3>{pageMode === SubscriptionPlansMode.ADD ? 'Add Page' : 'Update Page'}</h3>
          <div className={`save-loader ${loading ? 'active' : ''}`}></div>
        </div>

        <div className='new-plan-message'>
          <MessageLabel message={message.message} type={message.type} isVisible={message.isVisible} />
        </div>

        <div className={`new-plan-body ${loading ? 'loading' : ''}`}>
          <div className='input-container'>
        
            <div className='item-container'>
              <TextInput
                title={'Plan Name'} 
                value={newData.planName}
                onChangeHandler={(newValue) => {onChangeHandler('planName', newValue, setNewData)}} 
                validateInput={validatePlanName}
                mandatory={true}
                tooltipTitle={'Please enter a valid plan name with a maximum length of 100 characters.'}
                onEnter={async () => {}}
                dir='rtl'
              />
            </div>

            <div className='item-container'>
              <TextInput
                title={'Price'} 
                value={newData.price}
                onChangeHandler={(newValue) => {onChangeHandler('price', newValue, setNewData)}} 
                validateInput={validatePrice}
                mandatory={true}
                tooltipTitle={'Please enter a valid plan price.'}
                onEnter={async () => {}}
                dir='rtl'
                />
            </div>

            <div className='item-container'>
              <TextInput
                title={'Discount'} 
                value={newData.discount}
                onChangeHandler={(newValue) => {onChangeHandler('discount', newValue, setNewData)}} 
                validateInput={validateDiscount}
                mandatory={true}
                tooltipTitle={'Please enter a valid plan discount. It must be a numeric value.'}
                onEnter={async () => {}}
                dir='rtl'
                />
            </div>

            <div className='item-container'>
              <TextInput
                title={'Mounthly Duration'} 
                value={newData.monthlyDuration}
                onChangeHandler={(newValue) => {onChangeHandler('monthlyDuration', newValue, setNewData)}} 
                validateInput={validateMonthlyAndDailyDuration}
                mandatory={true}
                tooltipTitle={'Please enter a valid plan monthly duration. It must be a numeric value.'}
                onEnter={async () => {}}
                dir='rtl'
                />  
            </div>
            
            <div className='item-container'>
              <TextInput
                title={'Daily Duration'} 
                value={newData.dailyDuration}
                onChangeHandler={(newValue) => {onChangeHandler('dailyDuration', newValue, setNewData)}} 
                validateInput={validateMonthlyAndDailyDuration}
                mandatory={true}
                tooltipTitle={'Please enter a valid plan daily duration. It must be a numeric value.'}
                onEnter={async () => {}}
                dir='rtl'
                />
            </div>
        
            <div className={`item-container description-container ${pageMode === SubscriptionPlansMode.UPDATE ? 'hidden' : ''}`}>
              <div className='input-container-header'>
                <p className='label'>Description</p>
              </div>

              <textarea 
                className='description-input' 
                placeholder='Enter plan description' 
                maxLength={250} 
                value={handleDescription(newData?.description)}
                onChange={(e) => {onChangeHandler('description', e.target.value, setNewData)}} />
            </div>

            <div className={`item-container label ${pageMode === SubscriptionPlansMode.UPDATE ? 'hidden' : ''}`}>
              <Selector className={'label'} dir={'rtl'} title={'Branch'} options={options} value={selectedBranch.value} onSelect={handleBranchOption}/>
            </div>

          </div>
        </div>

        <div className={`new-plan-footer ${loading ? 'loading' : ''}`}>
          <div className={`new-plan-add-button-container ${(currentAdminPermissions & 4096) !== 4096 ? 'disabled' : ''}`}>
            <ContainedButton title={pageMode === SubscriptionPlansMode.ADD ? 'Add' : 'Update'} onclickHandler={() => {handleAddUpdateClick()}} />
          </div>

          <div className='new-plan-cancel-button-container'>
            <TextButton title={'Cancel'} onclickHandler={() => {handleCancelClick()}} />
          </div>
        </div>

      </div>
    </div>
  )
}
