// base
import React from 'react'

// context
import { UsersSubscriptionsProvider } from './usersSubscriptionsContext';

// components
import UsersSubscriptionsMain from '../../components/users_subscriptions_components/users_subscriptions_main/UsersSubscriptionsMain';

export default function UsersSubscriptions() {
  return (
    <UsersSubscriptionsProvider>
      <UsersSubscriptionsMain />
    </UsersSubscriptionsProvider>
  )
}
