// base
import React, { useState, useEffect, useContext, useRef } from 'react';
import './lessonsCard.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// context
import { lessonsContext } from '../../../pages/Lessons/lessonsContext';

// icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TimelineIcon from '@mui/icons-material/Timeline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// import YouTubeIcon from '@mui/icons-material/YouTube';

// components
import CardDropDown from '../../card_dropdown/card_dropdown_menu/CardDropDown';
import CardDropdownItem from '../../card_dropdown/card_Dropdown_item/CardDropdownItem';

// logic
import { 
  LessonsModeEnum,
  MessageBoxActionsEnum,
} from '../../../pages/Lessons/lessonsLogic';

export default function LessonsCard({ lesson, _index }) {

  let menuRef  = useRef(null);

  const {
    setPageMode,
    setActionType,
    setLessonObjForAction
  } = useContext(lessonsContext)

  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [dropdownStatus, setDropdownStatus] = useState(false);

  useEffect(() => {
    let handler = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)){
        setDropdownStatus(false);
      }
    }

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  }, [])

  const onUpdateClickHandler = () => {
    setLessonObjForAction(lesson);
    setPageMode(LessonsModeEnum.UPDATE)
  }

  const onDeleteClickHandler = () => {
    setActionType(MessageBoxActionsEnum.DELETE);
    setLessonObjForAction(lesson);
  }

  return (
    <div className='lesson-card-component'>
      
      <div className='more-options-container' ref={menuRef}>
        <MoreHorizIcon className='more-icon' onClick={() => {setDropdownStatus(!dropdownStatus)}}/>
      
        <CardDropDown status={dropdownStatus} dir='rtl'>

          <Link to={`/transactions-history/${lesson?.lessonId}/Lessons`} className={`history`}>
            <CardDropdownItem 
              title={'History'}
              isDisabled={false}
              onClick={() => {
                setDropdownStatus(false);
              }} >
              <TimelineIcon />
            </CardDropdownItem>
          </Link>

          <CardDropdownItem 
            title={'Update'}
            isDisabled={(BigInt(currentAdminPermissions) & BigInt(1073741824)) !== BigInt(1073741824)}
            onClick={async() => {
              setDropdownStatus(false);
              onUpdateClickHandler();
            }} >
            <EditIcon />
          </CardDropdownItem>

          <CardDropdownItem 
            title={'Delete'}
            isDanger={true}
            isDisabled={(BigInt(currentAdminPermissions) & BigInt(2147483648)) !== BigInt(2147483648)}
            onClick={async() => {
              setDropdownStatus(false);
              onDeleteClickHandler();
            }} >
            <DeleteIcon />
          </CardDropdownItem>

        </CardDropDown>
      </div>
      
      <div className='lesson-info-container'>
        <p className='index'>{_index}/#</p>
        <p className='lesson-title'>{lesson?.lessonTitle}</p>
      </div>
      
    </div>
  )
}