// base
import React, { useContext, useEffect } from 'react';
import './typicalQuestionsTypesMain.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// context
import { typicalQuestionsTypesContext } from '../../../pages/typical_questions_types/typicalQuestionsTypesContext';

// logic
import { 
  resetMessage, 
  MessageBoxActionsEnum,
  TypicalQuestionsTypesModeEnum,
  deleteTypeAsync
} from '../../../pages/typical_questions_types/typicalQuestionsTypesLogic';

// components
import TypicalQuestionsTypesHeader from '../typical_questions_types_header/TypicalQuestionsTypesHeader';
import TypicalQuestionsTypesBody from '../typical_questions_types_body/TypicalQuestionsTypesBody';
import TypicalQuestionsTypesAddUpdateComponent from '../typical_questions_types_add_update_component/TypicalQuestionsTypesAddUpdateComponent';
import Model  from '../../model/Model';
import MessageBox from '../../message_box/MessageBox';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import Loading from '../../loading/Loading';

export default function TypicalQuestionsTypesMain() {
  const navigate = useNavigate();

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const { 
    reload,
    setReload,
    modelMessage, 
    setModelMessage,
    messageBox,
    setMessageBox,
    loading,
    setLoading,
    typeIdForDelete,
    pageMode,
    actionType, 
    setActionType
  } = useContext(typicalQuestionsTypesContext);     

  useEffect(() => {
    onActionTypeChangeHandler();
  }, [actionType])

  const onActionTypeChangeHandler = () => {
    switch (actionType) {
      case MessageBoxActionsEnum.DELETE:
        setMessageBox({
          title: 'Confirm Deletion',
          message: 'Are you sure you want to delete this typical question type? This action cannot be undone.',
          isVisible: true
        });
        break;

      default:
        return;
    }
  }

  const onConfirmAction = async() => {
    switch (actionType) {
      case MessageBoxActionsEnum.DELETE:
        await deleteTypeAsync
          (
            typeIdForDelete,
            currentAdminId,
            currentAdminPermissions,
            setModelMessage,
            setLoading,
            reload,
            setReload,
            navigate
          )
        break;

      default:
        return;
    }
    setActionType(MessageBoxActionsEnum.NONE);
  }

  return (
    <div className='typical-questions-types-main-component'>
      
      <Model 
        title={modelMessage.title} 
        message={modelMessage.message} 
        status={modelMessage.isVisible} 
        setStatus={setModelMessage} 
      />

      <MessageBox 
        title={messageBox.title} 
        message={messageBox.message} 
        isVisable={messageBox.isVisible} 
        onClose={() => {
          resetMessage(setMessageBox);
          setActionType(MessageBoxActionsEnum.NONE);
        }}
      >
        <div className='typical-questions-types-confirm-btn-container'>
          <ContainedButton title={'Confirm'} onclickHandler={async() => {
            resetMessage(setMessageBox);
            onConfirmAction();
          }} />
        </div>
      </MessageBox>

      <div className={`loading-container ${loading ? 'active' : ''}`}>
        <Loading isVisible={loading} />
      </div>

      <div className={`add-update-container ${pageMode === TypicalQuestionsTypesModeEnum.ADD ? '' : 'in-active'}`}>
        <TypicalQuestionsTypesAddUpdateComponent />
      </div>

      <div className={`add-update-container ${pageMode === TypicalQuestionsTypesModeEnum.UPDATE ? '' : 'in-active'}`}>
        <TypicalQuestionsTypesAddUpdateComponent />
      </div>
      
      <div className='typical-questions-types-header'>
        <TypicalQuestionsTypesHeader />
      </div>

      <div className='typical-questions-body'>
        <TypicalQuestionsTypesBody />
      </div>

    </div>
  )
}