// base
import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// data
import { setCurrentUser } from './global/data/global_data'; 
import { getCurrentAdminFromSession } from './global/utils/session_storage/sessionStorage';

// pages
import Login from './pages/login/Login';
import ResetPassword from './pages/reset_password/ResetPassword';
import Dashboard from './pages/dashboard/Dashboard';
import Main from './pages/main/Main';
import Error from './pages/error/Error';
import PermissionsDenied from './pages/permissions_denied/PermissionsDenied';

// admins pages
import Admins from './pages/admins/admins/Admins';
import AdminProfile from './pages/admins/admin_profile/AdminProfile';
import CurrentAdminProfile from './pages/admins/current_admin_profile/CurrentAdminProfile';

// users pages
import Users from './pages/users/users/Users';
import UserDetails from './pages/users/user_details/UserDetails';

// errors pages
import Errors from './pages/errors/errors/Errors';

// history
import OperationsHistory from './pages/operations_history/operations_history/OperationsHistory';

// transactions
import Transactions from './pages/transactions/transactions/Transactions';

// additions
import Additions from './pages/additions/Additions';

// deletions
import Deletions from './pages/deletions/Deletions';

// updates
import Updates from './pages/updates/Updates';

// general questions
import GeneralQuestions from './pages/general_questions/GeneralQuestions';

// subscription plans
import SubscriptionPlans from './pages/subscription_plans/SubscriptionPlans';

// users subscriptions
import UsersSubscriptions from './pages/users_subscriptions/UsersSubscriptions';

// branches
import Branches from './pages/branches/Branches';

// academic years
import AcademicYears from './pages/academic_years/AcademicYears';

// typical questions types
import TypicalQuestionsTypes from './pages/typical_questions_types/TypicalQuestionsTypes';

// materials
import Materials from './pages/materials/Materials';

// typical questions
import TypicalQuestions from './pages/typical_questions/TypicalQuestions';

// acceptance rates
import AcceptanceRates from './pages/acceptance_rates/AcceptanceRates';

// acceptance rates files
import AcceptanceRatesFiles from './pages/acceptance_rates_files/AcceptanceRatesFiles';

// courses
import Courses from './pages/courses/Courses';

// courses sections
import CoursesSections from './pages/courses_sections/CoursesSections';

// lessons
import Lessons from './pages/Lessons/Lessons';

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const user = getCurrentAdminFromSession();
    if (user) {
      dispatch(setCurrentUser(user));
    } else {
      navigate('/login');
    }

  }, []);

  return (
    <div className="App">
      <Routes>
        
        <Route path='*' element={<Error />} />

        <Route path='/login' element={<Login />} />
        <Route path='/reset-password' element={<ResetPassword />} />

        <Route path='/' element={<ProtectedRoute><Dashboard /></ProtectedRoute>}>
          <Route index element={<Main />} />
          <Route path='home' element={<Main />} />

          <Route path="admins">
            <Route index element={<ProtectedRoute requiredPermissions={1}><Admins /></ProtectedRoute>} />
            <Route path='profile/:adminId' element={<ProtectedRoute requiredPermissions={1}><AdminProfile /></ProtectedRoute>} />
            <Route path=":id" element={<ProtectedRoute><CurrentAdminProfile /></ProtectedRoute>} />
          </Route>

          <Route path='users'>
            <Route index element={<ProtectedRoute requiredPermissions={64}><Users /></ProtectedRoute>} />
            <Route path=":id" element={<ProtectedRoute requiredPermissions={64}><UserDetails /></ProtectedRoute>} />
            <Route path="subscriptions" element={<ProtectedRoute requiredPermissions={8192}><UsersSubscriptions /></ProtectedRoute>} />
          </Route>

          <Route path='errors'>
            <Route index element={<ProtectedRoute requiredPermissions={128}><Errors /></ProtectedRoute>} />
          </Route>

          <Route path='transactions-history'>
            <Route path=":id/:table" element={<ProtectedRoute requiredPermissions={256}><OperationsHistory /></ProtectedRoute>} />
          </Route>

          <Route path='my-history'>
            <Route path=":id/:table" element={<ProtectedRoute><OperationsHistory /></ProtectedRoute>} />
          </Route>

          <Route path='transactions'>
            <Route path=":id/:table" element={<ProtectedRoute requiredPermissions={256}><Transactions /></ProtectedRoute>} />
          </Route>

          <Route path='my-transactions'>
            <Route path=":id/:table" element={<ProtectedRoute><Transactions /></ProtectedRoute>} />
          </Route>

          <Route path='additions'>
            <Route index element={<ProtectedRoute requiredPermissions={256}><Additions /></ProtectedRoute>} />
          </Route>

          <Route path='deletions'>
            <Route index element={<ProtectedRoute requiredPermissions={256}><Deletions /></ProtectedRoute>} />
          </Route>

          <Route path='updates'>
            <Route index element={<ProtectedRoute requiredPermissions={256}><Updates /></ProtectedRoute>} />
          </Route>

          <Route path='questions'>
            <Route path='general' element={<ProtectedRoute requiredPermissions={512}><GeneralQuestions /></ProtectedRoute>} />
          </Route>

          <Route path='subscription-plans'>
            <Route index element={<ProtectedRoute requiredPermissions={2048}><SubscriptionPlans /></ProtectedRoute>} />
          </Route>

          <Route path='branches'>
            <Route index element={<ProtectedRoute requiredPermissions={16384}><Branches /></ProtectedRoute>} />
          </Route>

          <Route path='academic'>
            <Route path="years" element={<ProtectedRoute requiredPermissions={65536}><AcademicYears /></ProtectedRoute>} />
          </Route>

          <Route path='typical'>
            <Route path="questions" element={<ProtectedRoute requiredPermissions={4194304}><TypicalQuestions /></ProtectedRoute>} />
            <Route path="questions-types" element={<ProtectedRoute requiredPermissions={262144}><TypicalQuestionsTypes /></ProtectedRoute>} />
          </Route>

          <Route path='materials'>
            <Route index element={<ProtectedRoute requiredPermissions={1048576}><Materials /></ProtectedRoute>} />
          </Route>

          <Route path='acceptance-rates'>
            <Route index element={<ProtectedRoute requiredPermissions={16777216}><AcceptanceRates /></ProtectedRoute>} />
            <Route path="files" element={<ProtectedRoute requiredPermissions={67108864}><AcceptanceRatesFiles /></ProtectedRoute>} />
          </Route>

          <Route path='courses'>
            <Route index element={<ProtectedRoute requiredPermissions={268435456}><Courses /></ProtectedRoute>} />
            <Route path=":courseId" element={<ProtectedRoute requiredPermissions={268435456}><CoursesSections /></ProtectedRoute>} />
            <Route path="sections/:sectionId" element={<ProtectedRoute requiredPermissions={268435456}><Lessons /></ProtectedRoute>} />
          </Route>

        </Route>
      </Routes>
    </div>
  );
}

function ProtectedRoute({ requiredPermissions, children }) {
  const currentAdminPermissions = getCurrentAdminFromSession()?.permissions || 0;

  if (requiredPermissions && currentAdminPermissions >= 0) {
    if ((currentAdminPermissions & requiredPermissions) !== requiredPermissions) {
      return <PermissionsDenied />;
    }
  }

  const user = getCurrentAdminFromSession();
  if (!user) {
    return <Navigate to='/login' />; 
  }

  return children;
}

export default App;
