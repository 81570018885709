import React, { createContext, useState } from 'react';
import { SubscriptionPlansMode, MessageBoxActionsEnum } from './subscriptionPlansLogic';

export const subscriptionPlansContext = createContext();

export const SubscriptionPlansProvider = ({ children }) => {

  const [plans, setPlans] = useState([]);
  const [branches, setBranches] = useState([]);
  const [pageMode, setPageMode] = useState(SubscriptionPlansMode.READ);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);
  const [actionType, setActionType] = useState(MessageBoxActionsEnum.NONE);
  const [planIdForChangeStatus, setPlanIdForChangeStatus] = useState(0);
  const [planIdForDelete, setPlanIdForDelete] = useState(0);
  const [planObjForUpdate, setPlanObjForUpdate] = useState(null);

  return (
  <subscriptionPlansContext.Provider
    value={{
      plans,
      setPlans,
      branches,
      setBranches,
      pageMode,
      setPageMode,
      loading,
      setLoading,
      modelMessage,
      setModelMessage,
      messageBox,
      setMessageBox,
      reload,
      setReload,
      actionType, 
      setActionType,
      planIdForChangeStatus,
      setPlanIdForChangeStatus,
      planIdForDelete,
      setPlanIdForDelete,
      planObjForUpdate,
      setPlanObjForUpdate
    }}
  >
      {children}
  </subscriptionPlansContext.Provider>
  );
};
