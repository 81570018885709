// base
import React, { useContext } from 'react';
import './yearsBody.css';

// context
import { academicYearsContext } from '../../../pages/academic_years/academicYearsContext';

// components
import YearsCard from '../years_card/YearsCard';

export default function YearsBody() {
  const {
    years
  } = useContext(academicYearsContext);

  return (
    <div className='years-body-component'>
      
      {years.length > 0 ? (
        years.map((year, index) => (
          <div key={year.yearId || index} className='year-card-container'>
            <YearsCard year={year} _index={index + 1} />
          </div>
        ))
      ) : (
        <div className="no-years-message">
          No academic years found.
        </div>
      )}

    </div>
  )
}
