// base
import React from 'react'

// context
import { AcceptanceRatesProvider } from './acceptanceRatesContext';

// components
import RatesMain from '../../components/acceptance_rates_components/rates_main/RatesMain';

export default function Materials() {
  return (
    <AcceptanceRatesProvider>
      <RatesMain />
    </AcceptanceRatesProvider>
  )
}
