// base
import React, { useContext, useEffect } from 'react'
import './usersSubscriptionsHeader.css';

// context
import { usersSubscriptionsContext } from '../../../pages/users_subscriptions/usersSubscriptionsContext';

// icons
import { MdTimer } from "react-icons/md";
import { MdTimerOff } from "react-icons/md";

// components 
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// logic
import {
  UsersSubscriptionsMode,
  getActiveUsersSubscriptionsAsync,
  getActivesPagesCount,
  getActivesSubscriptionsCount,
  getInActiveUsersSubscriptionsAsync,
  getInActivesPagesCount,
  getInActivesSubscriptionsCount
} from '../../../pages/users_subscriptions/usersSubscriptionsLogic';

export default function UsersSubscriptionsHeader() {
  const {
    setSubscriptions, 
    subscriptionsMode,
    setSubscriptionsMode,
    activesCount,
    setActivesCount,
    inActivesCount,
    setInActivesCount,
    currentPageNumber,
    setCurrentPageNumber,
    setPagesCount,
    setLoading
  } = useContext(usersSubscriptionsContext);

  useEffect(() => {
    const fetchData = async () => {
      await getSubscriptionsWithPagesCount();
    }

    fetchData();
  }, [currentPageNumber, subscriptionsMode])

  async function getSubscriptionsWithPagesCount() {
    try {
      setLoading(true);
      setActivesCount(await getActivesSubscriptionsCount());
      setInActivesCount(await getInActivesSubscriptionsCount());
      
      switch (subscriptionsMode) {
        case UsersSubscriptionsMode.ACTIVES:
          setPagesCount(await getActivesPagesCount());
          setSubscriptions(await getActiveUsersSubscriptionsAsync(currentPageNumber));
          break;

        case UsersSubscriptionsMode.INACTIVES:
          setPagesCount(await getInActivesPagesCount());
          setSubscriptions(await getInActiveUsersSubscriptionsAsync(currentPageNumber));
          break;

        default:
          break;
      }

    } catch (error) {
      console.error('Error fetching users subscriptions with pages count:', error);
    } finally {
      setLoading(false);
    }
  }

  const onActiveSubscriptionsClicked = () => {
    setCurrentPageNumber(1);
    setSubscriptionsMode(UsersSubscriptionsMode.ACTIVES);
  }

  const onInActiveSubscriptionsClicked = () => {
    setCurrentPageNumber(1);
    setSubscriptionsMode(UsersSubscriptionsMode.INACTIVES);
  }

  return (
    <div className="users-subscriptions-header-component">
      <div className="item-container">
        <AnalyticalCard 
          title={'Active Subscriptions'} 
          icon={MdTimer} 
          value={activesCount} 
          isActive={subscriptionsMode === UsersSubscriptionsMode.ACTIVES} 
          onClick={onActiveSubscriptionsClicked} />
      </div>

      <div className="item-container">
        <AnalyticalCard 
          title={'Inactive Subscriptions'} 
          icon={MdTimerOff} 
          value={inActivesCount} 
          isActive={subscriptionsMode === UsersSubscriptionsMode.INACTIVES} 
          onClick={onInActiveSubscriptionsClicked} />
      </div>
    </div>
  )
}
