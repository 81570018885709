// base
import React, { useContext } from 'react';
import './coursesBody.css';

// context
import { coursesContext } from '../../../pages/courses/coursesContext';

// components
import CoursesCard from '../courses_card/CoursesCard';

export default function CoursesBody() {
  const {
    courses
  } = useContext(coursesContext);

  return (
    <div className='courses-body-component'>

      {courses.length > 0 ? (
        courses.map((course, index) => (
          <div key={course.courseId || index} className='course-card-container'>
            <CoursesCard course={course} />
          </div>
        ))
      ) : (
        <div className="no-courses-message">
          No courses found.
        </div>
      )}

    </div>
  )
}
