// base
import './sideBar.css';
import SideBarSection from '../sidebar_section/SideBarSection';
import SideBarSectionItem from '../sidebar_section_item/SideBarSectionItem';

// icons
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WidgetsIcon from '@mui/icons-material/Widgets';
import LayersIcon from '@mui/icons-material/Layers';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';

export default function SideBar({activeItem, setActiveItem }) {

  const toggleSidebarClass = () => {
    if (window.innerWidth <= 600)
    document.body.classList.toggle('toggle-sidebar');
  };

  const handleItemClick = (itemTitle) => {
    setActiveItem(itemTitle);
    toggleSidebarClass();
  };

  return (
    <aside id='sidebar-container' className='sidebar-container'>
      <ul id='sidebar-menu' className='sidebar-menu'>
        <SideBarSection title={'People'} icon={PeopleAltIcon}>
          <SideBarSectionItem 
            path={'/admins'}
            title={'Admins'} 
            isActive={activeItem === 'Admins'} 
            onClick={() => handleItemClick('Admins')}
          />
          <SideBarSectionItem 
            path={'/users'}
            title={'Users'} 
            isActive={activeItem === 'Users'} 
            onClick={() => handleItemClick('Users')}
          />
        </SideBarSection>

        <SideBarSection title={'Transactions'} icon={TransferWithinAStationIcon}>
          <SideBarSectionItem 
            path={'additions'}
            title={'Additions'} 
            isActive={activeItem === 'Additions'} 
            onClick={() => handleItemClick('Additions')}
          />
          <SideBarSectionItem 
            path={'deletions'}
            title={'Deletions'} 
            isActive={activeItem === 'Deletions'} 
            onClick={() => handleItemClick('Deletions')}
          />
          <SideBarSectionItem 
            path={'updates'}
            title={'Updates'} 
            isActive={activeItem === 'Updates'} 
            onClick={() => handleItemClick('Updates')}
          />
        </SideBarSection>

        <SideBarSection title={'Subscriptions'} icon={AssignmentIcon}>
          <SideBarSectionItem 
              path={'subscription-plans'}
              title={'Subscription Plans'} 
              isActive={activeItem === 'Subscription Plans'} 
              onClick={() => handleItemClick('Subscription Plans')}
            />
            <SideBarSectionItem 
              path={'users/subscriptions'}
              title={'Users Subscriptions'} 
              isActive={activeItem === 'Users Subscriptions'} 
              onClick={() => handleItemClick('Users Subscriptions')}
            />
        </SideBarSection>

        <SideBarSection title={'Resources'} icon={WidgetsIcon}>
          <SideBarSectionItem 
            path={'courses'}
            title={'Courses'} 
            isActive={activeItem === 'Courses'} 
            onClick={() => handleItemClick('Courses')}
          />
          <SideBarSectionItem
            path={'typical/questions'}
            title={'Typical Questions'} 
            isActive={activeItem === 'Typical Questions'} 
            onClick={() => handleItemClick('Typical Questions')}
          />
          <SideBarSectionItem
            path={'acceptance-rates'}
            title={'Acceptance Rates'} 
            isActive={activeItem === 'Acceptance Rates'} 
            onClick={() => handleItemClick('Acceptance Rates')}
          />
          <SideBarSectionItem 
            path={'acceptance-rates/files'}
            title={'Acceptance Rates Files'} 
            isActive={activeItem === 'Acceptance Rates Files'} 
            onClick={() => handleItemClick('Acceptance Rates Files')}
          />
          <SideBarSectionItem 
            path={'questions/general'}
            title={'General Questions'} 
            isActive={activeItem === 'General Questions'} 
            onClick={() => handleItemClick('General Questions')}
          />
        </SideBarSection>

        <SideBarSection title={'Base'} icon={LayersIcon}>
          <SideBarSectionItem 
            path={'academic/years'}
            title={'Academic Years'} 
            isActive={activeItem === 'Academic Years'} 
            onClick={() => handleItemClick('Academic Years')}
          />
          <SideBarSectionItem 
            path={'branches'}
            title={'Branches'} 
            isActive={activeItem === 'Branches'} 
            onClick={() => handleItemClick('Branches')}
          />
          <SideBarSectionItem 
            path={'materials'}
            title={'Materials'} 
            isActive={activeItem === 'Materials'} 
            onClick={() => handleItemClick('Materials')}
          />
          <SideBarSectionItem 
            path={'typical/questions-types'}
            title={'Typical Questions Types'} 
            isActive={activeItem === 'Typical Questions Types'} 
            onClick={() => handleItemClick('Typical Questions Types')}
          />
        </SideBarSection>

        <SideBarSection title={'System'} icon={RoomPreferencesIcon}>
          <SideBarSectionItem 
            path={'/errors'}
            title={'Errors'} 
            isActive={activeItem === 'Errors'} 
            onClick={() => handleItemClick('Errors')}
          />

          {/* <SideBarSectionItem 
            title={'etc'} 
            isActive={activeItem === 'etc'} 
            onClick={() => handleItemClick('etc')}
          /> */}
        </SideBarSection>
      </ul>
    </aside>
  );
}
