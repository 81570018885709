// base
import React, { useState, useContext, useEffect } from 'react';
import './subscriptionPlanCard.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// context
import { subscriptionPlansContext } from '../../../pages/subscription_plans/subscriptionPlansContext';

// icons
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

// components
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';

// logic
import { getDate } from '../../../global/utils/functions/utils';
import { 
  SubscriptionPlansMode,
  MessageBoxActionsEnum,
  setDescriptionAsync
} from '../../../pages/subscription_plans/subscriptionPlansLogic';

export default function SubscriptionPlanCard({ plan, _index }) {
  
  const {
    setPageMode,
    setModelMessage,
    reload,
    setReload,
    setActionType,
    setPlanIdForChangeStatus,
    setPlanIdForDelete,
    setPlanObjForUpdate
  } = useContext(subscriptionPlansContext)

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [openMode, setOpenMode] = useState(false);
  const [isDescriptionUpdateMode, setDescriptionAsUpdateMode] = useState(false);
  const [descriptionLoader, setDescriptionLoader] = useState(false);
  const [newDescription, setNewDescription] = useState('');

  useEffect(() => {
    setNewDescription(handleDescription(plan?.description));
  }, [plan?.description])

  const OnDoneClickHandler = async () => {
      if (plan?.description.length > 250)
        return;
      const response = await setDescriptionAsync(
        plan?.planId,
        newDescription,
        currentAdminId,
        currentAdminPermissions,
        setDescriptionLoader,
        setModelMessage
      );

      if (response) {
        setDescriptionAsUpdateMode(false);
        setReload(!reload);
      }
  }

  function handleDescription(description) {
    if (!description)
      return '';
    else if (description === 'null')
      return '';
    else
      return description;
  }

  const onChangeStatusClickHandler = () => {
    if (plan?.isActive === true) {
      setActionType(MessageBoxActionsEnum.DEACTIVATE);

    } else if (plan?.isActive === false) {
      setActionType(MessageBoxActionsEnum.ACTIVATE);
    }

    setPlanIdForChangeStatus(plan?.planId);
  }

  const onUpdateClickHandler = () => {
    setPlanObjForUpdate(plan);
    setPageMode(SubscriptionPlansMode.UPDATE)
  }

  const onDeleteClickHandler = () => {
    setActionType(MessageBoxActionsEnum.DELETE);
    setPlanIdForDelete(plan?.planId);
  }

  return (
    <div className='subscription-plan-card-component'>

      <div className='plan-header' onClick={() => {setOpenMode(!openMode)}}>
        <div className='index-container'>
          <p className='label index'>#/{_index}</p>
        </div>

        <div className='plan-name-container'>
          <p className='label'>Plan Name</p>
          <p className='value'>{plan?.planName}</p>
        </div>

        <div className='branch-name-container'>
          <p className='label'>Branch Name</p>
          <p className='value'>{plan?.branchName}</p>
        </div>

        <div className='status-container'>
          <p className='label'>Status</p>
          <p className={`value ${plan?.isActive ? 'active' : 'in-active'}`}>{plan?.isActive ? 'active' : 'inactive'}</p>
        </div>

        <div className='icon-container'>
          {openMode ? (
            <FaRegArrowAltCircleUp className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          ) : (
            <FaRegArrowAltCircleDown className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          )}
        </div>
      </div>

      <div className={`card-body ${openMode ? 'opened' : ''}`}>

        <div className='item description'>
          <div className='description-header'>
            <p className='label'>Description</p>
            <MdEdit fontSize={''} className={`description-icon ${(currentAdminPermissions & 4096) !== 4096 ? 'disabled' : ''}`} onClick={() => {setDescriptionAsUpdateMode(!isDescriptionUpdateMode)}} />
          </div>

          <pre className={`value ${isDescriptionUpdateMode ? 'hidden' : ''}`}>
            {handleDescription(plan?.description) ? handleDescription(plan?.description) : 'no description'}
          </pre>
          
          <div className={`input-description-container ${isDescriptionUpdateMode ? '' : 'hidden'}`}>
            <textarea 
                className='description-input' 
                placeholder='Description' 
                maxLength={250} 
                rows="4" 
                value={newDescription} 
                onChange={(e) => {setNewDescription(e.target.value)}}
              />

              <div className='description-btn-container'>
                <p className={`description-btn ${descriptionLoader ? 'loading' : ''}`} onClick={async () => {OnDoneClickHandler()}}>
                  Done
                </p>
                <div className={`description-input-loader ${descriptionLoader ? 'active' : ''}`}></div>
              </div>
          </div>
        </div>
        
        <div className='item inline'>
          <p className='label'>Daily Duration</p>
          <p className='value'>{plan?.dailyDuration} days</p>
        </div>

        <div className='item inline'>
          <p className='label'>Monthly Duration</p>
          <p className='value'>{plan?.monthlyDuration} months</p>
        </div>

        <div className='item inline'>
          <p className='label'>Price</p>
          <p className='value'>{plan?.price} $</p>
        </div>

        <div className='item inline'>
          <p className='label'>Discount</p>
          <p className='value'>{plan?.discount}%</p>
        </div>

        <div className='item inline'>
          <p className='label'>Final Price</p>
          <p className='value'>{plan?.finalPrice} $</p>
        </div>

        <div className='item inline'>
          <p className='label'>Pricing Status</p>
          <p className='value'>
            {plan?.isFreeTrail ? 'Free Trial' : 'Not Free'}
          </p>
        </div>

        <div className='item inline'>
          <p className='label'>View History</p>

          <Link to={`/transactions-history/${plan?.planId}/Subscription Plans`}>
            <p className='value history'>history</p>
          </Link>

        </div>

        <div className='item inline'>
          <p className='label'>Registrated On</p>
          <p className='value'>{getDate(plan?.createdAt)}</p>
        </div>

        <div className={`item inline action`}>
          <p className='label'>{plan?.isActive ? 'Deactivate' : 'activate'}</p>
          <div className={`btn-container ${(currentAdminPermissions & 4096) !== 4096 ? 'disabled' : ''}`}>
            <ContainedButton title={plan?.isActive ? 'Deactivate' : 'activate'} onclickHandler={onChangeStatusClickHandler} />
          </div>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Update</p>
          <div className={`btn-container ${(currentAdminPermissions & 4096) !== 4096 ? 'disabled' : ''}`}>
            <ContainedButton title={'Update'} onclickHandler={onUpdateClickHandler} />
          </div>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Delete</p>
          <div className={`btn-container ${(currentAdminPermissions & 4096) !== 4096 ? 'disabled' : ''}`}>
            <ContainedButton title={'Delete'} onclickHandler={onDeleteClickHandler} type='danger' />
          </div>
        </div>

      </div>

    </div>
  )
}
