import React from "react";
import "./userAvater.css";
import ProfileDropDown from "../profile_dropdown/ProfileDropDown";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

export default function UserAvater() {
  const [dropdownStatus, setDropdownStatus] = useState(false);
  let menuRef = useRef();

  const username = useSelector((state) => state.currentUser.userName);
  const profileImage = useSelector(
    (state) => state.currentUser.profileImageURL
  );

  useEffect(() => {
    let handler = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setDropdownStatus(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  function onClickHandler() {
    setDropdownStatus(!dropdownStatus);
  }

  const renderProfileImage = () => {
    if (profileImage) {
      return <img src={profileImage} alt="profile" className="profile-image" />;
    } else {
      const initials = username ? username.slice(0, 1).toUpperCase() : "";
      return <div className="profile-initials">{initials}</div>;
    }
  };

  return (
    <div className="user-avater-container" ref={menuRef}>
      <div className="username-image-container" onClick={onClickHandler}>
        {renderProfileImage()}
        <span className="profile-username">{username}</span>
      </div>

      <ProfileDropDown
        status={dropdownStatus}
        itemOnClickHandler={onClickHandler}
      />
    </div>
  );
}
