import axios from 'axios';
import config from '../../global/config';

import {
  fetchScalarData,
  fetchMultipleData,
  patchData,
  deleteData
} from '../../global/utils/functions/apiService';

import {
  checkLength,
  isInt,
  isFloat
} from '../../global/utils/validation';

const SubscriptionPlansMode = Object.freeze({
  READ: 'read',
  ADD: 'add',
  UPDATE: 'update'
});

const MessageBoxActionsEnum = Object.freeze({
  NONE: 'none',
  ACTIVATE: 'activate',
  DEACTIVATE: 'de-activate',
  DELETE: 'delete'
})

function onChangeHandler(field, newValue, state) {
  state(prevState => ({
    ...prevState,
    [field]: newValue
  }));
}

function validatePlanName (input) {
  return checkLength(1, 100, input);
}

function validatePrice (input) {
  return isFloat(input);
}

function validateDiscount (input) {
  return isInt(input) && input <= 100;
}

function validateMonthlyAndDailyDuration (input) {
  return isInt(input) && input <= 100;
} 

function validatePlanForAddNew (plan) {
  if (!validatePlanName(plan.planName))
    return false;

  if (!validatePrice(plan.price))
    return false;

  if (!validateDiscount(plan.discount))
    return false;

  if (!validateMonthlyAndDailyDuration(plan.monthlyDuration))
    return false;

  if (!validateMonthlyAndDailyDuration(plan.dailyDuration))
    return false;

  if (plan.description.length > 250)
    return false;

  if (!plan.branchId || !Number.isInteger(parseInt(plan.branchId)) || parseInt(plan.branchId) <= 0)
    return false;

  return true;
}

function validatePlanForUpdate (plan) {
  if (!validatePlanName(plan.planName))
    return false;

  if (!validatePrice(plan.price))
    return false;

  if (!validateDiscount(plan.discount))
    return false;

  if (!validateMonthlyAndDailyDuration(plan.monthlyDuration))
    return false;

  if (!validateMonthlyAndDailyDuration(plan.dailyDuration))
    return false;

  return true;
}

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

async function getBranches() {
  const url = '/Branches/GetAllBranches';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const branches = response?.data?.Response?.data?.branches || [];

    return branches;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getSubscriptionPlans(branchId) {
  const url = `/SubscriptionPlans/GetPlansByBranch/${branchId}`;

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const plans = response?.data?.Response?.data?.subscriptionPlans || [];

    return plans;
  } else {
    console.log(response.message);
    return [];
  }
}

async function setDescriptionAsync(planId, description, currentAdminId, currentAdminPermissions, loaderState, messageState) {
  try {
    
    if (!currentAdminPermissions) {
      return;
    }
    else if ((currentAdminPermissions & 4096) !== 4096) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }
    
    const url = `/SubscriptionPlans/SetSubscriptionPlanDescription/${planId}/${description || 'null'}/${currentAdminId}`;
    loaderState(true);
    const response = await patchData(url);

    if (response.message === 'Success.') {
      messageState({
        title: 'Success',
        message: 'The description has been added successfully.',
        isVisible: true
      });
      return true;
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to add the description. Please check the input information and try again.',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred. Please try again later.',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
}

async function activatePlanAsync(
  planId, currentAdminId, currentAdminPermissions, messageState,
  loaderState, reload, setReload, navigate
  ) {
  try {

    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((currentAdminPermissions & 4096) !== 4096) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);
    const response = await patchData(`/SubscriptionPlans/SetSubscriptionPlanAsActive/${planId}/${currentAdminId}`);

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'Subscription plan has been activated successfully.',
        isVisible: true
      });
      return true;
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to activate subscription plan. Please check your permissions and try again.',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while activating the subscription plan. Please try again later.',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
    setReload(!reload);
  }
}

async function deactivatePlanAsync(
  planId, currentAdminId, currentAdminPermissions, messageState,
  loaderState, reload, setReload, navigate
  ) {
  try {

    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((currentAdminPermissions & 4096) !== 4096) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);
    const response = await patchData(`/SubscriptionPlans/SetSubscriptionPlanAsInActive/${planId}/${currentAdminId}`);

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'Subscription plan has been deactivated successfully.',
        isVisible: true
      });
      return true;
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to deactivate subscription plan. Please check your permissions and try again.',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deactivating the subscription plan. Please try again later.',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
    setReload(!reload);
  }
}

async function isPlanHasRelationsAsync(planId) {
  const url = `/SubscriptionPlans/IsSubscriptionPlanHasRelations/${planId}`;

  try {
    const response = await fetchScalarData(url);

    if (response.data.Response.data.hasRelations === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('An error occurred while fetching the data:', error);
    return false;
  }
}

async function deletePlanAsync(planId, byAdmin, currentAdminPermissions, messageState, loaderState, reload, setReload, navigate) {
  try {
    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((currentAdminPermissions & 4096) !== 4096) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);
    
    const hasRelations = await isPlanHasRelationsAsync(planId);

    if (hasRelations === true) {
      messageState({
        title: 'Action Required',
        message: 'This subscription plan is linked to other records. Please remove the dependencies before attempting to delete it.',
        isVisible: true,
      });
      
      return;
    }

    const response = await deleteData(`/SubscriptionPlans/DeleteSubscriptionPlan/${planId}/${byAdmin}`);
    
    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'Subscription plan has been deleted successfully.',
        isVisible: true
      });
      setReload(!reload);
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to delete subscription plan. Please check your permissions and try again.',
        isVisible: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deleting the subscription plan. Please try again later.',
      isVisible: true
    });
  } finally {
    loaderState(false);
  }
}

async function newPlanAsync(plan, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (validatePlanForAddNew(plan) === false) {
      setMessage({
        message: 'Invalid subscription plan data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    if ((currentAdminPermissions & 4096) !== 4096) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }
    
    loaderState(true);
    const url = `${config.apiBaseUrl}/SubscriptionPlans/NewSubscriptionPlan`;

    const response = await axios.post(url, {
      planName: plan.planName,
      price: plan.price,
      discount: plan.discount,
      monthlyDuration: plan.monthlyDuration,
      dailyDuration: plan.dailyDuration,
      description: plan.description,
      branchId: plan.branchId,
      byAdmin: byAdmin
    });

    if (response.status === 201) {
      setMessage({
        message: 'Subscription plan inserted successfully.',
        type: 'success',
        isVisible: true
      });
      return true;
    } else {
      setMessage({
        message: 'Failed to insert new subscription plan.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.response?.data?.title}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
};

async function updatePlanAsync(plan, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (validatePlanForUpdate(plan) === false) {
      setMessage({
        message: 'Invalid subscription plan data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    if ((currentAdminPermissions & 4096) !== 4096) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }
    loaderState(true);
    const url = `${config.apiBaseUrl}/SubscriptionPlans/UpdateSubscriptionPlan`;

    const response = await axios.put(url, {
      planId: plan.planId,
      planName: plan.planName,
      price: plan.price,
      discount: plan.discount,
      monthlyDuration: plan.monthlyDuration,
      dailyDuration: plan.dailyDuration,
      byAdmin: byAdmin
    });

    if (response.status === 200) {
      return true;
    } else {
      setMessage({
        message: 'Failed to update subscription plan.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.response?.data?.title}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
};

export {
  SubscriptionPlansMode,
  MessageBoxActionsEnum,
  validatePlanName,
  validatePrice,
  validateDiscount,
  validateMonthlyAndDailyDuration,
  onChangeHandler,
  resetMessage,
  getBranches,
  getSubscriptionPlans,
  setDescriptionAsync,
  activatePlanAsync,
  deactivatePlanAsync,
  deletePlanAsync,
  newPlanAsync,
  updatePlanAsync
}