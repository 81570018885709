// base
import React, { useState, useContext } from 'react';
import './materialsCard.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// context
import { materialsContext } from '../../../pages/materials/materialsContext';

// icons
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

// components
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';

// logic
import { 
  MaterialsModeEnum,
  MessageBoxActionsEnum
} from '../../../pages/materials/materialsLogic';

export default function MaterialsCard({ material, _index }) {
  
  const {
    setPageMode,
    setActionType,
    setMaterialIdForDelete,
    setMaterialObjForUpdate
  } = useContext(materialsContext)

  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [openMode, setOpenMode] = useState(false);

  const onUpdateClickHandler = () => {
    setMaterialObjForUpdate(material);
    setPageMode(MaterialsModeEnum.UPDATE)
  }

  const onDeleteClickHandler = () => {
    setActionType(MessageBoxActionsEnum.DELETE);
    setMaterialIdForDelete(material?.materialId);
  }

  return (
    <div className='materials-card-component'>

      <div className='material-card-header' onClick={() => {setOpenMode(!openMode)}}>
        <div className='index-container'>
          <p className='label index'>#/{_index}</p>
        </div>

        <div className='material-name-container'>
          <p className='label'>Material Name</p>
          <p className='value'>{material?.materialName}</p>
        </div>

        <div className='branch-name-container'>
          <p className='label'>Branch Name</p>
          <p className='value'>{material?.branchName}</p>
        </div>

        <div className='icon-container'>
          {openMode ? (
            <FaRegArrowAltCircleUp className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          ) : (
            <FaRegArrowAltCircleDown className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          )}
        </div>
      </div>

      <div className={`card-body ${openMode ? 'opened' : ''}`}>

        <div className='item inline'>
          <p className='label'>View History</p>

          <Link to={`/transactions-history/${material?.materialId}/Materials`}>
            <p className='value history'>history</p>
          </Link>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Update</p>
          <div className={`btn-container ${(currentAdminPermissions & 2097152) !== 2097152 ? 'disabled' : ''}`}>
            <ContainedButton title={'Update'} onclickHandler={onUpdateClickHandler} />
          </div>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Delete</p>
          <div className={`btn-container ${(currentAdminPermissions & 2097152) !== 2097152 ? 'disabled' : ''}`}>
            <ContainedButton title={'Delete'} onclickHandler={onDeleteClickHandler} type='danger' />
          </div>
        </div>

      </div>

    </div>
  )
}
