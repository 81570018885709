import React, { createContext, useState } from 'react';
import { SectionsModeEnum, MessageBoxActionsEnum } from './coursesSectionsLogic';

export const coursesSectionsContext = createContext();

export const CoursesSectionsProvider = ({ children }) => {

  const [sections, setSections] = useState([]);
  const [pageMode, setPageMode] = useState(SectionsModeEnum.READ);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);
  const [sectionObjForAction, setSectionObjForAction] = useState(null);
  const [actionType, setActionType] = useState(MessageBoxActionsEnum.NONE);

  return (
  <coursesSectionsContext.Provider
    value={{
      sections, setSections,
      pageMode, setPageMode,
      loading, setLoading,
      modelMessage, setModelMessage,
      messageBox, setMessageBox,
      reload, setReload,
      sectionObjForAction, setSectionObjForAction,
      actionType, setActionType
    }}
  >
      {children}
  </coursesSectionsContext.Provider>
  );
};
