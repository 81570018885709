// base
import React, { useContext } from 'react';
import './typicalQuestionsBody.css';

// context
import { typicalQuestionsContext } from '../../../pages/typical_questions/typicalQuestionsContext';

// components
import TypicalQuestionsCard from '../typical_questions_card/TypicalQuestionsCard';

export default function TypicalQuestionsBody() {
  const {
    questions
  } = useContext(typicalQuestionsContext);

  return (
    <div className='typical-questions-body-component'>
      
      {questions.length > 0 ? (
        questions.map((question, index) => (
          <div key={question.questionId || index} className='typical-question-card-container'>
            <TypicalQuestionsCard question={question} _index={index + 1} />
          </div>
        ))
      ) : (
        <div className="no-typical-questions-message">
          No typical questions found.
        </div>
      )}

    </div>
  )
}
