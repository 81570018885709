import { fetchMultipleData, fetchScalarData } from "../../global/utils/functions/apiService";

async function getUsersAnalyticsAsync() {
  const url = `/Users/GetUsersAnalytics`;

  const response = await fetchMultipleData(url);

  if (response.success) {
    return {
      totalUsers: response.data.Response.data.totalUsers,
      totalUsersThisMonth: response.data.Response.data.totalUsersThisMonth
    };
  } else {
    console.error(response.message);
    return null;
  }
}

async function getUsersSubscriptionsAnalyticsAsync() {
  const url = `/UsersSubscriptions/GetUsersSubscriptionsAnalytics`;

  const response = await fetchMultipleData(url);

  if (response.success) {
    return {
      totalSubscriptions: response.data.Response.data.totalSubscriptions,
      totalSubscriptionsThisMonth: response.data.Response.data.totalSubscriptionsThisMonth
    };
  } else {
    console.error(response.message);
    return null;
  }
}

async function getUsersSessionsAnalyticsAsync() {
  const url = `/Sessions/GetUsersSessionsAnalytics`;

  const response = await fetchMultipleData(url);

  if (response.success) {
    return {
      activeSessions: response.data.Response.data.activeSessions,
      inActiveSessions: response.data.Response.data.inActiveSessions,
      totalSessions: response.data.Response.data.totalSessions
    };
  } else {
    console.error(response.message);
    return null;
  }
}

async function getTransactionsAnalyticsAsync() {
  const url = `/SystemTransactions/GetTransactionsAnalytics`;

  const response = await fetchMultipleData(url);

  if (response.success) {
    return {
      additions: response.data.Response.data.additions,
      deletions: response.data.Response.data.deletions,
      updates: response.data.Response.data.updates
    };
  } else {
    console.error(response.message);
    return null;
  }
}

async function fetchDataAsync(usersState, subscriptionsState, sessionsState, transactionsState, loaderState) {
  try {
    loaderState(true);
    usersState(await getUsersAnalyticsAsync());
    subscriptionsState(await getUsersSubscriptionsAnalyticsAsync());
    sessionsState(await getUsersSessionsAnalyticsAsync());
    transactionsState(await getTransactionsAnalyticsAsync());
    return true;
  } 
  catch (error) {
    console.error('An error occurred while fetching the data:', error);
    usersState(null);
    subscriptionsState(null);
    sessionsState(null);
    transactionsState(null);
    return false;
  }
  finally {
    loaderState(false);
  }
}

async function checkUsersSubscriptionsAsync(currentAdminPermissions, loaderState, messageState) {
  try {
    if ((currentAdminPermissions & 8192) !== 8192) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);
    const url = `/UsersSubscriptions/CheckAllUsersSubscriptions`;
    const response = await fetchScalarData(url);

    if (response?.data?.Response?.data?.isSuccessful === true) {
      messageState({
        title: 'Success',
        message: `The users' subscriptions have been successfully checked and updated.`,
        isVisible: true,
      });
      return;
    }
    else {
      messageState({
        title: 'Info',
        message: `Failed to check and update the users' subscriptions. Please try again later.`,
        isVisible: true,
      });
      return;
    }
  } 
  catch (error) {
    messageState({
      title: 'Error',
      message: `An error occurred while checking users subscriptions.`,
      isVisible: true,
    });
    console.error('An error occurred while checking users subscriptions:', error);
  } 
  finally {
    loaderState(false);
  }
}

export {
  fetchDataAsync,
  checkUsersSubscriptionsAsync
};