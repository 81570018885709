// base
import React, { useContext } from 'react';
import './usersSubscriptionsBody.css';
import { Link } from 'react-router-dom';

// context
import { usersSubscriptionsContext } from '../../../pages/users_subscriptions/usersSubscriptionsContext';

// logic
import { getDate } from '../../../global/utils/functions/utils';

export default function UsersSubscriptionsBody() {
  const {
    subscriptions
  } = useContext(usersSubscriptionsContext)

  return (
    <div className='users-subscriptions-body-component'>

      <div className='users-subscriptions-table-container'>
      <table className="users-subscriptions-table">
        <thead>
            <tr>
              <th className='first-column'>#</th>
              <th>Username</th>
              <th>Plan</th>
              <th>Branch</th>
              <th>Price</th>
              <th>Discount</th>
              <th>Final Price</th>
              <th>Start</th>
              <th>End</th>
              <th className='last-column'>Status</th>
            </tr>
        </thead>

        <tbody>
          {subscriptions && subscriptions.length > 0 ?
            (
              subscriptions.map((subscription, index) => {
                return (
                  <tr key={subscription.subscriptionId}>
                    <td>{index + 1}</td>

                    <td className="user-name">
                      <Link to={`${subscription.userId ? `/users/${subscription.userId}` : '/login'}`}>
                        {subscription.userName}
                      </Link>
                    </td>

                    <td>{subscription.planName}</td>
                    <td>{subscription.branchName}</td>
                    <td>{subscription.price} $</td>
                    <td>{subscription.discount}%</td>
                    <td>{subscription.finalPrice} $</td>
                    <td>{getDate(subscription.subscriptionStart)}</td>
                    <td>{getDate(subscription.subscriptionEnd)}</td>
                    <td>
                      <span className={`status ${subscription.isActive ? 'active' : 'in-active'}`}>
                        {subscription.isActive ? "Active" : "In-Active"}
                      </span>
                    </td>
                </tr>
                );
                })
            )
            :
            (
              <tr>
                <td className='no-users-subscriptions-available' colSpan="10">No users subscriptions available.</td>
              </tr>
            )}
        </tbody>

      </table>
      </div>

    </div>
  )
}
