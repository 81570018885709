// base
import React, { useState, useEffect, useContext } from 'react';
import './ratesAddUpdateComponent.css';
import { useSelector } from 'react-redux';

// context
import { acceptanceRatesContext } from '../../../pages/acceptance_rates/acceptanceRatesContext';

// components
import MessageLabel from '../../message_label/MessageLabel';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import TextButton from '../../inputs/buttons/text_button/TextButton';
import TextInput from '../../inputs/text_input/TextInput';
import Selector from '../../inputs/selector/Selector';

// logic
import {
  AcceptanceRatesModeEnum,
  onChangeHandler,
  validateCollege,
  validateTotal,
  validateAverage,
  newRateAsync,
  updateRateAsync
} from '../../../pages/acceptance_rates/acceptanceRatesLogic';

export default function RatesAddUpdateComponent() {
  const {
    branches,
    years,
    pageMode,
    setPageMode,
    setModelMessage,
    reload,
    setReload,
    rateObjForUpdate
  } = useContext(acceptanceRatesContext);

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [newData, setNewData] = useState({
    rateId: 0,
    college: '',
    total: 0,
    average: 0,
    gender: '',
    description: '',
    branchId: 0,
    academicYearId: 0,
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: 'Please fill in the required fields to add a new acceptance rate. All fields marked with * are mandatory.',
    type: 'info',
    isVisible: true
  });

  const genderOptions = [
    {value: '1', label: 'ذكر'},
    {value: '2', label: 'انثئ'},
    {value: '3', label: 'غير محدد'}
  ]

  const branchesOptions = branches.map(branch => ({
    value: String(branch.branchId),
    label: branch.branchName
  }));

  const yearsOptions = years.map(year => ({
    value: String(year.yearId),
    label: year.academicYear
  }));

  const [selectedGender, setSelectedGender] = useState({value: '', label: ''});
  const [selectedBranch, setSelectedBranch] = useState({value: '', label: ''});
  const [selectedYear, setSelectedYear] = useState({value: '', label: ''});

  useEffect(() => {

    if (pageMode === AcceptanceRatesModeEnum.ADD) {
      setNewData({
        rateId: 0,
        college: '',
        total: 0,
        average: 0,
        gender: genderOptions[0].label,
        description: '',
        branchId: branchesOptions[0]?.value,
        academicYearId: yearsOptions[0]?.value,
      })
      setSelectedGender(genderOptions[0]);
      setSelectedBranch(branchesOptions[0]);
      setSelectedYear(yearsOptions[0]);

      setMessage({
        message: 'Please fill in the required fields to add a new acceptance rate. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      });
    }

    if (pageMode === AcceptanceRatesModeEnum.UPDATE) {
      setNewData({
        rateId: rateObjForUpdate.rateId,
        college: rateObjForUpdate.college,
        total: rateObjForUpdate.total,
        average: rateObjForUpdate.average,
        gender: genderOptions.find(option => option.label === rateObjForUpdate.gender).label || genderOptions[0].label,
        description: rateObjForUpdate.description,
        branchId: branchesOptions.find(option => option.label === rateObjForUpdate.branchName).value || branchesOptions[0]?.value,
        academicYearId: yearsOptions.find(option => option.label === rateObjForUpdate.academicYear).value || yearsOptions[0]?.value
      })
      setSelectedGender(genderOptions.find(option => option.label === rateObjForUpdate.gender) || genderOptions[0]);
      setSelectedBranch(branchesOptions.find(option => option.label === rateObjForUpdate.branchName) || branchesOptions[0]);
      setSelectedYear(yearsOptions.find(option => option.label === rateObjForUpdate.academicYear) || yearsOptions[0]);
      
      setMessage({
        message: 'Please fill in the required fields to update acceptance rate. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      })
    }

  }, [pageMode]);

  const handleNewRate = async() => {
    if (await newRateAsync(newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setNewData({
        rateId: 0,
        college: '',
        total: 0,
        average: 0,
        gender: genderOptions[0].label,
        description: '',
        branchId: branchesOptions[0]?.value,
        academicYearId: yearsOptions[0]?.value,
      });
      setSelectedGender(genderOptions[0]);
      setSelectedBranch(branchesOptions[0]);
      setSelectedYear(yearsOptions[0]);
      setReload(!reload);
    }
  }

  const handleUpdateRate = async() => {
    if (await updateRateAsync(newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setReload(!reload);
      setPageMode(AcceptanceRatesModeEnum.READ);
      setModelMessage({
          title: 'success', 
          message: 'Acceptance rate updated successfully.', 
          isVisible: true 
        });
    }
  }

  const handleAddUpdateClick = async () => {

    if ((currentAdminPermissions & 33554432) !== 33554432){
      setMessage({
        message: 'You do not have sufficient permissions to perform this action.',
        type: 'info',
        isVisible: true,
      });
      return;
    }

    try {

      if (pageMode === AcceptanceRatesModeEnum.ADD) {
        await handleNewRate();
      }
      else if (pageMode === AcceptanceRatesModeEnum.UPDATE) {
        await handleUpdateRate();
      }

    } catch {
      setMessage({
        message: 'An error occurred while processing your request. Please try again later.',
        type: 'error',
        isVisible: true
      });
    }
  }

  const handleCancelClick = () => {
    setNewData({
      rateId: 0,
      college: '',
      total: 0,
      average: 0,
      gender: '',
      description: '',
      branchId: 0,
      academicYearId: 0,
    })
    setPageMode(AcceptanceRatesModeEnum.READ);
  }

  const handleGenderOption = (option) => {
    setSelectedGender(option);
    onChangeHandler('gender', option.label, setNewData);
  }

  const handleBranchOption = (option) => {
    setSelectedBranch(option);
    onChangeHandler('branchId', option.value, setNewData);
  }

  const handleYearOption = (option) => {
    setSelectedYear(option);
    onChangeHandler('academicYearId', option.value, setNewData);
  }
  
  return (
    <div className='rate-add-update-component'>
      <div className='rate-add-update-content'>

        <div className='new-rate-header'>
          <h3>{pageMode === AcceptanceRatesModeEnum.ADD ? 'Add New Acceptance Rate' : 'Update Acceptance Rate'}</h3>
          <div className={`save-loader ${loading ? 'active' : ''}`}></div>
        </div>

        <div className='new-rate-message'>
          <MessageLabel message={message.message} type={message.type} isVisible={message.isVisible} />
        </div>

        <div className={`new-rate-body ${loading ? 'loading' : ''}`}>
            <div className='item-container'>
              <TextInput
                title={'College'} 
                value={newData.college}
                onChangeHandler={(newValue) => {onChangeHandler('college', newValue, setNewData)}} 
                validateInput={validateCollege}
                mandatory={true}
                tooltipTitle={'Please enter a valid college name with a maximum length of 250 characters.'}
                onEnter={async() => {handleAddUpdateClick()}}
                dir='rtl'
              />
            </div>

            <div className='item-container'>
              <TextInput
                title={'Total Marks (positive number only)'}
                value={newData.total}
                onChangeHandler={(newValue) => {onChangeHandler('total', newValue, setNewData)}} 
                validateInput={validateTotal}
                mandatory={true}
                tooltipTitle={'Please enter a valid positive total marks.'}
                onEnter={async() => {handleAddUpdateClick()}}
                dir='rtl'
              />
            </div>

            <div className='item-container'>
              <TextInput
                title={'Average (1 - 100, positive numbers only)'} 
                value={newData.average}
                onChangeHandler={(newValue) => {onChangeHandler('average', newValue, setNewData)}} 
                validateInput={validateAverage}
                mandatory={true}
                tooltipTitle={'Please enter a number between 1 and 100 for the average.'}
                onEnter={async() => {handleAddUpdateClick()}}
                dir='rtl'
              />
            </div>

            <div className='item-container'>
              <textarea 
                className='description-input' 
                placeholder='Description (Max 150 characters)'
                maxLength={150} 
                rows="4" 
                value={newData.description} 
                onChange={(e) => {onChangeHandler('description', e.target.value, setNewData)}}
              />
            </div>

            <div className={`item-container`}>
              <p className='label'>Gender <span>*</span></p>
              <Selector className={'label'} dir={'rtl'} title={''} options={genderOptions} value={selectedGender.value} onSelect={handleGenderOption}/>
            </div>

            <div className={`item-container`}>
              <p className='label'>Branch <span>*</span></p>
              <Selector className={'label'} dir={'rtl'} title={''} options={branchesOptions} value={selectedBranch.value} onSelect={handleBranchOption}/>
            </div>

            <div className={`item-container`}>
              <p className='label'>academic Year <span>*</span></p>
              <Selector className={'label'} dir={'rtl'} title={''} options={yearsOptions} value={selectedYear.value} onSelect={handleYearOption}/>
            </div>

        </div>

        <div className={`new-rate-footer ${loading ? 'loading' : ''}`}>
          <div className={`new-rate-add-button-container ${(currentAdminPermissions & 33554432) !== 33554432 ? 'disabled' : ''}`}>
            <ContainedButton title={pageMode === AcceptanceRatesModeEnum.ADD ? 'Add' : 'Update'} onclickHandler={async() => {handleAddUpdateClick()}} />
          </div>

          <div className='new-rate-cancel-button-container'>
            <TextButton title={'Cancel'} onclickHandler={() => {handleCancelClick()}} />
          </div>
        </div>

      </div>
    </div>
  )
}
