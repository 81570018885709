import axios from 'axios';
import config from '../../global/config';

import {
  fetchScalarData,
  fetchMultipleData,
  deleteData
} from '../../global/utils/functions/apiService';

import {
  checkLength,
} from '../../global/utils/validation';

const MaterialsModeEnum = Object.freeze({
  READ: 'read',
  ADD: 'add',
  UPDATE: 'update'
});

const MessageBoxActionsEnum = Object.freeze({
  NONE: 'none',
  DELETE: 'delete'
})

function onChangeHandler(field, newValue, state) {
  state(prevState => ({
    ...prevState,
    [field]: newValue
  }));
}

function validateMaterialName (input) {
  return checkLength(1, 100, input);
}

function validateMaterialForAddNew (material) {
  if (!validateMaterialName(material.materialName))
    return false;

  if (!material.branchId || material.branchId <= 0)
    return false;

  return true;
}

function validateMaterialForUpdate (material) {
  if (!material.materialId || material.materialId <= 0)
    return false;

  if (!validateMaterialName(material.materialName))
    return false;

  return true;
}

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

async function getBranches() {
  const url = '/Branches/GetAllBranches';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const branches = response?.data?.Response?.data?.branches || [];

    return branches;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getAllMaterials() {
  const url = '/Materials/GetAllMaterials';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const materials = response?.data?.Response?.data?.materials || [];

    return materials;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getMaterialsByBranch(branchId) {
  const url = `/Materials/GetMaterialsByBranch/${branchId}`;

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const materials = response?.data?.Response?.data?.materials || [];

    return materials;
  } else {
    console.log(response.message);
    return [];
  }
}

async function isMaterialHasRelationsAsync(materialId) {
  const url = `/Materials/IsMaterialHasRelations/${materialId}`;

  try {
    const response = await fetchScalarData(url);

    if (response.data.Response.data.hasRelations === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('An error occurred while fetching the data:', error);
    return false;
  }
}

async function deleteMaterialAsync(materialId, byAdmin, currentAdminPermissions, messageState, loaderState, reload, setReload, navigate) {
  try {
    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((currentAdminPermissions & 2097152) !== 2097152) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);
    
    const hasRelations = await isMaterialHasRelationsAsync(materialId);

    if (hasRelations === true) {
      messageState({
        title: 'Action Required',
        message: 'This material is linked to other records. Please remove the dependencies before attempting to delete it.',
        isVisible: true,
      });
      
      return;
    }

    const response = await deleteData(`/Materials/DeleteMaterial/${materialId}/${byAdmin}`);
    
    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'The material has been deleted successfully.',
        isVisible: true
      });
      setReload(!reload);
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to delete material. Please check your permissions and try again.',
        isVisible: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deleting the material. Please try again later.',
      isVisible: true
    });
  } finally {
    loaderState(false);
  }
}

async function newMaterialAsync(material, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (validateMaterialForAddNew(material) === false) {
      setMessage({
        message: 'Invalid material data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    if ((currentAdminPermissions & 2097152) !== 2097152) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);

    const url = `${config.apiBaseUrl}/Materials/NewMaterial`;

    const response = await axios.post(url, {
      materialName: material.materialName,
      branchId: material.branchId,
      byAdmin: byAdmin
    });

    if (response.status === 201) {
      setMessage({
        message: 'Material inserted successfully.',
        type: 'success',
        isVisible: true
      });
      return true;
    } else {
      setMessage({
        message: 'Failed to insert new material.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.response?.data?.title}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
};

async function updateMaterialAsync(material, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (validateMaterialForUpdate(material) === false) {
      setMessage({
        message: 'Invalid material data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    if ((currentAdminPermissions & 2097152) !== 2097152) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }
    loaderState(true);

    const url = `${config.apiBaseUrl}/Materials/UpdateMaterial`;

    const response = await axios.put(url, {
      materialId: material.materialId,
      materialName: material.materialName,
      byAdmin: byAdmin
    });

    if (response.status === 200) {
      return true;
    } else {
      setMessage({
        message: 'Failed to update material.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.response?.data?.message}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
};

export {
  MaterialsModeEnum,
  MessageBoxActionsEnum,
  onChangeHandler,
  validateMaterialName,
  resetMessage,
  getBranches,
  getAllMaterials,
  getMaterialsByBranch,
  deleteMaterialAsync,
  newMaterialAsync,
  updateMaterialAsync
}