import React, { createContext, useState } from 'react';
import { BranchesModeEnum, MessageBoxActionsEnum } from './branchesLogic';

export const branchesContext = createContext();

export const BranchesProvider = ({ children }) => {

  const [branches, setBranches] = useState([]);
  const [pageMode, setPageMode] = useState(BranchesModeEnum.READ);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);
  const [branchIdForDelete, setBranchIdForDelete] = useState(0);
  const [branchObjForUpdate, setBranchObjForUpdate] = useState(null);
  const [actionType, setActionType] = useState(MessageBoxActionsEnum.NONE);

  return (
  <branchesContext.Provider
    value={{
      branches,
      setBranches,
      pageMode, 
      setPageMode,
      loading,
      setLoading,
      modelMessage,
      setModelMessage,
      messageBox,
      setMessageBox,
      reload,
      setReload,
      branchIdForDelete,
      setBranchIdForDelete,
      branchObjForUpdate,
      setBranchObjForUpdate,
      actionType, 
      setActionType
    }}
  >
      {children}
  </branchesContext.Provider>
  );
};
