// import axios from 'axios';
// import config from '../../global/config';

import {
  fetchScalarData,
  fetchMultipleData,
} from '../../global/utils/functions/apiService';

const UsersSubscriptionsMode = Object.freeze({
  ACTIVES: 'actives',
  INACTIVES: 'in-actives'
})

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

async function getActiveUsersSubscriptionsAsync(pageNumber) {
  const url = `/UsersSubscriptions/GetActivesUsersSubscriptions`;

  const response = await fetchMultipleData(url, pageNumber);
  console.log('data: ', response?.data?.Response?.data?.subscriptions[0])
  if (response.success) {
    const subscriptions = response?.data?.Response?.data?.subscriptions || [];
    return subscriptions;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getActivesPagesCount() {
  const result = await fetchScalarData('/UsersSubscriptions/GetUsersSubscriptionsPagesCountActive');
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching actives users subscriptions pages count:', result.message);
    return 0;
  }
}

async function getActivesSubscriptionsCount() {
  const result = await fetchScalarData('/UsersSubscriptions/GetActiveUsersSubscriptionsCount');
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching in-actives users subscriptions pages count:', result.message);
    return 0;
  }
}

async function getInActiveUsersSubscriptionsAsync(pageNumber) {
  const url = `/UsersSubscriptions/GetInActivesUsersSubscriptions`;

  const response = await fetchMultipleData(url, pageNumber);

  if (response.success) {
    const subscriptions = response?.data?.Response?.data?.subscriptions || [];
    return subscriptions;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getInActivesPagesCount() {
  const result = await fetchScalarData('/UsersSubscriptions/GetUsersSubscriptionsPagesCountInActive');
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching in-actives users subscriptions pages count:', result.message);
    return 0;
  }
}

async function getInActivesSubscriptionsCount() {
  const result = await fetchScalarData('/UsersSubscriptions/GetInActiveUsersSubscriptionsCount');
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching in-actives users subscriptions pages count:', result.message);
    return 0;
  }
}

export {
  UsersSubscriptionsMode,
  resetMessage,
  getActiveUsersSubscriptionsAsync,
  getActivesPagesCount,
  getActivesSubscriptionsCount,
  getInActiveUsersSubscriptionsAsync,
  getInActivesPagesCount,
  getInActivesSubscriptionsCount
}