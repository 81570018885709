// base
import React, { useState, useEffect, useContext } from 'react';
import './yearsAddUpdateComponent.css';
import { useSelector } from 'react-redux';

// context
import { academicYearsContext } from '../../../pages/academic_years/academicYearsContext';

// components
import MessageLabel from '../../message_label/MessageLabel';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import TextButton from '../../inputs/buttons/text_button/TextButton';
import TextInput from '../../inputs/text_input/TextInput';

// logic
import {
  AcademicYearsModeEnum,
  onChangeHandler,
  validateYearName,
  newAcademicYearAsync,
  updateAcademicYearAsync
} from '../../../pages/academic_years/academicYearsLogic';


export default function YearsAddUpdateComponent() {
  const {
    pageMode,
    setPageMode,
    setModelMessage,
    reload,
    setReload,
    yearObjForUpdate
  } = useContext(academicYearsContext);

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [newData, setNewData] = useState({
    yearId: 0,
    academicYear: ''
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: 'Please fill in the required fields to add a new academic year. All fields marked with * are mandatory.',
    type: 'info',
    isVisible: true
  });

  useEffect(() => {

    if (pageMode === AcademicYearsModeEnum.ADD) {
      setNewData({
        yearId: 0,
        academicYear: ''
      })

      setMessage({
        message: 'Please fill in the required fields to add a new academic year. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      });
    }

    if (pageMode === AcademicYearsModeEnum.UPDATE) {
      setNewData({
        yearId: yearObjForUpdate.yearId,
        academicYear: yearObjForUpdate.academicYear
      })

      setMessage({
        message: 'Please fill in the required fields to update academic year. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      })
    }

  }, [pageMode]);

  const handleNewYear = async() => {
    if (await newAcademicYearAsync(newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setNewData({
        yearId: 0,
        academicYear: ''
      });
      setReload(!reload);
    }
  }

  const handleUpdateYear = async() => {
    if (await updateAcademicYearAsync(newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setReload(!reload);
      setPageMode(AcademicYearsModeEnum.READ);
      setModelMessage({
          title: 'success', 
          message: 'Academic year updated successfully.', 
          isVisible: true 
        });
    }
  }

  const handleAddUpdateClick = async () => {

    if ((currentAdminPermissions & 131072) !== 131072){
      setMessage({
        message: 'You do not have sufficient permissions to perform this action.',
        type: 'info',
        isVisible: true,
      });
      return;
    }

    try {

      if (pageMode === AcademicYearsModeEnum.ADD) {
        await handleNewYear();
      }
      else if (pageMode === AcademicYearsModeEnum.UPDATE) {
        await handleUpdateYear();
      }

    } catch {
      setMessage({
        message: 'An error occurred while processing your request. Please try again later.',
        type: 'error',
        isVisible: true
      });
    }
  }

  const handleCancelClick = () => {
    setPageMode(AcademicYearsModeEnum.READ);
  }

  return (
    <div className='year-add-update-component'>
      <div className='year-add-update-content'>

        <div className='new-year-header'>
          <h3>{pageMode === AcademicYearsModeEnum.ADD ? 'Add Academic Year' : 'Update Academic Year'}</h3>
          <div className={`save-loader ${loading ? 'active' : ''}`}></div>
        </div>

        <div className='new-year-message'>
          <MessageLabel message={message.message} type={message.type} isVisible={message.isVisible} />
        </div>

        <div className={`new-year-body ${loading ? 'loading' : ''}`}>
            <div className='item-container'>
              <TextInput
                title={'Academic Year'} 
                value={newData.academicYear}
                onChangeHandler={(newValue) => {onChangeHandler('academicYear', newValue, setNewData)}} 
                validateInput={validateYearName}
                mandatory={true}
                tooltipTitle={'Please enter a valid academic year with a maximum length of 25 characters.'}
                onEnter={async() => {handleAddUpdateClick()}}
                dir='rtl'
              />
            </div>
        </div>

        <div className={`new-year-footer ${loading ? 'loading' : ''}`}>
          <div className={`new-year-add-button-container ${(currentAdminPermissions & 131072) !== 131072 ? 'disabled' : ''}`}>
            <ContainedButton title={pageMode === AcademicYearsModeEnum.ADD ? 'Add' : 'Update'} onclickHandler={async() => {handleAddUpdateClick()}} />
          </div>

          <div className='new-year-cancel-button-container'>
            <TextButton title={'Cancel'} onclickHandler={() => {handleCancelClick()}} />
          </div>
        </div>

      </div>
    </div>
  )
}
