import axios from 'axios';
import config from '../../global/config';

import {
  fetchScalarData,
  fetchMultipleData,
  patchData,
  deleteData,
  patchDataWithHeader
} from '../../global/utils/functions/apiService';

import {
  checkLength,
} from '../../global/utils/validation';

import {
  uploadFileToFirebase,
  deleteFileFromFirebase
} from '../../global/utils/firebase/firebaseActions'

const CoursesModeEnum = Object.freeze({
  READ: 'read',
  ADD: 'add',
  UPDATE: 'update'
});

const MessageBoxActionsEnum = Object.freeze({
  NONE: 'none',
  ACTIVATE: 'activate',
  DEACTIVATE: 'deactivate',
  DELETE_COVER: 'delete-cover',
  DELETE: 'delete'
})

function onChangeHandler(field, newValue, state) {
  state(prevState => ({
    ...prevState,
    [field]: newValue
  }));
}

function validateCourseTitle (input) {
  return checkLength(1, 250, input);
}

function validateDescription (input) {
  if (!input || input.length === 0)
    return true;

  return checkLength(1, 250, input);
}

function validateTeacherName (input) {
  return checkLength(1, 100, input);
}

function validateCoverURL (input) {
  return input.length > 0;
}

function validateCoverName (input) {
  return checkLength(1, 150, input);
}

function validateCourseForAddNew (course) {
  if (!validateCourseTitle(course.courseTitle))
    return false;

  if (!validateDescription(course.description))
    return false;

  if (!validateTeacherName(course.teacherName))
    return false;

  if (!course.branchId || course.branchId <= 0)
    return false;

  return true;
}

function validateCourseForUpdate (course) {
  if (!course.courseId || course.courseId <= 0)
    return false;

  if (!validateCourseTitle(course.courseTitle))
    return false;

  if (!validateDescription(course.description))
    return false;

  if (!validateTeacherName(course.teacherName))
    return false;

  if (!course.branchId || course.branchId <= 0)
    return false;

  return true;
}

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

function validateFile(file) {
  if (!file)
    return false;

  const allowedTypes = ['image/jpeg', 'image/png', 'image/webp'];

  if (!allowedTypes.includes(file.type)) {
    return false;
  }

  return true;
}

function generateUniqueFileName(file) {
  if (!file || !file.name) {
    return null;
  }

  const fileExtension = file.name.split('.').pop().toLowerCase();
  const fileName = `${Date.now()}.${fileExtension}`;
  return fileName;
}

async function uploadCourseCover(file, fullPath) {
  const fileURL = await uploadFileToFirebase(file, fullPath);
  return fileURL ? fileURL : null;
}

async function getCoursesCount() {
  const result = await fetchScalarData('/Courses/GetCoursesCount');
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching courses count:', result.message);
    return 0;
  }
}

async function getBranches() {
  const url = '/Branches/GetAllBranches';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const branches = response?.data?.Response?.data?.branches || [];

    return branches;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getCoursesByBranchAsync(branchId) {
  const url = `/Courses/GetAllCoursesByBranch/${branchId}`;

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const courses = response?.data?.Response?.data?.courses || [];

    return courses;
  } else {
    console.log(response.message);
    return [];
  }
}

async function activateCourse(courseId, currentAdminId, currentAdminPermissions, messageState, loaderState, reload, setReload) {
  try {

    if ((currentAdminPermissions & 536870912) !== 536870912) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return false;
    }

    loaderState(true);
    const response = await patchData(`/Courses/SetCourseAsActive/${courseId}/${currentAdminId}`);

    if (response.message === 'Success.') {
      messageState({
        title: 'Success',
        message: 'The course has been activated successfully.',
        isVisible: true
      });
      setReload(!reload);
      return true;
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to activate this course. Please try again.',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while activating this course. Please try again later.',
      status: true
    });
    return false;
  } finally {
    loaderState(false);
  }
}

async function deActivateCourse(courseId, currentAdminId, currentAdminPermissions, messageState, loaderState, reload, setReload) {
  try {

    if ((currentAdminPermissions & 536870912) !== 536870912) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return false;
    }

    loaderState(true);
    const response = await patchData(`/Courses/SetCourseAsInActive/${courseId}/${currentAdminId}`);

    if (response.message === 'Success.') {
      messageState({
        title: 'Success',
        message: 'The course has been deactivated successfully.',
        isVisible: true
      });
      setReload(!reload);
      return true;
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to deactivate this course. Please try again.',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deactivating this course. Please try again later.',
      status: true
    });
    return false;
  } finally {
    loaderState(false);
  }
}

async function setCourseCoverAsync(event, courseObj, byAdmin, currentAdminPermissions, reload, setReload , setMessage, loaderState) {
  try {
    if (!validateFile(event?.target?.files[0])) {
      setMessage({
        message: 'Invalid image format. Please upload an image of type JPEG, PNG, or WebP.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    if ((currentAdminPermissions & 1073741824) !== 1073741824) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    loaderState(true);

    const file = event.target.files?.[0];
    if (!file) {
      setMessage({
        message: 'No image selected. Please select an image to upload.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    const uniqueImageName = generateUniqueFileName(file);
    if (!uniqueImageName) {
      setMessage({
        message: 'Failed to generate a unique image name. Please try again.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    const imageURL = await uploadCourseCover(file, `courses/courses-covers/${uniqueImageName}`);
    if (!imageURL) {
      setMessage({
        message: 'An error occurred while uploading the image. Please try again later.',
        type: 'error',
        isVisible: true,
      });
      return false;
    } else {
      if (!validateCoverURL(imageURL) || !validateCoverName(uniqueImageName)) {
        await deleteFileFromFirebase(`courses/courses-covers/${uniqueImageName}`);
        return false;
      }
    }

    const url = `/Courses/SetCourseCover`;
    const headers = {
      'courseId': courseObj.courseId,
      'imageURL': imageURL,
      'imageName': uniqueImageName,
      'byAdmin': byAdmin,
    };

    const response = await patchDataWithHeader(url, { headers });

    if (response.data.Response.data.response) {
      if (courseObj.coverName)
        await deleteFileFromFirebase(`courses/courses-covers/${courseObj.coverName}`);
      setMessage({
        message: 'The course cover has been successfully set.',
        type: 'success',
        isVisible: true,
      });
      setReload(!reload);
      return true;
    } else {
      setMessage({
        message: 'Failed to set the course cover.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: error.message || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true,
    });
    return false;
  } finally {
    loaderState(false);
  }
}

async function deleteCourseCoverAsync(courseObj, byAdmin, currentAdminPermissions, reload, setReload, setMessage, loaderState) {
  try {

    if ((currentAdminPermissions & 1073741824) !== 1073741824) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);

    if (courseObj.coverName) {
      await deleteFileFromFirebase(`courses/courses-covers/${courseObj.coverName}`);
    }

    const response = await patchData(`/Courses/DeleteCourseCover/${courseObj.courseId}/${byAdmin}`);

    if (response.data.Response?.data?.response) {
      setMessage({
        title: 'Success',
        message: 'The course cover has been deleted successfully.',
        isVisible: true
      });
      setReload(!reload);
    } else {
      setMessage({
        title: 'Error',
        message: response.message || 'Failed to delete the course cover. Please check your permissions and try again.',
        isVisible: true
      });
    }
  } catch (error) {
    setMessage({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deleting the course cover. Please try again later.',
      isVisible: true
    });
  } finally {
    loaderState(false);
  }
}

async function isCourseHasRelationsAsync(courseId) {
  const url = `/Courses/IsCourseHasRelations/${courseId}`;

  try {
    const response = await fetchScalarData(url);

    if (response.data.Response.data.hasRelations === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('An error occurred while fetching the data:', error);
    return false;
  }
}

async function deleteCourseAsync(courseObj, byAdmin, currentAdminPermissions, messageState, loaderState, reload, setReload, navigate) {
  try {
    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((BigInt(currentAdminPermissions) & BigInt(2147483648)) !== BigInt(2147483648)) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);

    if (await isCourseHasRelationsAsync(courseObj.courseId)) {
      messageState({
        title: 'Action Required',
        message: 'This course is linked to other records. Please remove the dependencies before attempting to delete it.',
        isVisible: true,
      });
      return;
    }

    if (courseObj.coverName) {
      await deleteFileFromFirebase(`courses/courses-covers/${courseObj.coverName}`);
    }
    
    const response = await deleteData(`/Courses/DeleteCourse/${courseObj.courseId}/${byAdmin}`);
    
    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'The course has been deleted successfully.',
        isVisible: true
      });
      setReload(!reload);
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to delete the course. Please check your permissions and try again.',
        isVisible: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deleting the course. Please try again later.',
      isVisible: true
    });
  } finally {
    loaderState(false);
  }
}

async function newCourseAsync(courseObj, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (!validateCourseForAddNew(courseObj)) {
      setMessage({
        message: 'Invalid course data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    if ((currentAdminPermissions & 1073741824) !== 1073741824) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    loaderState(true);
    const url = `${config.apiBaseUrl}/Courses/NewCourse`;
    const response = await axios.post(url, {
      courseTitle: courseObj.courseTitle,
      description: courseObj.description || '',
      teacherName: courseObj.teacherName,
      branchId: parseInt(courseObj.branchId, 10),
      byAdmin: byAdmin,
    });

    if (response.status === 201) {
      setMessage({
        message: 'Course inserted successfully.',
        type: 'success',
        isVisible: true,
      });
      return true;
    } else {
      setMessage({
        message: 'Failed to insert new course.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: error.message || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true,
    });
    return false;
  } finally {
    loaderState(false);
  }
}

async function updateCourseAsync(courseObj, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (!validateCourseForUpdate(courseObj)) {
      setMessage({
        message: 'Invalid course data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    if ((currentAdminPermissions & 1073741824) !== 1073741824) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return false;
    }
    loaderState(true);

    const url = `${config.apiBaseUrl}/Courses/UpdateCourse`;

    const response = await axios.put(url, {
      courseId: courseObj.courseId,
      courseTitle: courseObj.courseTitle,
      description: courseObj.description || '',
      teacherName: courseObj.teacherName,
      branchId: parseInt(courseObj.branchId, 10),
      byAdmin: byAdmin,
    });

    if (response.status === 200) {
      return true;
    } else {
      setMessage({
        message: 'Failed to update this course.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.message}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
};


export {
  CoursesModeEnum,
  MessageBoxActionsEnum,
  onChangeHandler,
  validateCourseTitle,
  validateDescription,
  validateTeacherName,
  resetMessage,
  validateFile,
  getCoursesCount,
  getBranches,
  getCoursesByBranchAsync,
  activateCourse,
  deActivateCourse,
  setCourseCoverAsync,
  deleteCourseCoverAsync,
  deleteCourseAsync,
  newCourseAsync,
  updateCourseAsync
}