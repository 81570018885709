// base
import React, { useContext, useEffect } from 'react'
import './ratesFilesHeader.css';
import { useSelector } from 'react-redux';

// context
import { acceptanceRatesFilesContext } from '../../../pages/acceptance_rates_files/acceptanceRatesFilesContext';

// icons
import { BsFileEarmarkPdfFill } from "react-icons/bs";

// components 
import AdditionCard from '../../common_components/addition_card/AdditionCard';
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// logic
import {
  AcceptanceRatesModeEnum,
  getAllRatesFiles,
  getYears
} from '../../../pages/acceptance_rates_files/acceptanceRatesFilesLogic';

export default function RatesFilesHeader() {
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const {
    rates,
    setRates,
    setYears,
    setPageMode,
    setLoading,
    reload
  } = useContext(acceptanceRatesFilesContext);

  useEffect(() => {
    fetchRatesFilesAndYears();
  }, [reload])

  async function fetchRatesFilesAndYears(){
    try {
      setLoading(true);
      setRates(await getAllRatesFiles());
      setYears(await getYears());
    } catch {
      console.error('An error occurred while fetching data:');
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className='rates-files-header-component'>

      <div className={`item-container ${(currentAdminPermissions & 134217728) !== 134217728 ? 'disabled' : ''}`} onClick={() => {setPageMode(AcceptanceRatesModeEnum.ADD)}}>
        <AdditionCard />
      </div>

      <div className="item-container">
        <AnalyticalCard
          title={'All Acceptance Rate Files'}
          icon={BsFileEarmarkPdfFill}
          value={rates?.length || '-'}
          isActive={true}
          onClick={() => {}}
        />
      </div>

    </div>
  )
}
