// base
import React from 'react'

// context
import { TypicalQuestionsTypesProvider } from './typicalQuestionsTypesContext';

// components
import TypicalQuestionsTypesMain from '../../components/typical_questions_types_components/typical_questions_types_main/TypicalQuestionsTypesMain';

export default function AcademicYears() {
  return (
    <TypicalQuestionsTypesProvider>
      <TypicalQuestionsTypesMain />
    </TypicalQuestionsTypesProvider>
  )
}
