// base
import React, { useContext, useEffect, useState, useRef } from 'react'
import './materialsHeader.css';
import { useSelector } from 'react-redux';

// context
import { materialsContext } from '../../../pages/materials/materialsContext';

// icons
import { FaBookOpen } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

// components 
import AdditionCard from '../../common_components/addition_card/AdditionCard';
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// logic
import {
  MaterialsModeEnum,
  getBranches,
  getAllMaterials,
  getMaterialsByBranch
} from '../../../pages/materials/materialsLogic';

export default function MaterialsHeader() {
  const containerRef = useRef(null);

  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);
  const [activeCardId, setActiveCardId] = useState(0);

  const {
    materials,
    setMaterials,
    branches,
    setBranches,
    setPageMode,
    setLoading,
    reload
  } = useContext(materialsContext);

  useEffect(() => {
    
    if (activeCardId <= 0)
      fetchAllMaterialsWithBranches();
    else if (activeCardId > 0) 
      fetchMaterialsByBranch();
  }, [reload, activeCardId])

  async function fetchAllMaterialsWithBranches(){
    try {
      setLoading(true);
      setBranches(await getBranches());
      setMaterials(await getAllMaterials());
    } catch {
      console.error('An error occurred while fetching data:');
    } finally {
      setLoading(false);
    }
  }

  async function fetchMaterialsByBranch() {
    try {
      setLoading(true);
      setMaterials(await getMaterialsByBranch(activeCardId));
    } catch {
      console.error('An error occurred while fetching data:');
    } finally {
      setLoading(false);
    }
  }

  const scrollLeft = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23;
      containerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  };

  const scrollRight = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23
    containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  const onCardClickHandler = (branchId) => {
    setActiveCardId(branchId)
  }

  return (
    <div className='materials-header-component'>

      <div className='scroll-container left'>
        <IoIosArrowBack className='icon' fontSize={''} onClick={scrollLeft} />
      </div>

      <div className='items-container' ref={containerRef}>

        <div className={`item-container ${(currentAdminPermissions & 2097152) !== 2097152 ? 'disabled' : ''}`} onClick={() => {setPageMode(MaterialsModeEnum.ADD)}}>
          <AdditionCard />
        </div>

        <div className='item-container'>
          <AnalyticalCard
            title={'All Branches'}
            icon={FaBookOpen}
            value={activeCardId <= 0 ? `${materials?.length} Materials` : '-'}
            isActive={activeCardId <= 0}
            onClick={() => onCardClickHandler(0)}
          />
        </div>

        {branches
          .map((branch) => (
            <div key={branch.branchId} className="item-container">
              <AnalyticalCard
                title={branch.branchName}
                icon={FaBookOpen}
                value={activeCardId === branch.branchId ? `${materials?.length} Materials` : '-'}
                isActive={activeCardId === branch.branchId}
                onClick={() => onCardClickHandler(branch.branchId)}
              />
            </div>
          ))
        }

      </div>

      <div className='scroll-container right'>
        <IoIosArrowForward className='icon' fontSize={''} onClick={scrollRight} />
      </div>

    </div>
  )
}
