// base
import React, { useState, useEffect, useContext, useRef } from 'react';
import './sectionsCard.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// context
import { coursesSectionsContext } from '../../../pages/courses_sections/coursesSectionsContext';

// icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ImageIcon from '@mui/icons-material/Image';
import HideImageIcon from '@mui/icons-material/HideImage';
import TimelineIcon from '@mui/icons-material/Timeline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// components
import CardDropDown from '../../card_dropdown/card_dropdown_menu/CardDropDown';
import CardDropdownItem from '../../card_dropdown/card_Dropdown_item/CardDropdownItem';

// logic
import { 
  SectionsModeEnum,
  MessageBoxActionsEnum,
  validateFile,
  setSectionCoverAsync
} from '../../../pages/courses_sections/coursesSectionsLogic';

export default function SectionsCard({ section }) {
  const fileInputRef = useRef(null);

  let menuRef  = useRef(null);

  const {
    setPageMode,
    setActionType,
    setSectionObjForAction,
    setModelMessage,
    setLoading,
    reload,
    setReload
  } = useContext(coursesSectionsContext)

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [dropdownStatus, setDropdownStatus] = useState(false);

  useEffect(() => {
    let handler = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)){
        setDropdownStatus(false);
      }
    }

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  }, [])

  const onDeleteCoverClickHandler = () => {
    setActionType(MessageBoxActionsEnum.DELETE_COVER);
    setSectionObjForAction(section);
  }

  const onUpdateClickHandler = () => {
    setSectionObjForAction(section);
    setPageMode(SectionsModeEnum.UPDATE)
  }

  const onDeleteClickHandler = () => {
    setActionType(MessageBoxActionsEnum.DELETE);
    setSectionObjForAction(section);
  }

  return (
    <div className='course-section-card-component'>
      
      <div className='course-section-card-header-container'>
        <div className='more-options-container' ref={menuRef}>

          <MoreHorizIcon className='more-icon' onClick={() => {setDropdownStatus(!dropdownStatus)}}/>

          <CardDropDown status={dropdownStatus} >

            <div className='input-image-container' onClick={() => {fileInputRef.current.click();}}>
              <CardDropdownItem 
                title={'Set Cover'}
                isDisabled={(BigInt(currentAdminPermissions) & BigInt(1073741824)) !== BigInt(1073741824)}
                onClick={async() => {
                  setDropdownStatus(false);
                }} >
                <ImageIcon />
              </CardDropdownItem>

              <input
                className="image-input"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (!file) {
                    return;
                  };

                  const isValid = validateFile(file);
                  if (!isValid) {
                    setModelMessage({
                      title: 'error', 
                      message: 'Please select un image in (jpeg, png, webp) format only.', 
                      isVisible: false
                    });
                    e.target.value = "";
                  } else {
                    setSectionCoverAsync(
                      e,
                      section,
                      currentAdminId,
                      currentAdminPermissions,
                      reload,
                      setReload,
                      setModelMessage,
                      setLoading
                    )
                  }
                }}
                ref={fileInputRef}
              />

            </div>

            <CardDropdownItem 
              title={'Delete Cover'}
              isDisabled={(BigInt(currentAdminPermissions) & BigInt(1073741824)) !== BigInt(1073741824) || !section.coverName}
              onClick={async() => {
                setDropdownStatus(false);
                onDeleteCoverClickHandler();
              }} >
              <HideImageIcon />
            </CardDropdownItem>

            <Link to={`/transactions-history/${section?.sectionId}/Courses Sections`} className={`history ${(currentAdminPermissions & 256) !== 256 ? 'disabled' : ''}`}>
              <CardDropdownItem 
                title={'History'}
                isDisabled={false}
                onClick={async() => {
                  setDropdownStatus(false);
                }} >
                <TimelineIcon />
              </CardDropdownItem>
            </Link>

            <CardDropdownItem 
              title={'Update'}
              isDisabled={(BigInt(currentAdminPermissions) & BigInt(1073741824)) !== BigInt(1073741824)}
              onClick={async() => {
                setDropdownStatus(false);
                onUpdateClickHandler();
              }} >
              <EditIcon />
            </CardDropdownItem>

            <CardDropdownItem 
              title={'Delete'}
              isDanger={true}
              isDisabled={(BigInt(currentAdminPermissions) & BigInt(2147483648)) !== BigInt(2147483648)}
              onClick={async() => {
                setDropdownStatus(false);
                onDeleteClickHandler();
              }} >
              <DeleteIcon />
            </CardDropdownItem>

          </CardDropDown>

        </div>

        <Link className={`cover-image-link`} to={`/courses/sections/${section.sectionId}`}>
          <div className='course-section-card-img-container'>
            {section.coverURL ? 
              <img src={section.coverURL} alt='profile' className='cover-image' />
            :
              <p className='no-cover'>cover</p>
            }
          </div>
        </Link>
      </div>

      <div className='course-section-card-body-container'>
        <Link className={`card-title-link`} to={`/courses/sections/${section.sectionId}`}>
          <div className='section-name-container'>
            <p className='section-name'>
              {section?.sectionName}
            </p>
          </div>
        </Link>

        <div className='course-section-footer-container'>
          <p className='lessons-count'>{section?.lessonsCount} Lessons</p>
        </div>

      </div>

    </div>
  )
}