import React, { useState } from "react";
import "./newPay.css";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// components
import Selector from "../../../components/inputs/selector/Selector";
import Loading from "../../../components/loading/Loading";
import Model from "../../../components/model/Model";
import TextInput from "../../../components/inputs/text_input/TextInput";
import ContainedButton from "../../../components/inputs/buttons/contained_button/ContainedButton";

import { validateAmountInput, newPayAsync } from "./newPayLogic";

export default function NewPay() {
  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector(
    (state) => state.currentUser.permissions
  );
  const { userId, subscriptionId } = useParams();

  const [amountInput, setAmountInput] = useState("");
  const [currencyInput, setCurrencyInput] = useState({
    value: "1",
    label: "IQD",
  });
  const [descriptionInput, setDescriptionInput] = useState("");

  const [currencyOptions] = useState([
    {
      value: "1",
      label: "IQD",
    },
    {
      value: "2",
      label: "$",
    },
  ]);

  const [loader, setLoader] = useState(false);
  const [message, messageState] = useState({
    title: "",
    message: "",
    status: false,
  });

  const handleCurrencyOption = (option) => {
    setCurrencyInput(option);
  };

  const handleOnPay = async () => {
    await newPayAsync(
      currentAdminId,
      currentAdminPermissions,
      userId,
      subscriptionId,
      amountInput,
      currencyInput?.label,
      descriptionInput,
      setLoader,
      messageState
    );
  };

  return (
    <div className="new-pay-page">
      <Loading isVisible={loader} />
      <Model
        title={message.title}
        message={message.message}
        status={message.status}
        setStatus={messageState}
      />

      <div className="input-group">
        <div className="input-container">
          <TextInput
            title={"Pay amount"}
            value={amountInput}
            onChangeHandler={(value) => {
              setAmountInput(value);
            }}
            validateInput={validateAmountInput}
            mandatory={true}
            onEnter={handleOnPay}
          />
        </div>
        <div className={`input-container`}>
          <p className="selector-label">
            Currency <span>*</span>
          </p>
          <Selector
            className={"label"}
            title={""}
            options={currencyOptions}
            value={currencyInput.value}
            onSelect={handleCurrencyOption}
          />
        </div>
        <div className="input-container">
          <label htmlFor="description" className="textarea-label">
            Description
          </label>
          <textarea
            id="description"
            className="textarea-input"
            placeholder="Enter pay description"
            value={descriptionInput}
            onChange={(e) => setDescriptionInput(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleOnPay()}
            required
          />
        </div>

        <div className="input-container btn-container">
          <ContainedButton title={"Pay"} onclickHandler={handleOnPay} />
        </div>
      </div>
    </div>
  );
}
