import axios from 'axios';
import config from '../../global/config';

import {
  uploadFileToFirebase,
  deleteFileFromFirebase
} from '../../global/utils/firebase/firebaseActions';

import {
  fetchMultipleData,
  deleteData
} from '../../global/utils/functions/apiService';

import {
  checkLength,
} from '../../global/utils/validation';

const TypicalQuestionsModeEnum = Object.freeze({
  READ: 'read',
  ADD: 'add',
  UPDATE: 'update'
});

const MessageBoxActionsEnum = Object.freeze({
  NONE: 'none',
  DELETE: 'delete'
})

function onChangeHandler(field, newValue, state) {
  state(prevState => ({
    ...prevState,
    [field]: newValue
  }));
}

function validateQuestionTitle (input) {
  return checkLength(1, 150, input);
}

function validateFileURL (input) {
  return input.length > 0;
}

function validateFileName (input) {
  return checkLength(1, 150, input);
}

function validatePosativeNumber(number) {
  if (!number || number <= 0){
    return false;
  }

  return true;
}

function validateQuestionForAddNew (questionObj) {

  if (!validateQuestionTitle(questionObj.questionTitle))
    return false;

  if (!validatePosativeNumber(questionObj.typeId))
    return false;

  if (!validatePosativeNumber(questionObj.materialId))
    return false;

  if (!validatePosativeNumber(questionObj.branchId))
    return false;

  if (!validatePosativeNumber(questionObj.yearId))
    return false;

  return true;
}

function validateQuestionForUpdate (questionObj) {
  console.log(questionObj)
  if (!validatePosativeNumber(questionObj.questionId))
    return false;

  if (!validateQuestionTitle(questionObj.questionTitle))
    return false;

  if (!validatePosativeNumber(questionObj.typeId))
    return false;

  if (!validatePosativeNumber(questionObj.materialId))
    return false;

  if (!validatePosativeNumber(questionObj.branchId))
    return false;

  if (!validatePosativeNumber(questionObj.yearId))
    return false;

  return true;
}

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

async function getQuestionsTypes() {
  const url = '/TypicalQuestionsTypes/GetAllTypicalQuestionsTypes';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const types = response?.data?.Response?.data?.types || [];

    return types;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getBranches() {
  const url = '/Branches/GetAllBranches';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const branches = response?.data?.Response?.data?.branches || [];

    return branches;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getYears() {
  const url = '/AcademicYears/GetAllAcademicYears';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const years = response?.data?.Response?.data?.academicYears || [];

    return years;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getAllMaterials() {
  const url = '/Materials/GetAllMaterials';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const materials = response?.data?.Response?.data?.materials || [];

    return materials;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getTypicalQuestionsByMaterial(materialId) {
  const url = `/TypicalQuestions/GetByMaterial/${materialId}`;

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const questions = response?.data?.Response?.data?.typicalQuestions || [];

    return questions;
  } else {
    console.log(response.message);
    return [];
  }
}

async function deleteTypicalQuestionAsync(questionObj, byAdmin, currentAdminPermissions, messageState, loaderState, reload, setReload, navigate) {
  try {
    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((currentAdminPermissions & 8388608) !== 8388608) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);

    if (questionObj.fileName) {
      await deleteFileFromFirebase(`pdf-files/typical-questions/${questionObj.fileName}`);
    }

    const response = await deleteData(`/TypicalQuestions/DeleteTypicalQuestion/${questionObj.questionId}/${byAdmin}`);
    
    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'The typical question has been deleted successfully.',
        isVisible: true
      });
      setReload(!reload);
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to delete typical question. Please check your permissions and try again.',
        isVisible: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deleting the typical question. Please try again later.',
      isVisible: true
    });
  } finally {
    loaderState(false);
  }
}

function validateFile(file) {
  if (!file)
    return false;

  const allowedTypes = ['application/pdf'];

  if (!allowedTypes.includes(file.type)) {
    return false;
  }

  return true;
}

function generateUniqueFileName(file) {
  if (!file || !file.name) {
    return null;
  }

  const fileExtension = file.name.split('.').pop().toLowerCase();
  const fileName = `${Date.now()}.${fileExtension}`;
  return fileName;
}

async function uploadTypicalFile(file, uniqueFileName) {
  const fileURL = await uploadFileToFirebase(file, uniqueFileName);
  return fileURL ? fileURL : null;
}

async function newTypicalQuestionAsync(event, questionObj, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (!validateQuestionForAddNew(questionObj) || !validateFile(event?.target?.files[0])) {
      setMessage({
        message: 'Invalid typical question data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    if ((currentAdminPermissions & 8388608) !== 8388608) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    loaderState(true);

    const file = event.target.files?.[0];
    if (!file) {
      setMessage({
        message: 'No file selected. Please select a PDF file to upload.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    if (!validateFile(file)) {
      setMessage({
        message: 'Invalid file type. Please upload a PDF file.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    const uniqueFileName = generateUniqueFileName(file);
    if (!uniqueFileName) {
      setMessage({
        message: 'Failed to generate a unique file name. Please try again.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    const fileURL = await uploadTypicalFile(file, `pdf-files/typical-questions/${uniqueFileName}`);

    if (!fileURL) {
      setMessage({
        message: 'An error occurred during file upload. Please try again.',
        type: 'error',
        isVisible: true,
      });
      return false;
    } else {
      if (!validateFileURL(fileURL)) {
        await deleteFileFromFirebase(`pdf-files/typical-questions/${uniqueFileName}`);
        return false;
      }
    
      if (!validateFileName(uniqueFileName)) {
        await deleteFileFromFirebase(`pdf-files/typical-questions/${uniqueFileName}`);
        return false;
      }
    }

    const url = `${config.apiBaseUrl}/TypicalQuestions/NewTypicalQuestion`;
    const response = await axios.post(url, {
      questionTitle: questionObj.questionTitle,
      fileURL: fileURL,
      fileName: uniqueFileName,
      questionTypeId: questionObj.typeId,
      materialId: questionObj.materialId,
      branchId: questionObj.branchId,
      academicYearId: questionObj.yearId,
      byAdmin: byAdmin,
    });

    if (response.status === 201) {
      setMessage({
        message: 'Typical question inserted successfully.',
        type: 'success',
        isVisible: true,
      });
      return true;
    } else {
      setMessage({
        message: 'Failed to insert new typical question.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: error.response?.data?.title || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true,
    });
    return false;
  } finally {
    loaderState(false);
  }
}

async function updateTypicalQuestionAsync(event, questionObj, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (!validateQuestionForUpdate(questionObj) || !validateFile(event?.target?.files[0])) {
      setMessage({
        message: 'Invalid typical question data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    if ((currentAdminPermissions & 8388608) !== 8388608) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return false;
    }
    loaderState(true);

    const file = event.target.files?.[0];
    if (!file) {
      setMessage({
        message: 'No file selected. Please select a PDF file to upload.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    if (!validateFile(file)) {
      setMessage({
        message: 'Invalid file type. Please upload a PDF file.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    const uniqueFileName = generateUniqueFileName(file);
    if (!uniqueFileName) {
      setMessage({
        message: 'Failed to generate a unique file name. Please try again.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    if (questionObj.fileName) {
      await deleteFileFromFirebase(`pdf-files/typical-questions/${questionObj.fileName}`);
    }

    const fileURL = await uploadTypicalFile(file, `pdf-files/typical-questions/${uniqueFileName}`);
    if (!fileURL) {
      setMessage({
        message: 'An error occurred during file upload. Please try again.',
        type: 'error',
        isVisible: true,
      });
      return false;
    }

    const url = `${config.apiBaseUrl}/TypicalQuestions/UpdateTypicalQuestion`;

    const response = await axios.put(url, {
      questionId: questionObj.questionId,
      questionTitle: questionObj.questionTitle,
      fileURL: fileURL,
      fileName: uniqueFileName,
      questionTypeId: questionObj.typeId,
      materialId: questionObj.materialId,
      branchId: questionObj.branchId,
      academicYearId: questionObj.yearId,
      byAdmin: byAdmin
    });

    if (response.status === 200) {
      return true;
    } else {
      setMessage({
        message: 'Failed to update typical question.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    console.log(error)
    setMessage({
      message: `${error.response?.data}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
};

export {
  TypicalQuestionsModeEnum,
  MessageBoxActionsEnum,
  onChangeHandler,
  validateQuestionTitle,
  resetMessage,
  getQuestionsTypes,
  getBranches,
  getYears,
  getAllMaterials,
  getTypicalQuestionsByMaterial,
  deleteTypicalQuestionAsync,
  validateFile,
  newTypicalQuestionAsync,
  updateTypicalQuestionAsync
}