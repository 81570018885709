import axios from 'axios';
import config from '../../global/config';

import {
  fetchScalarData,
  fetchMultipleData,
  deleteData
} from '../../global/utils/functions/apiService';

import {
  checkLength,
} from '../../global/utils/validation';

const AcademicYearsModeEnum = Object.freeze({
  READ: 'read',
  ADD: 'add',
  UPDATE: 'update'
});

const MessageBoxActionsEnum = Object.freeze({
  NONE: 'none',
  DELETE: 'delete'
})

function onChangeHandler(field, newValue, state) {
  state(prevState => ({
    ...prevState,
    [field]: newValue
  }));
}

function validateYearName (input) {
  return checkLength(1, 25, input);
}

function validateYearForAddNew (year) {
  if (!validateYearName(year.academicYear))
    return false;

  return true;
}

function validateYearForUpdate (year) {
  if (!validateYearName(year.academicYear))
    return false;

  if (!year.yearId || year.yearId <= 0)
    return false;

  return true;
}

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

async function getYears() {
  const url = '/AcademicYears/GetAllAcademicYears';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const years = response?.data?.Response?.data?.academicYears || [];

    return years;
  } else {
    console.log(response.message);
    return [];
  }
}

async function isYearHasRelationsAsync(yearId) {
  const url = `/AcademicYears/IsAcademicYearHasRelations/${yearId}`;

  try {
    const response = await fetchScalarData(url);

    if (response.data.Response.data.hasRelations === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('An error occurred while fetching the data:', error);
    return false;
  }
}

async function deleteYearAsync(yearId, byAdmin, currentAdminPermissions, messageState, loaderState, reload, setReload, navigate) {
  try {
    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((currentAdminPermissions & 131072) !== 131072) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);
    
    const hasRelations = await isYearHasRelationsAsync(yearId);

    if (hasRelations === true) {
      messageState({
        title: 'Action Required',
        message: 'This academic year is linked to other records. Please remove the dependencies before attempting to delete it.',
        isVisible: true,
      });
      
      return;
    }

    const response = await deleteData(`/AcademicYears/DeleteAcademicYear/${yearId}/${byAdmin}`);
    
    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'The academic year has been deleted successfully.',
        isVisible: true
      });
      setReload(!reload);
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to delete academic year. Please check your permissions and try again.',
        isVisible: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deleting the academic year. Please try again later.',
      isVisible: true
    });
  } finally {
    loaderState(false);
  }
}

async function isYearExist(year) {
  const url = `/AcademicYears/IsAcademicYearExist/${year}`;

  try {
    const response = await fetchScalarData(url);

    if (response.data.Response.data.isExist === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('An error occurred while fetching the data:', error);
    return false;
  }
}

async function newAcademicYearAsync(year, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (validateYearForAddNew(year) === false) {
      setMessage({
        message: 'Invalid academic year data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    if ((currentAdminPermissions & 131072) !== 131072) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);

    if (await isYearExist(year.academicYear) === true) {
      setMessage({
        message: 'This academic year already exists. Please enter a different academic year.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    const url = `${config.apiBaseUrl}/AcademicYears/NewAcademicYear`;

    const response = await axios.post(url, {
      academicYear: year.academicYear,
      byAdmin: byAdmin
    });

    if (response.status === 201) {
      setMessage({
        message: 'Academic year inserted successfully.',
        type: 'success',
        isVisible: true
      });
      return true;
    } else {
      setMessage({
        message: 'Failed to insert new academic year.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.response?.data?.title}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
};

// review catch section in top method

async function updateAcademicYearAsync(year, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (validateYearForUpdate(year) === false) {
      setMessage({
        message: 'Invalid academic year data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    if ((currentAdminPermissions & 131072) !== 131072) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }
    loaderState(true);

    if (await isYearExist(year.academicYear) === true) {
      setMessage({
        message: 'This academic year already exists. Please enter a different academic year.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    const url = `${config.apiBaseUrl}/AcademicYears/UpdateAcademicYear`;

    const response = await axios.put(url, {
      academicYearId: year.yearId,
      academicYear: year.academicYear,
      byAdmin: byAdmin
    });

    if (response.status === 200) {
      return true;
    } else {
      setMessage({
        message: 'Failed to update academic year.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.response?.data?.message}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
};

export {
  AcademicYearsModeEnum,
  MessageBoxActionsEnum,
  onChangeHandler,
  validateYearName,
  validateYearForAddNew,
  validateYearForUpdate,
  resetMessage,
  getYears,
  deleteYearAsync,
  newAcademicYearAsync,
  updateAcademicYearAsync
}