// base
import React from 'react'

// context
import { SubscriptionPlansProvider } from './subscriptionPlansContext';

// components
import SubscriptionPlansMain from '../../components/subscription_plans_components/subscription_plans_main/SubscriptionPlansMain';

export default function SubscriptionPlans() {
  return (
    <SubscriptionPlansProvider>
      <SubscriptionPlansMain />
    </SubscriptionPlansProvider>
  )
}
