import React, { createContext, useState } from 'react';
import { AcceptanceRatesModeEnum, MessageBoxActionsEnum } from './acceptanceRatesFilesLogic';

export const acceptanceRatesFilesContext = createContext();

export const AcceptanceRatesFilesProvider = ({ children }) => {

  const [rates, setRates] = useState([]);
  const [years, setYears] = useState([]);
  const [pageMode, setPageMode] = useState(AcceptanceRatesModeEnum.READ);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);
  const [rateObjForDelete, setRateObjForDelete] = useState(null);
  const [rateObjForUpdate, setRateObjForUpdate] = useState(null);
  const [actionType, setActionType] = useState(MessageBoxActionsEnum.NONE);

  return (
  <acceptanceRatesFilesContext.Provider
    value={{
      rates,
      setRates,
      years,
      setYears,
      pageMode,
      setPageMode,
      loading,
      setLoading,
      modelMessage, 
      setModelMessage,
      messageBox, 
      setMessageBox,
      reload,
      setReload,
      rateObjForDelete,
      setRateObjForDelete,
      rateObjForUpdate,
      setRateObjForUpdate,
      actionType,
      setActionType
    }}
  >
      {children}
  </acceptanceRatesFilesContext.Provider>
  );
};
