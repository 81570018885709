// base
import React, { useState, useContext } from 'react';
import './yearsCard.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// context
import { academicYearsContext } from '../../../pages/academic_years/academicYearsContext';

// icons
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

// components
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';

// logic
import { 
  AcademicYearsModeEnum,
  MessageBoxActionsEnum
} from '../../../pages/academic_years/academicYearsLogic';

export default function YearsCard({ year, _index }) {
  const {
    setPageMode,
    setActionType,
    setYearIdForDelete,
    setYearObjForUpdate
  } = useContext(academicYearsContext)

  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [openMode, setOpenMode] = useState(false);

  const onUpdateClickHandler = () => {
    setYearObjForUpdate(year);
    setPageMode(AcademicYearsModeEnum.UPDATE)
  }

  const onDeleteClickHandler = () => {
    setActionType(MessageBoxActionsEnum.DELETE);
    setYearIdForDelete(year?.yearId);
  }

  return (
    <div className='years-card-component'>

      <div className='years-card-header' onClick={() => {setOpenMode(!openMode)}}>
        <div className='index-container'>
          <p className='label index'>#/{_index}</p>
        </div>

        <div className='year-name-container'>
          <p className='label'>Academic Year</p>
          <p className='value'>{year?.academicYear}</p>
        </div>

        <div className='icon-container'>
          {openMode ? (
            <FaRegArrowAltCircleUp className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          ) : (
            <FaRegArrowAltCircleDown className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          )}
        </div>
      </div>

      <div className={`card-body ${openMode ? 'opened' : ''}`}>

        <div className='item inline'>
          <p className='label'>View History</p>

          <Link to={`/transactions-history/${year?.yearId}/Academic Years`}>
            <p className='value history'>history</p>
          </Link>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Update</p>
          <div className={`btn-container ${(currentAdminPermissions & 131072) !== 131072 ? 'disabled' : ''}`}>
            <ContainedButton title={'Update'} onclickHandler={onUpdateClickHandler} />
          </div>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Delete</p>
          <div className={`btn-container ${(currentAdminPermissions & 131072) !== 131072 ? 'disabled' : ''}`}>
            <ContainedButton title={'Delete'} onclickHandler={onDeleteClickHandler} type='danger' />
          </div>
        </div>

      </div>

    </div>
  )
}
