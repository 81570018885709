import axios from "axios";
import config from "../../../global/config";

import { checkLength, isFloat } from "../../../global/utils/validation";

const validateAmountInput = (value) => {
  return isFloat(value);
};
const validateDescriptionInput = (value) => {
  return checkLength(0, 250, value);
};

async function newPayAsync(
  adminId,
  currentAdminPermissions,
  userid,
  subscriptionId,
  amount,
  currency,
  description,
  setLoader,
  messageState
) {
  try {
    if (
      (BigInt(currentAdminPermissions) & BigInt(4294967296)) !==
      BigInt(4294967296)
    )
      return;

    if (
      !adminId ||
      !subscriptionId ||
      !validateAmountInput(amount) ||
      description?.length > 250 ||
      !currency
    ) {
      messageState({
        title: "Invalid Data",
        message: "Please ensure all fields are filled correctly.",
        status: true,
      });
      return false;
    }

    if (setLoader) setLoader(true);

    const paymentData = {
      amount,
      currency,
      paymentMethod: "manual",
      description,
      userId: userid,
      subscriptionId,
      byAdmin: adminId,
    };

    const response = await axios.post(
      `${config?.apiBaseUrl}/Payments/NewPayment`,
      paymentData
    );

    if (response.status === 201) {
      messageState({
        title: "Success",
        message: "Payment inserted successfully.",
        status: true,
      });
      return true;
    } else {
      messageState({
        title: "Error",
        message: response.data.message || "Payment could not be processed.",
        status: true,
      });
      return false;
    }
  } catch (error) {
    console.error(error);
    messageState({
      title: "Error",
      message: "An error occurred while processing the payment.",
      status: true,
    });
    return false;
  } finally {
    if (setLoader) setLoader(false);
  }
}

export { validateAmountInput, validateDescriptionInput, newPayAsync };
