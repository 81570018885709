// base
import React from 'react'

// context
import { TypicalQuestionsProvider } from './typicalQuestionsContext';

// components
import TypicalQuestionsMain from '../../components/typical_questions_components/typical_questions_main/TypicalQuestionsMain';

export default function Materials() {
  return (
    <TypicalQuestionsProvider>
      <TypicalQuestionsMain />
    </TypicalQuestionsProvider>
  )
}
