// base
import React from 'react'

// context
import { CoursesSectionsProvider } from './coursesSectionsContext';

// components
import SectionsMain from '../../components/courses_sections_components/courses_sections_main/SectionsMain';

export default function Materials() {
  return (
    <CoursesSectionsProvider>
      <SectionsMain />
    </CoursesSectionsProvider>
  )
}
