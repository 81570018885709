import React from 'react'
import './cardDropdown.css';

export default function CardDropDown({ status, dir = 'ltr', children }) {
  return (
    <ul className={`card-dropdown-component ${status ? 'card-dropdown-active' : 'card-dropdown-in-active'} ${dir === 'rtl' ? 'rtl' : ''}`}>
      {children}
    </ul>
  )
}
