import React, { createContext, useState } from 'react';
import { LessonsModeEnum, MessageBoxActionsEnum } from './lessonsLogic';

export const lessonsContext = createContext();

export const LessonsProvider = ({ children }) => {

  const [lessons, setLessons] = useState([]);
  const [pageMode, setPageMode] = useState(LessonsModeEnum.READ);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);
  const [lessonObjForAction, setLessonObjForAction] = useState(null);
  const [actionType, setActionType] = useState(MessageBoxActionsEnum.NONE);

  return (
  <lessonsContext.Provider
    value={{
      lessons, setLessons,
      pageMode, setPageMode,
      loading, setLoading,
      modelMessage, setModelMessage,
      messageBox, setMessageBox,
      reload, setReload,
      lessonObjForAction, setLessonObjForAction,
      actionType, setActionType
    }}
  >
      {children}
  </lessonsContext.Provider>
  );
};
