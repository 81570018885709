// base
import React from 'react'

// context
import { MaterialsProvider } from './materialsContext';

// components
import MaterialsMain from '../../components/materials_components/materials_main/MaterialsMain';

export default function Materials() {
  return (
    <MaterialsProvider>
      <MaterialsMain />
    </MaterialsProvider>
  )
}
