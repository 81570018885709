// base
import React, { useState, useContext } from 'react';
import './typicalQuestionsTypesCard.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// context
import { typicalQuestionsTypesContext } from '../../../pages/typical_questions_types/typicalQuestionsTypesContext';

// icons
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

// components
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';

// logic
import { 
  TypicalQuestionsTypesModeEnum,
  MessageBoxActionsEnum
} from '../../../pages/typical_questions_types/typicalQuestionsTypesLogic';

export default function TypicalQuestionsTypesCard({ type, _index }) {
  const {
    setPageMode,
    setActionType,
    setTypeIdForDelete,
    setTypeObjForUpdate
  } = useContext(typicalQuestionsTypesContext)

  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [openMode, setOpenMode] = useState(false);

  const onUpdateClickHandler = () => {
    setTypeObjForUpdate(type);
    setPageMode(TypicalQuestionsTypesModeEnum.UPDATE)
  }

  const onDeleteClickHandler = () => {
    setActionType(MessageBoxActionsEnum.DELETE);
    setTypeIdForDelete(type?.typeId);
  }

  return (
    <div className='typical-questions-types-card-component'>

      <div className='typical-questions-types-card-header' onClick={() => {setOpenMode(!openMode)}}>
        <div className='index-container'>
          <p className='label index'>#/{_index}</p>
        </div>

        <div className='type-name-container'>
          <p className='label'>Typical Question Type</p>
          <p className='value'>{type?.type}</p>
        </div>

        <div className='icon-container'>
          {openMode ? (
            <FaRegArrowAltCircleUp className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          ) : (
            <FaRegArrowAltCircleDown className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          )}
        </div>
      </div>

      <div className={`card-body ${openMode ? 'opened' : ''}`}>

        <div className='item inline'>
          <p className='label'>View History</p>

          <Link to={`/transactions-history/${type?.typeId}/Typical Questions Types`}>
            <p className='value history'>history</p>
          </Link>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Update</p>
          <div className={`btn-container ${(currentAdminPermissions & 524288) !== 524288 ? 'disabled' : ''}`}>
            <ContainedButton title={'Update'} onclickHandler={onUpdateClickHandler} />
          </div>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Delete</p>
          <div className={`btn-container ${(currentAdminPermissions & 524288) !== 524288 ? 'disabled' : ''}`}>
            <ContainedButton title={'Delete'} onclickHandler={onDeleteClickHandler} type='danger' />
          </div>
        </div>

      </div>

    </div>
  )
}
