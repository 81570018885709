import React, { createContext, useState } from 'react';
import { AcceptanceRatesModeEnum, MessageBoxActionsEnum } from './acceptanceRatesLogic';

export const acceptanceRatesContext = createContext();

export const AcceptanceRatesProvider = ({ children }) => {

  const [rates, setRates] = useState([]);
  const [count, setCount] = useState(0);
  const [branches, setBranches] = useState([]);
  const [years, setYears] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);
  const [pageMode, setPageMode] = useState(AcceptanceRatesModeEnum.READ);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);
  const [rateIdForDelete, setRateIdForDelete] = useState(0);
  const [rateObjForUpdate, setRateObjForUpdate] = useState(null);
  const [actionType, setActionType] = useState(MessageBoxActionsEnum.NONE);

  return (
  <acceptanceRatesContext.Provider
    value={{
      rates, 
      setRates,
      count,
      setCount,
      branches, 
      setBranches,
      years,
      setYears,
      pageNumber,
      setPageNumber,
      pagesCount,
      setPagesCount,
      pageMode,
      setPageMode,
      loading,
      setLoading,
      modelMessage,
      setModelMessage,
      messageBox,
      setMessageBox,
      reload,
      setReload,
      rateIdForDelete,
      setRateIdForDelete,
      rateObjForUpdate,
      setRateObjForUpdate,
      actionType,
      setActionType
    }}
  >
      {children}
  </acceptanceRatesContext.Provider>
  );
};
