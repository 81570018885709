import axios from 'axios';
import config from '../../global/config';

import {
  fetchScalarData,
  fetchMultipleData,
  deleteData
} from '../../global/utils/functions/apiService';

import {
  checkLength,
} from '../../global/utils/validation';

const BranchesModeEnum = Object.freeze({
  READ: 'read',
  ADD: 'add',
  UPDATE: 'update'
});

const MessageBoxActionsEnum = Object.freeze({
  NONE: 'none',
  DELETE: 'delete'
})

function onChangeHandler(field, newValue, state) {
  state(prevState => ({
    ...prevState,
    [field]: newValue
  }));
}

function validateBranchName (input) {
  return checkLength(1, 50, input);
}

function validateBranchForAddNew (branch) {
  if (!validateBranchName(branch.branchName))
    return false;

  return true;
}

function validateBranchForUpdate (branch) {
  if (!validateBranchName(branch.branchName))
    return false;

  if (!branch.branchId || branch.branchId <= 0)
    return false;

  return true;
}

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

async function getBranches() {
  const url = '/Branches/GetAllBranches';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const branches = response?.data?.Response?.data?.branches || [];

    return branches;
  } else {
    console.log(response.message);
    return [];
  }
}

async function isBranchHasRelationsAsync(branchId) {
  const url = `/Branches/IsBranchHasRelations/${branchId}`;

  try {
    const response = await fetchScalarData(url);

    if (response.data.Response.data.hasRelations === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('An error occurred while fetching the data:', error);
    return false;
  }
}

async function deleteBranchAsync(branchId, byAdmin, currentAdminPermissions, messageState, loaderState, reload, setReload, navigate) {
  try {
    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((currentAdminPermissions & 32768) !== 32768) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);
    
    const hasRelations = await isBranchHasRelationsAsync(branchId);

    if (hasRelations === true) {
      messageState({
        title: 'Action Required',
        message: 'This branch is linked to other records. Please remove the dependencies before attempting to delete it.',
        isVisible: true,
      });
      
      return;
    }

    const response = await deleteData(`/Branches/DeleteBranch/${branchId}/${byAdmin}`);
    
    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'The branch has been deleted successfully.',
        isVisible: true
      });
      setReload(!reload);
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to delete branch. Please check your permissions and try again.',
        isVisible: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deleting the branch. Please try again later.',
      isVisible: true
    });
  } finally {
    loaderState(false);
  }
}

async function isBranchExist(branchName) {
  const url = `/Branches/IsBranchExist/${branchName}`;

  try {
    const response = await fetchScalarData(url);

    if (response.data.Response.data.isExist === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('An error occurred while fetching the data:', error);
    return false;
  }
}

async function newBranchAsync(branch, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (validateBranchForAddNew(branch) === false) {
      setMessage({
        message: 'Invalid branch data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    if ((currentAdminPermissions & 32768) !== 32768) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }

    loaderState(true);

    if (await isBranchExist(branch.branchName) === true) {
      setMessage({
        message: 'This branch already exists. Please enter a different branch name.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    const url = `${config.apiBaseUrl}/Branches/NewBranch`;

    const response = await axios.post(url, {
      branchName: branch.branchName,
      byAdmin: byAdmin
    });

    if (response.status === 201) {
      setMessage({
        message: 'Branch inserted successfully.',
        type: 'success',
        isVisible: true
      });
      return true;
    } else {
      setMessage({
        message: 'Failed to insert new branch.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.response?.data?.title}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
};

async function updateBranchAsync(branch, byAdmin, currentAdminPermissions, setMessage, loaderState) {
  try {
    if (validateBranchForUpdate(branch) === false) {
      setMessage({
        message: 'Invalid branch data. Please review your inputs and ensure all fields are correctly filled.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    if ((currentAdminPermissions & 32768) !== 32768) {
      setMessage({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }
    loaderState(true);

    if (await isBranchExist(branch.branchName) === true) {
      setMessage({
        message: 'This branch already exists. Please enter a different branch name.',
        type: 'error',
        isVisible: true,
      });
      return;
    }

    const url = `${config.apiBaseUrl}/Branches/UpdateBranch`;

    const response = await axios.put(url, {
      branchId: branch.branchId,
      branchName: branch.branchName,
      byAdmin: byAdmin
    });

    if (response.status === 200) {
      return true;
    } else {
      setMessage({
        message: 'Failed to update branch.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.response?.data?.message}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
};

export {
  BranchesModeEnum,
  MessageBoxActionsEnum,
  onChangeHandler,
  validateBranchName,
  validateBranchForAddNew,
  validateBranchForUpdate,
  resetMessage,
  getBranches,
  newBranchAsync,
  updateBranchAsync,
  deleteBranchAsync
}