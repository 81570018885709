// base
import React, { useContext } from 'react';
import './ratesFilesBody.css';

// context
import { acceptanceRatesFilesContext } from '../../../pages/acceptance_rates_files/acceptanceRatesFilesContext';

// components
import RatesFilesCard from '../rates_files_card/RatesFilesCard';

export default function RatesFilesBody() {
  const {
    rates
  } = useContext(acceptanceRatesFilesContext);

  return (
    <div className='rates-files-body-component'>
      
      {rates.length > 0 ? (
        rates.map((rate, index) => (
          <div key={rate.rateId || index} className='rates-files-card-container'>
            <RatesFilesCard rate={rate} _index={index + 1} />
          </div>
        ))
      ) : (
        <div className="no-rates-files-message">
          No acceptance rates files found.
        </div>
      )}

    </div>
  )
}
