// base
import React, { useContext } from 'react';
import './sectionsBody.css';

// context
import { coursesSectionsContext } from '../../../pages/courses_sections/coursesSectionsContext';

// components
import SectionsCard from '../courses_sections_card/SectionsCard';

export default function SectionsBody() {
  const {
    sections
  } = useContext(coursesSectionsContext);

  return (
    <div className='courses-sections-body-component'>

      {sections.length > 0 ? (
        sections.map((section, index) => (
          <div key={section.sectionId || index} className='course-section-card-container'>
            <SectionsCard section={section} />
          </div>
        ))
      ) : (
        <div className="no-sections-message">
          No sections found.
        </div>
      )}

    </div>
  )
}
