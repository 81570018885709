// base
import React, { useContext, useEffect, useState, useRef } from 'react'
import './coursesHeader.css';
import { useSelector } from 'react-redux';

// context
import { coursesContext } from '../../../pages/courses/coursesContext';

// icons
import { FaChalkboardTeacher } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

// components 
import AdditionCard from '../../common_components/addition_card/AdditionCard';
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// logic
import {
  CoursesModeEnum,
  getBranches,
  getCoursesCount,
  getCoursesByBranchAsync,
} from '../../../pages/courses/coursesLogic';

export default function CoursesHeader() {
  const containerRef = useRef(null);

  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);
  const [activeCardId, setActiveCardId] = useState(0);

  const {
    setCount,
    courses,
    setCourses,
    branches,
    setBranches,
    setPageMode,
    setLoading,
    reload
  } = useContext(coursesContext);

  useEffect(() => {
    fetchBranches();
  }, [reload])

  useEffect(() => {
    if (activeCardId > 0)
      fetchCoursesByBranch();
  }, [activeCardId]);

  async function fetchBranches(){
    try {
      setLoading(true);
      const data = await getBranches();
      if (data?.length > 0) {
        setBranches(data);
        if (activeCardId <= 0)
          setActiveCardId(data[0].branchId);
        else
          fetchCoursesByBranch();
      }

      setCount(await getCoursesCount());
    } catch {
      console.error('An error occurred while fetching data:');
    } finally {
      setLoading(false);
    }
  }

  async function fetchCoursesByBranch() {
    try {
      setLoading(true);
      setCourses(await getCoursesByBranchAsync(activeCardId));
    } catch {
      console.error('An error occurred while fetching data:');
    } finally {
      setLoading(false);
    }
  }

  const scrollLeft = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23;
      containerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  };

  const scrollRight = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23
    containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  const onCardClickHandler = (branchId) => {
    setActiveCardId(branchId)
  }

  return (
    <div className='courses-header-component'>

      <div className='scroll-container left'>
        <IoIosArrowBack className='icon' fontSize={''} onClick={scrollLeft} />
      </div>

      <div className='items-container' ref={containerRef}>

        <div className={`item-container ${(currentAdminPermissions & 1073741824) !== 1073741824 ? 'disabled' : ''}`} onClick={() => {setPageMode(CoursesModeEnum.ADD)}}>
          <AdditionCard />
        </div>

        {branches
          .map((branch) => (
            <div key={branch.branchId} className="item-container">
              <AnalyticalCard
                title={branch.branchName}
                icon={FaChalkboardTeacher}
                value={activeCardId === branch.branchId ? `${courses?.length} Courses` : '-'}
                isActive={activeCardId === branch.branchId}
                onClick={() => onCardClickHandler(branch.branchId)}
              />
            </div>
          ))
        }

      </div>

      <div className='scroll-container right'>
        <IoIosArrowForward className='icon' fontSize={''} onClick={scrollRight} />
      </div>

    </div>
  )
}
