// base
import React from 'react'

// context
import { LessonsProvider } from './lessonsContext';

// components
import LessonsMain from '../../components/lessons_components/lessons_main/LessonsMain';

export default function Materials() {
  return (
    <LessonsProvider>
      <LessonsMain />
    </LessonsProvider>
  )
}
