// base
import React, { useState, useContext } from 'react';
import './ratesFilesCard.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// context
import { acceptanceRatesFilesContext } from '../../../pages/acceptance_rates_files/acceptanceRatesFilesContext';

// icons
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

// components
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';

// logic
import { 
  AcceptanceRatesModeEnum,
  MessageBoxActionsEnum
} from '../../../pages/acceptance_rates_files/acceptanceRatesFilesLogic';

export default function RatesFilesCard({ rate, _index }) {
  
  const {
    setPageMode,
    setActionType,
    setRateObjForDelete,
    setRateObjForUpdate
  } = useContext(acceptanceRatesFilesContext)

  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [openMode, setOpenMode] = useState(false);

  const onUpdateClickHandler = () => {
    setRateObjForUpdate(rate);
    setPageMode(AcceptanceRatesModeEnum.UPDATE)
  }

  const onDeleteClickHandler = () => {
    setActionType(MessageBoxActionsEnum.DELETE);
    setRateObjForDelete({
      fileId: rate.fileId,
      fileName: rate.fileName
    });
  }

  return (
    <div className='rates-files-card-component'>
      <div className='rates-files-card-header' onClick={() => {setOpenMode(!openMode)}}>
        <div className='index-container'>
          <p className='label index'>#/{_index}</p>
        </div>

        <div className='file-title-container'>
          <p className='label'>File Title</p>
          <p className='value'>{rate?.fileTitle}</p>
        </div>
        <div className='year-container'>
          <p className='label'>Academic Year</p>
          <p className='value'>{rate?.academicYear}</p>
        </div>

        <div className='icon-container'>
          {openMode ? (
            <FaRegArrowAltCircleUp className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          ) : (
            <FaRegArrowAltCircleDown className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          )}
        </div>
      </div>

      <div className={`card-body ${openMode ? 'opened' : ''}`}>

        <div className='item file-title-container'>
          <p className='label'>File Title</p>
          <p className='value'>{rate?.fileTitle}</p>
        </div>
        <div className='item inline'>
          <p className='label'>Academic Year</p>
          <p className='value'>{rate?.academicYear}</p>
        </div>
        <div className='item inline'>
          <p className='label'>Open File</p>
          <a href={rate.fileURL} className='value history' target='_blank' rel='noopener noreferrer' >Open</a>
        </div>

        <div className='item inline'>
          <p className='label'>View History</p>

          <Link to={`/transactions-history/${rate?.fileId}/Acceptance Rates Files`}>
            <p className='value history'>history</p>
          </Link>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Update</p>
          <div className={`btn-container ${(currentAdminPermissions & 134217728) !== 134217728 ? 'disabled' : ''}`}>
            <ContainedButton title={'Update'} onclickHandler={onUpdateClickHandler} />
          </div>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Delete</p>
          <div className={`btn-container ${(currentAdminPermissions & 134217728) !== 134217728 ? 'disabled' : ''}`}>
            <ContainedButton title={'Delete'} onclickHandler={onDeleteClickHandler} type='danger' />
          </div>
        </div>

      </div>

    </div>
  )
}
