// base
import React, { useContext, useEffect, useRef, useState } from 'react'
import './subscriptionPlansHeader.css';
import { useSelector } from 'react-redux';

// context
import { subscriptionPlansContext } from '../../../pages/subscription_plans/subscriptionPlansContext';

// icons
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { SiGoogleclassroom } from "react-icons/si";


// components 
import AdditionCard from '../../common_components/addition_card/AdditionCard';
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// logic
import {
  SubscriptionPlansMode,
  getBranches,
  getSubscriptionPlans
} from '../../../pages/subscription_plans/subscriptionPlansLogic';

export default function SubscriptionPlansHeader() {
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const {
    setPlans, 
    branches, 
    setBranches,
    setPageMode,
    setLoading,
    reload
  } = useContext(subscriptionPlansContext);

  const containerRef = useRef(null);
  const [activeCardId, setActiveCardId] = useState(0);

  const scrollLeft = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23;
      containerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  };

  const scrollRight = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23
    containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getBranches();
        setActiveCardId(data[0].branchId)
        setBranches(data);
      } catch {
        console.error('An error occurred while fetching branches data:');
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [reload])

  useEffect(() => {
    const fetchData = async () => {
      await getSubscriptionPlansAsync();
    }

    fetchData();
  }, [activeCardId, reload])

  async function getSubscriptionPlansAsync() {
    if (activeCardId <= 0)
      return;
    try {
      setLoading(true);
      setPlans(await getSubscriptionPlans(activeCardId));
    } catch (error) {
      console.error('Error fetching subscription plans:', error);
    } finally {
      setLoading(false);
    }
  }

  const onCardClickHandler = (branchId) => {
    setActiveCardId(branchId)
  }

  return (
    <div className='subscription-plans-header-component'>
      
      <div className='scroll-container left'>
        <IoIosArrowBack className='icon' fontSize={''} onClick={scrollLeft} />
      </div>

      <div className='items-container' ref={containerRef}>

        <div className={`item-container ${(currentAdminPermissions & 4096) !== 4096 ? 'disabled' : ''}`} onClick={() => {setPageMode(SubscriptionPlansMode.ADD)}}>
          <AdditionCard />
        </div>

        {branches
          .map((branch) => (
            <div key={branch.branchId} className="item-container">
              <AnalyticalCard
                title={branch.branchName}
                icon={SiGoogleclassroom}
                value={'~'}
                isActive={activeCardId === branch.branchId}
                onClick={() => onCardClickHandler(branch.branchId)}
              />
            </div>
          ))
        }
        
      </div>

      <div className='scroll-container right'>
        <IoIosArrowForward className='icon' fontSize={''} onClick={scrollRight} />
      </div>

    </div>
  )
}
