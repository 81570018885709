// base
import React, { useContext, useEffect } from 'react';
import './coursesMain.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// context
import { coursesContext } from '../../../pages/courses/coursesContext';

// logic
import { 
  resetMessage, 
  MessageBoxActionsEnum,
  CoursesModeEnum,
  activateCourse,
  deActivateCourse,
  deleteCourseCoverAsync,
  deleteCourseAsync,
} from '../../../pages/courses/coursesLogic';

// components
import CoursesHeader from '../courses_header/CoursesHeader';
import CoursesBody from '../courses_body/CoursesBody';
import CoursesAddUpdateComponent from '../courses_add_update_component/CoursesAddUpdateComponent';
import Model  from '../../model/Model';
import MessageBox from '../../message_box/MessageBox';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import Loading from '../../loading/Loading';

export default function CoursesMain() {
  const navigate = useNavigate();

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const { 
    reload,
    setReload,
    modelMessage, 
    setModelMessage,
    messageBox,
    setMessageBox,
    loading,
    setLoading,
    courseObjForAction,
    pageMode,
    actionType, 
    setActionType
  } = useContext(coursesContext);

  useEffect(() => {
    onActionTypeChangeHandler();
  }, [actionType])

  const onActionTypeChangeHandler = () => {
    switch (actionType) {
      case MessageBoxActionsEnum.ACTIVATE:
        setMessageBox({
          title: 'Confirm Activation',
          message: 'Are you sure you want to activate this course?',
          isVisible: true
        });
      break;
      case MessageBoxActionsEnum.DEACTIVATE:
        setMessageBox({
          title: 'Confirm Deactivation',
          message: 'Are you sure you want to deactivate this course?',
          isVisible: true
        });
      break;
      case MessageBoxActionsEnum.DELETE_COVER:
        setMessageBox({
          title: 'Confirm Cover Deletion',
          message: 'Are you sure you want to delete this course cover?',
          isVisible: true
        });
      break;
      case MessageBoxActionsEnum.DELETE:
        setMessageBox({
          title: 'Confirm Deletion',
          message: 'Are you sure you want to delete this course? This action cannot be undone.',
          isVisible: true
        });
        break;

      default:
        return;
    }
  }

  const onConfirmAction = async() => {
    switch (actionType) {
      case MessageBoxActionsEnum.ACTIVATE:
        await activateCourse(
          courseObjForAction.courseId,
          currentAdminId,
          currentAdminPermissions,
          setModelMessage,
          setLoading,
          reload,
          setReload
        );
      break;
      case MessageBoxActionsEnum.DEACTIVATE:
        await deActivateCourse(
          courseObjForAction.courseId,
          currentAdminId,
          currentAdminPermissions,
          setModelMessage,
          setLoading,
          reload,
          setReload
        );
      break;
      case MessageBoxActionsEnum.DELETE_COVER:
        await deleteCourseCoverAsync(
          courseObjForAction,
          currentAdminId,
          currentAdminPermissions,
          reload,
          setReload,
          setModelMessage,
          setLoading
        )
      break;
      case MessageBoxActionsEnum.DELETE:
        await deleteCourseAsync
          (
            courseObjForAction,
            currentAdminId,
            currentAdminPermissions,
            setModelMessage,
            setLoading,
            reload,
            setReload,
            navigate
          )
        break;

      default:
        return;
    }
    setActionType(MessageBoxActionsEnum.NONE);
  }

  return (
    <div className='courses-main-component'>

      <Model 
        title={modelMessage.title} 
        message={modelMessage.message} 
        status={modelMessage.isVisible} 
        setStatus={setModelMessage} 
      />

      <MessageBox 
        title={messageBox.title} 
        message={messageBox.message} 
        isVisable={messageBox.isVisible} 
        onClose={() => {
          resetMessage(setMessageBox);
          setActionType(MessageBoxActionsEnum.NONE);
        }}
      >
        <div className='courses-confirm-btn-container'>
          <ContainedButton title={'Confirm'} onclickHandler={async() => {
            resetMessage(setMessageBox);
            onConfirmAction();
          }} />
        </div>
      </MessageBox>

      <div className={`loading-container ${loading ? 'active' : ''}`}>
        <Loading isVisible={loading} />
      </div>

      <div className={`add-update-container ${pageMode === CoursesModeEnum.ADD ? '' : 'in-active'}`}>
        <CoursesAddUpdateComponent />
      </div>

      <div className={`add-update-container ${pageMode === CoursesModeEnum.UPDATE ? '' : 'in-active'}`}>
        <CoursesAddUpdateComponent />
      </div>

      <div className='courses-header'>
        <CoursesHeader />
      </div>

      <div className='courses-body'>
        <CoursesBody />
      </div>

    </div>
  )
}