// base
import React, { useState, useEffect, useContext, useRef } from 'react';
import './coursesCard.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// context
import { coursesContext } from '../../../pages/courses/coursesContext';

// icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ImageIcon from '@mui/icons-material/Image';
import HideImageIcon from '@mui/icons-material/HideImage';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import TimelineIcon from '@mui/icons-material/Timeline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// components
import CardDropDown from '../../card_dropdown/card_dropdown_menu/CardDropDown';
import CardDropdownItem from '../../card_dropdown/card_Dropdown_item/CardDropdownItem';

// logic
import { 
  CoursesModeEnum,
  MessageBoxActionsEnum,
  validateFile,
  setCourseCoverAsync
} from '../../../pages/courses/coursesLogic';

export default function CoursesCard({ course }) {
  const fileInputRef = useRef(null);

  let menuRef  = useRef(null);

  const {
    setPageMode,
    setActionType,
    setCourseObjForAction,
    setModelMessage,
    setLoading,
    reload,
    setReload
  } = useContext(coursesContext)

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [dropdownStatus, setDropdownStatus] = useState(false);

  useEffect(() => {
    let handler = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)){
        setDropdownStatus(false);
      }
    }

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  }, [])

  const onDeleteCoverClickHandler = () => {
    setActionType(MessageBoxActionsEnum.DELETE_COVER);
    setCourseObjForAction(course);
  }

  const onActivateClickHandler = () => {
    setActionType(MessageBoxActionsEnum.ACTIVATE);
    setCourseObjForAction(course);
  }

  const onDeactivateClickHandler = () => {
    setActionType(MessageBoxActionsEnum.DEACTIVATE);
    setCourseObjForAction(course);
  }

  const onUpdateClickHandler = () => {
    setCourseObjForAction(course);
    setPageMode(CoursesModeEnum.UPDATE)
  }

  const onDeleteClickHandler = () => {
    setActionType(MessageBoxActionsEnum.DELETE);
    setCourseObjForAction(course);
  }

  return (
    <div className='course-card-component'>
      
      <div className='course-card-header-container'>
        <div className='more-options-container' ref={menuRef}>

          <MoreHorizIcon className='more-icon' onClick={() => {setDropdownStatus(!dropdownStatus)}}/>

          <CardDropDown status={dropdownStatus} >

            <div className='input-image-container' onClick={() => {fileInputRef.current.click();}}>
              <CardDropdownItem 
                title={'Set Cover'}
                isDisabled={(BigInt(currentAdminPermissions) & BigInt(1073741824)) !== BigInt(1073741824)}
                onClick={async() => {
                  setDropdownStatus(false);
                }} >
                <ImageIcon />
              </CardDropdownItem>

              <input
                className="image-input"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (!file) {
                    return;
                  };

                  const isValid = validateFile(file);
                  if (!isValid) {
                    setModelMessage({
                      title: 'error', 
                      message: 'Please select un image in (jpeg, png, webp) format only.', 
                      isVisible: false
                    });
                    e.target.value = "";
                  } else {
                    setCourseCoverAsync(
                      e,
                      course,
                      currentAdminId,
                      currentAdminPermissions,
                      reload,
                      setReload,
                      setModelMessage,
                      setLoading
                    )
                  }
                }}
                ref={fileInputRef}
              />

            </div>

            <CardDropdownItem 
              title={'Delete Cover'}
              isDisabled={(BigInt(currentAdminPermissions) & BigInt(1073741824)) !== BigInt(1073741824) || !course.coverName}
              onClick={async() => {
                setDropdownStatus(false);
                onDeleteCoverClickHandler();
              }} >
              <HideImageIcon />
            </CardDropdownItem>

            {course.isActive ? 
              (
                <CardDropdownItem 
                title={'Deactivate'}
                isDisabled={(BigInt(currentAdminPermissions) & BigInt(536870912)) !== BigInt(536870912)}
                onClick={async() => {
                  setDropdownStatus(false);
                  onDeactivateClickHandler();
                }} >
                <ToggleOffIcon />
              </CardDropdownItem>
              )
            :
              (
                <CardDropdownItem 
                  title={'Activate'}
                  isDisabled={(BigInt(currentAdminPermissions) & BigInt(536870912)) !== BigInt(536870912)}
                  onClick={async() => {
                    setDropdownStatus(false);
                    onActivateClickHandler();
                  }} >
                  <ToggleOnIcon />
                </CardDropdownItem>
              )
            }

            <Link to={`/transactions-history/${course?.courseId}/Courses`} className={`history ${(currentAdminPermissions & 256) !== 256 ? 'disabled' : ''}`}>
              <CardDropdownItem 
                title={'History'}
                isDisabled={false}
                onClick={async() => {
                  setDropdownStatus(false);
                  // onActivateClickHandler();
                }} >
                <TimelineIcon />
              </CardDropdownItem>
            </Link>

            <CardDropdownItem 
              title={'Update'}
              isDisabled={(BigInt(currentAdminPermissions) & BigInt(1073741824)) !== BigInt(1073741824)}
              onClick={async() => {
                setDropdownStatus(false);
                onUpdateClickHandler();
              }} >
              <EditIcon />
            </CardDropdownItem>

            <CardDropdownItem 
              title={'Delete'}
              isDanger={true}
              isDisabled={(BigInt(currentAdminPermissions) & BigInt(2147483648)) !== BigInt(2147483648)}
              onClick={async() => {
                setDropdownStatus(false);
                onDeleteClickHandler();
              }} >
              <DeleteIcon />
            </CardDropdownItem>

          </CardDropDown>

        </div>

        <Link className={`cover-image-link`} to={`${course.courseId}`}>
          <div className='course-card-img-container'>
            {course.coverURL ? 
              <img src={course.coverURL} alt='profile' className='cover-image' />
            :
              <p className='no-cover'>cover</p>
            }
          </div>
        </Link>
      </div>

      <div className='course-card-body-container'>
        <Link className={`card-title-link`} to={`${course.courseId}`}>
          <div className='course-title-container'>
            <p className='course-title'>
              {course?.courseTitle}
            </p>
          </div>
        </Link>

        <div className='course-footer-container'>
          <p className={`course-status ${course.isActive ? 'active' : 'in-active'}`}>
            {course.isActive ? 'Active' : 'In-Active'}
          </p>

          <p className={`branch-name`}>
            {course.branchName}
          </p>

          <p className={`teacher-name`}>
            {course.teacherName}
          </p>
        </div>

      </div>

    </div>
  )
}