// base
import React, { useState, useContext } from 'react';
import './branchesCard.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// context
import { branchesContext } from '../../../pages/branches/branchesContext';

// icons
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

// components
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';

// logic
import { 
  BranchesModeEnum,
  MessageBoxActionsEnum
} from '../../../pages/branches/branchesLogic';

export default function BranchesCard({ branch, _index }) {
  
  const {
    setPageMode,
    setActionType,
    setBranchIdForDelete,
    setBranchObjForUpdate
  } = useContext(branchesContext)

  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [openMode, setOpenMode] = useState(false);

  const onUpdateClickHandler = () => {
    setBranchObjForUpdate(branch);
    setPageMode(BranchesModeEnum.UPDATE)
  }

  const onDeleteClickHandler = () => {
    setActionType(MessageBoxActionsEnum.DELETE);
    setBranchIdForDelete(branch?.branchId);
  }

  return (
    <div className='branches-card-component'>

      <div className='branch-card-header' onClick={() => {setOpenMode(!openMode)}}>
        <div className='index-container'>
          <p className='label index'>#/{_index}</p>
        </div>

        <div className='branch-name-container'>
          <p className='label'>Branch Name</p>
          <p className='value'>{branch?.branchName}</p>
        </div>

        <div className='icon-container'>
          {openMode ? (
            <FaRegArrowAltCircleUp className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          ) : (
            <FaRegArrowAltCircleDown className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          )}
        </div>
      </div>

      <div className={`card-body ${openMode ? 'opened' : ''}`}>

        <div className='item inline'>
          <p className='label'>View History</p>

          <Link to={`/transactions-history/${branch?.branchId}/Branches`}>
            <p className='value history'>history</p>
          </Link>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Update</p>
          <div className={`btn-container ${(currentAdminPermissions & 32768) !== 32768 ? 'disabled' : ''}`}>
            <ContainedButton title={'Update'} onclickHandler={onUpdateClickHandler} />
          </div>
        </div>

        <div className={`item inline action`}>
          <p className='label'>Delete</p>
          <div className={`btn-container ${(currentAdminPermissions & 32768) !== 32768 ? 'disabled' : ''}`}>
            <ContainedButton title={'Delete'} onclickHandler={onDeleteClickHandler} type='danger' />
          </div>
        </div>

      </div>

    </div>
  )
}
