// base
import React, { useContext } from 'react';
import './ratesBody.css';

// context
import { acceptanceRatesContext } from '../../../pages/acceptance_rates/acceptanceRatesContext';

// components
import RatesCard from '../rates_card/RatesCard';

export default function RatesBody() {
  const {
    rates
  } = useContext(acceptanceRatesContext);

  return (
    <div className='rates-body-component'>
      
      {rates.length > 0 ? (
        rates.map((rate, index) => (
          <div key={rate.rateId || index} className='rate-card-container'>
            <RatesCard rate={rate} _index={index + 1} />
          </div>
        ))
      ) : (
        <div className="no-rates-message">
          No acceptance rates found.
        </div>
      )}

    </div>
  )
}
