// base
import React, { useContext, useEffect, useState, useRef } from 'react'
import './typicalQuestionsHeader.css';
import { useSelector } from 'react-redux';

// context
import { typicalQuestionsContext } from '../../../pages/typical_questions/typicalQuestionsContext';

// icons
import { FaClipboardQuestion } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

// components 
import AdditionCard from '../../common_components/addition_card/AdditionCard';
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// logic
import {
  TypicalQuestionsModeEnum,
  getAllMaterials,
  getTypicalQuestionsByMaterial,
  getQuestionsTypes,
  getBranches,
  getYears,
} from '../../../pages/typical_questions/typicalQuestionsLogic';

export default function TypicalQuestionsHeader() {
  const containerRef = useRef(null);

  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);
  const [activeCardId, setActiveCardId] = useState(0);

  const {
    setQuestions,
    setTypes,
    materials,
    setMaterials,
    setBranches,
    setYears,
    setPageMode,
    setLoading,
    reload
  } = useContext(typicalQuestionsContext);

  useEffect(() => {
    fetchAllMaterialsWithTypicalQuestions();
  }, [reload])

  useEffect(() => {
    if (!activeCardId)
      return;
    
    const fetchData = async () => {
      try {
        setLoading(true);
          setQuestions(await getTypicalQuestionsByMaterial(activeCardId));
      } catch {
        console.error('An error occurred while fetching data:');
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [activeCardId]);

  async function fetchBranches_Types_Years() {
    setTypes(await getQuestionsTypes());
    setBranches(await getBranches());
    setYears(await getYears());
  }

  async function fetchAllMaterialsWithTypicalQuestions(){
    try {
      setLoading(true);
      const data = await getAllMaterials();
      setMaterials(data);
      if (data.length > 0) {
        setQuestions(await getTypicalQuestionsByMaterial(data[0].materialId));
      }
      if (activeCardId <= 0)
      setActiveCardId(data[0]?.materialId);
      await fetchBranches_Types_Years();
    } catch {
      console.error('An error occurred while fetching data:');
    } finally {
      setLoading(false);
    }
  }

  const scrollLeft = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23;
      containerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  };

  const scrollRight = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23
    containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  const onCardClickHandler = (materialId) => {
    setActiveCardId(materialId)
  }

  return (
    <div className='typical-questions-header-component'>

      <div className='scroll-container left'>
        <IoIosArrowBack className='icon' fontSize={''} onClick={scrollLeft} />
      </div>

      <div className='items-container' ref={containerRef}>

        <div className={`item-container ${(currentAdminPermissions & 8388608) !== 8388608 ? 'disabled' : ''}`} onClick={() => {setPageMode(TypicalQuestionsModeEnum.ADD)}}>
          <AdditionCard />
        </div>

        {materials
          .map((material) => (
            <div key={material.materialId} className="item-container">
              <AnalyticalCard
                title={material.branchName}
                icon={FaClipboardQuestion}
                value={material.materialName}
                isActive={activeCardId === material.materialId}
                onClick={() => onCardClickHandler(material.materialId)}
              />
            </div>
          ))
        }

      </div>

      <div className='scroll-container right'>
        <IoIosArrowForward className='icon' fontSize={''} onClick={scrollRight} />
      </div>

    </div>
  )
}
