import axios from "axios";
import config from "../../global/config";

import {
  fetchScalarData,
  fetchMultipleData,
} from "../../global/utils/functions/apiService";

const UsersSubscriptionsMode = Object.freeze({
  ACTIVES: "actives",
  INACTIVES: "in-actives",
});

function resetMessage(state) {
  state({
    title: "",
    message: "",
    isVisible: false,
  });
}

async function getActiveUsersSubscriptionsAsync(pageNumber) {
  const url = `/UsersSubscriptions/GetActivesUsersSubscriptions`;

  const response = await fetchMultipleData(url, pageNumber);
  if (response.success) {
    const subscriptions = response?.data?.Response?.data?.subscriptions || [];
    return subscriptions;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getActivesPagesCount() {
  const result = await fetchScalarData(
    "/UsersSubscriptions/GetUsersSubscriptionsPagesCountActive"
  );
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log(
      "Error fetching actives users subscriptions pages count:",
      result.message
    );
    return 0;
  }
}

async function getActivesSubscriptionsCount() {
  const result = await fetchScalarData(
    "/UsersSubscriptions/GetActiveUsersSubscriptionsCount"
  );
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log(
      "Error fetching in-actives users subscriptions pages count:",
      result.message
    );
    return 0;
  }
}

async function getInActiveUsersSubscriptionsAsync(pageNumber) {
  const url = `/UsersSubscriptions/GetInActivesUsersSubscriptions`;

  const response = await fetchMultipleData(url, pageNumber);

  if (response.success) {
    const subscriptions = response?.data?.Response?.data?.subscriptions || [];
    return subscriptions;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getInActivesPagesCount() {
  const result = await fetchScalarData(
    "/UsersSubscriptions/GetUsersSubscriptionsPagesCountInActive"
  );
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log(
      "Error fetching in-actives users subscriptions pages count:",
      result.message
    );
    return 0;
  }
}

async function getInActivesSubscriptionsCount() {
  const result = await fetchScalarData(
    "/UsersSubscriptions/GetInActiveUsersSubscriptionsCount"
  );
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log(
      "Error fetching in-actives users subscriptions pages count:",
      result.message
    );
    return 0;
  }
}

async function getSubscriptionPlans(branchId, setLoader) {
  try {
    const url = `/SubscriptionPlans/GetPlansByBranch/${branchId}`;

    if (setLoader) setLoader(true);
    const response = await fetchMultipleData(url);

    if (response.success) {
      const plans = response?.data?.Response?.data?.subscriptionPlans || [];

      return plans;
    } else {
      console.log(response.message);
      return [];
    }
  } catch {
    return [];
  } finally {
    if (setLoader) setLoader(false);
  }
}

async function newUserSubscription(
  adminId,
  userId,
  planId,
  setLoader,
  messageState
) {
  try {
    if (!adminId || !userId || !planId) return false;

    const url = `${config.apiBaseUrl}/UsersSubscriptions/NewUserSubscription`; // تأكد من صحة الرابط
    const body = {
      userId: userId,
      planId: planId,
      byAdmin: adminId,
    };

    if (setLoader) setLoader(true);

    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response?.data?.data?.subscription?.subscriptionId) {
      messageState({
        title: "Subscription Successful",
        message: "The subscription has been created successfully.",
        status: true,
      });
      return true;
    } else {
      messageState({
        title: "Subscription Failed",
        message:
          response?.data?.data?.message ||
          "An error occurred while processing the subscription.",
        status: true,
      });
      return false;
    }
  } catch (error) {
    console.error(
      "An error occurred while creating the subscription:",
      error.message
    );
    messageState({
      title: "Subscription Failed",
      message:
        error.response?.data?.message ||
        "An error occurred while creating the subscription.",
      status: true,
    });
    return false;
  } finally {
    if (setLoader) setLoader(false);
  }
}

export {
  UsersSubscriptionsMode,
  resetMessage,
  getActiveUsersSubscriptionsAsync,
  getActivesPagesCount,
  getActivesSubscriptionsCount,
  getInActiveUsersSubscriptionsAsync,
  getInActivesPagesCount,
  getInActivesSubscriptionsCount,
  getSubscriptionPlans,
  newUserSubscription,
};
