// base
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import './sectionsHeader.css';
import { useSelector } from 'react-redux';

// context
import { coursesSectionsContext } from '../../../pages/courses_sections/coursesSectionsContext';

// components 
import AdditionCard from '../../common_components/addition_card/AdditionCard';

// icons
import { MdOutlinePlaylistPlay } from "react-icons/md";

// logic
import {
  SectionsModeEnum,
  GetCourseByIdAsync,
  getSectionsByCourseAsync,
  onChangeHandler,
} from '../../../pages/courses_sections/coursesSectionsLogic';

export default function SectionsHeader() {

  const { courseId } = useParams();
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [course, setCourse] = useState(null);

  const {
    sections,
    setSections,
    setPageMode,
    setLoading,
    reload,
    setSectionObjForAction
  } = useContext(coursesSectionsContext);

  useEffect(() => {
    fetchSectionsByCourse();
  }, [reload])

  async function fetchSectionsByCourse() {
    try {
      setLoading(true);
      setCourse(await GetCourseByIdAsync(courseId));
      setSections(await getSectionsByCourseAsync(courseId));
    } catch {
      console.error('An error occurred while fetching data:');
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className='course-section-header-component'>

      <div className='course-img-container'>
        {course?.coverURL ? 
          <img src={course?.coverURL} alt='cover' className='cover-image' />
        :
          <p className='no-cover'>cover</p>
        }
      </div>
      
      <div className='course-info-container'>
        
        <div className='course-info'>
          <div className='course-title-container'>
            <p className='course-title'>{course?.courseTitle}</p>
          </div>
          <div className='course-description-container'>
            <p className='course-description'>{course?.description}</p>
          </div>
          <div className='sections-count-container'>
            <MdOutlinePlaylistPlay fontSize={''} className='playlist-icon'/>
            <p className='sections-count'>{sections.length} sections</p>
          </div>
          <p className='teacher-name'>{course?.teacherName}</p>
        </div>

        <div className='course-section-footer-container'>
          <p className={`course-status ${course?.isActive ? 'active' : 'in-active'}`}>{course?.isActive ? 'active' : 'in-active'}</p>
          <div className={`addition-card-container ${(currentAdminPermissions & 1073741824) !== 1073741824 ? 'disabled' : ''}`} onClick={() => {
              onChangeHandler('courseId', course?.courseId, setSectionObjForAction);
              setPageMode(SectionsModeEnum.ADD);
            }
            }>
            <AdditionCard />
          </div>
        </div>

      </div>

    </div>
  )
}

