import React from 'react';
import './textInput.css';
import { useState } from 'react';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export default function TextInput({ title, value, onChangeHandler, validateInput, externalMessage, tooltipTitle, mandatory = false, onEnter, dir = 'ltr' }) {

  const [error, setError] = useState(externalMessage);
  
  const onChange = (e) => {
    let newValue = e.target.value;

    if (validateInput(newValue)) {
      setError('')
    }
    else {
      setError('Invalid ' + title + '.')
    }

    onChangeHandler(newValue)
  }

  const handleEnterDown = (e) => {
    if (e.key === 'Enter' && onEnter)
      onEnter();
  }

  return (
    <div className='text-input-component'>
      
      <label 
        className='text-input-title'
        htmlFor={title}>

        <div>
          {title} 
          <span className={mandatory ? 'required-field' : ''}> *</span> 
        </div>
          
          { 
            tooltipTitle ? 
            (
              <Tooltip title={tooltipTitle}>
                <InfoIcon fontSize='small'/>
              </Tooltip>
            )
            : 
            null
          }

      </label>

      <input id={title} className={`text-input ${dir === 'rtl' ? 'rtl' : ''}`}
        type='text' 
        placeholder={`Enter ${title ? title.toLowerCase() : ''}`} 
        value={value}
        onChange={onChange}
        autoCapitalize="none"
        onKeyDown={handleEnterDown}
      />

      <span className='error-label'>{error || externalMessage}</span>

    </div>
  )
}
