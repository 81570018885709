// base
import React, { useState, useEffect, useContext } from 'react';
import './branchesAddUpdateComponent.css';
import { useSelector } from 'react-redux';

// context
import { branchesContext } from '../../../pages/branches/branchesContext';

// components
import MessageLabel from '../../message_label/MessageLabel';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import TextButton from '../../inputs/buttons/text_button/TextButton';
import TextInput from '../../inputs/text_input/TextInput';

// logic
import {
  BranchesModeEnum,
  onChangeHandler,
  validateBranchName,
  newBranchAsync,
  updateBranchAsync
} from '../../../pages/branches/branchesLogic';


export default function BranchesAddUpdateComponent() {
  const {
    pageMode,
    setPageMode,
    setModelMessage,
    reload,
    setReload,
    branchObjForUpdate
  } = useContext(branchesContext);

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [newData, setNewData] = useState({
    branchId: 0,
    branchName: ''
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: 'Please fill in the required fields to add a new branch. All fields marked with * are mandatory.',
    type: 'info',
    isVisible: true
  });

  useEffect(() => {

    if (pageMode === BranchesModeEnum.ADD) {
      setNewData({
        branchId: 0,
        branchName: ''
      })

      setMessage({
        message: 'Please fill in the required fields to add a new branch. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      });
    }

    if (pageMode === BranchesModeEnum.UPDATE) {
      setNewData({
        branchId: branchObjForUpdate.branchId,
        branchName: branchObjForUpdate.branchName
      })

      setMessage({
        message: 'Please fill in the required fields to update branch. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      })
    }

  }, [pageMode]);

  const handleNewBranch = async() => {
    if (await newBranchAsync(newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setNewData({
        branchId: 0,
        branchName: ''
      });
      setReload(!reload);
    }
  }

  const handleUpdateBranch = async() => {
    if (await updateBranchAsync(newData, currentAdminId, currentAdminPermissions, setMessage, setLoading) === true) {
      setReload(!reload);
      setPageMode(BranchesModeEnum.READ);
      setModelMessage({
          title: 'success', 
          message: 'Branch updated successfully.', 
          isVisible: true 
        });
    }
  }

  const handleAddUpdateClick = async () => {

    if ((currentAdminPermissions & 32768) !== 32768){
      setMessage({
        message: 'You do not have sufficient permissions to perform this action.',
        type: 'info',
        isVisible: true,
      });
      return;
    }

    try {

      if (pageMode === BranchesModeEnum.ADD) {
        await handleNewBranch();
      }
      else if (pageMode === BranchesModeEnum.UPDATE) {
        await handleUpdateBranch();
      }

    } catch {
      setMessage({
        message: 'An error occurred while processing your request. Please try again later.',
        type: 'error',
        isVisible: true
      });
    }
  }

  const handleCancelClick = () => {
    setPageMode(BranchesModeEnum.READ);
  }

  return (
    <div className='branch-add-update-component'>
      <div className='branch-add-update-content'>

        <div className='new-branch-header'>
          <h3>{pageMode === BranchesModeEnum.ADD ? 'Add Branch' : 'Update Branch'}</h3>
          <div className={`save-loader ${loading ? 'active' : ''}`}></div>
        </div>

        <div className='new-branch-message'>
          <MessageLabel message={message.message} type={message.type} isVisible={message.isVisible} />
        </div>

        <div className={`new-branch-body ${loading ? 'loading' : ''}`}>
            <div className='item-container'>
              <TextInput
                title={'Branch Name'} 
                value={newData.branchName}
                onChangeHandler={(newValue) => {onChangeHandler('branchName', newValue, setNewData)}} 
                validateInput={validateBranchName}
                mandatory={true}
                tooltipTitle={'Please enter a valid branch name with a maximum length of 50 characters.'}
                onEnter={async() => {handleAddUpdateClick()}}
                dir='rtl'
              />
            </div>
        </div>

        <div className={`new-branch-footer ${loading ? 'loading' : ''}`}>
          <div className={`new-branch-add-button-container ${(currentAdminPermissions & 32768) !== 32768 ? 'disabled' : ''}`}>
            <ContainedButton title={pageMode === BranchesModeEnum.ADD ? 'Add' : 'Update'} onclickHandler={async() => {handleAddUpdateClick()}} />
          </div>

          <div className='new-branch-cancel-button-container'>
            <TextButton title={'Cancel'} onclickHandler={() => {handleCancelClick()}} />
          </div>
        </div>

      </div>
    </div>
  )
}
